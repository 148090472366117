import React, { useEffect, useState } from "react"
import { logos } from "assets"
import { Link, matchRoutes, useLocation } from "react-router-dom"
import { Tag, Space } from "antd"
import {
  BanknotesIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline"

import { useAuthStore } from "stores"
import { isPadeDomain } from "utils/utils"
import { useCustomizationSettingsStore } from "stores/customization-settings"

type ActionType = {
  title: string
  icon: JSX.Element
  route?: string
  patterns?: { path: string }[]
  enabled: boolean
  badge: boolean
  newTab?: boolean
  subActions?: {
    title: string
    route: string
    patterns: { path: string }[]
    enabled: boolean
  }[]
}

const NavTab: React.FC<{
  action: ActionType
  index?: number
  toggleSidebar?: VoidFunction
}> = ({ action, index, toggleSidebar }) => {
  const { pathname } = useLocation()
  // const [allPatterns, setAllPatterns] = useState<{ path: string }[]>([])
  const [subActionsOpen, toggleSubActionsOpen] = useState<boolean>(false)

  useEffect(() => {
    const allPatterns = action.subActions
      ?.map((action) => action.patterns)
      .reduce((a, e) => [...a, ...e], [])
    // setAllPatterns(allPatterns!)
    toggleSubActionsOpen(
      action.subActions?.length! > 0 &&
        matchRoutes(allPatterns!, pathname) !== null
    )
  }, [action.subActions, pathname])

  return (
    <div>
      {action.subActions?.length! > 0 ? (
        <>
          <div
            className={`w-full flex justify-between items-center gap-3 px-3 py-2
          hover:bg-primary600 hover:text-a11y hover:rounded-[10px] cursor-pointer ${
            subActionsOpen === true
              ? "bg-primary600 text-a11y rounded-[10px] font-avenir tracking-wider"
              : "text-a11y/80"
          }`}
            onClick={() => toggleSubActionsOpen(!subActionsOpen)}
          >
            <div className="flex items-center justify-center gap-3 font-avenir">
              {action.icon}
              <span className="mt-0.5">{action.title}</span>
              {action.badge ? (
                <Space
                  size={[0, 2]}
                  wrap
                >
                  <Tag color="gold">beta</Tag>
                </Space>
              ) : (
                ""
              )}
            </div>
            <ChevronUpIcon
              className={`ease-in-out transition-all w-4 rotate-180 ${
                subActionsOpen === true && "rotate-0"
              }`}
            />
          </div>
          {subActionsOpen === true && (
            <div className="border-l border-a11y flex flex-col gap-0.5 ml-[30px] my-1 pl-2">
              {action?.subActions?.map((action, index) =>
                !action.enabled ? (
                  ""
                ) : (
                  <Link
                    key={index}
                    to={action.route}
                    className={`w-full py-2 px-3 rounded-md flex justify-between items-center gap-5
                    hover:bg-primary600 hover:text-a11y cursor-pointer font-avenir ${
                      matchRoutes(action.patterns!, pathname) !== null
                        ? "bg-primary600 text-a11y  font-avenir tracking-wider"
                        : "text-a11y/80"
                    }`}
                    onClick={() => {
                      toggleSidebar && toggleSidebar()
                    }}
                  >
                    {action.title}
                  </Link>
                )
              )}
            </div>
          )}
        </>
      ) : (
        <Link
          to={action.route!}
          key={index!}
          aria-label={action.title}
          target={action.newTab === true ? "_blank" : ""}
          className={`w-full flex justify-start items-center gap-3 px-3 py-2
          hover:bg-primary600 hover:text-a11y hover:rounded-[10px] ${
            typeof action.patterns !== "undefined" &&
            matchRoutes(action.patterns!, pathname) !== null
              ? "bg-primary600 text-a11y rounded-[10px]  font-avenir tracking-wider"
              : "text-a11y/80 tracking-wide"
          }`}
          onClick={() => {
            toggleSidebar && toggleSidebar()
          }}
        >
          {action.icon}
          <span className="mt-0.5 font-avenir">{action.title}</span>
        </Link>
      )}
    </div>
  )
}

export const SideNavigationBar: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const { user } = useAuthStore()
  const { customizationSettings } = useCustomizationSettingsStore()

  const actions: ActionType[] = [
    {
      title: "Payslips",
      icon: <ClipboardDocumentListIcon className="w-5" />,
      route: "/employee/susp-exit/payslips",
      patterns: [{ path: "/employee/sus-exit/payslips/*" }],
      enabled: true,
      badge: false,
    },
    {
      title: "Wallet",
      icon: <BanknotesIcon className="w-5" />,
      enabled: true,
      badge: false,
      subActions: [
        {
          title: "Overview",
          route: "wallet/overview",
          patterns: [
            { path: "/employee/dashboard/wallet/overview/*" },
            { path: "/employee/dashboard/wallet/kyc/*" },
            { path: "/employee/dashboard/wallet/beneficiaries/*" },
            { path: "/employee/dashboard/wallet/send-money-wallet/*" },
            { path: "/employee/dashboard/wallet/send-money-bank/*" },
          ],
          enabled: user?.userOrganisationId === "108" ? false : true,
        },
        {
          title: "Transactions",
          route: "wallet/cross-border/transactions",
          patterns: [
            {
              path: "/employee/dashboard/wallet/cross-border/transactions/*",
            },
          ],
          enabled: user?.userOrganisationId === "108" ? false : true,
        },
        {
          title: "Earned Wage Access",
          route: "wallet/earned-wage-access/overview",
          patterns: [
            {
              path: "/employee/dashboard/wallet/earned-wage-access/overview/*",
            },
          ],
          enabled: true,
        },
      ],
    },
  ]

  const handleToggleSidebar = () => {
    toggleSidebar(false)
    document.body.classList.remove("overflow-hidden")
  }

  return (
    <>
      {isSidebarOpen && (
        <div
          className="md:hidden fixed inset-0 z-20 bg-black/30  cursor-pointer backdrop-blur-sm"
          onClick={handleToggleSidebar}
        />
      )}
      <div
        className={`custom__sidebar__scrollbar ${
          !isSidebarOpen ? "w-0" : "w-[280px]"
        } pb-20 md:pb-0 fixed top-0 bottom-0 z-30 md:left-0 md:w-[280px] shadow-lg h-[100dvh] bg-primary500 transition-all duration-75 ease-in-out overflow-y-auto `}
      >
        <div className="h-full w-full py-[18px] px-3 flex flex-col justify-start gap-3">
          {/* Brand */}
          <div className="mb-6 mt-2 pl-4">
            {isPadeDomain() ? (
              <img
                src={logos.PadeLogoWhite}
                alt="PaidHR Logo"
                className="w-[100px]"
              />
            ) : (
              <img
                src={customizationSettings?.brand?.menuLogoUrl}
                alt=""
                className="w-[100px]"
              />
            )}
          </div>
          {/* Menu */}
          <div className="h-full w-full flex flex-col gap-9">
            <div className="h-auto overflow-y-auto w-full flex flex-col gap-0.5">
              {actions.map((action, index) =>
                !action.enabled ? (
                  ""
                ) : (
                  <NavTab
                    action={action}
                    key={index}
                    index={index}
                    toggleSidebar={handleToggleSidebar}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
