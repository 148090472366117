import { useState } from "react"
import { Label, RadioInput } from "components"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import SingleBank from "./sendMoney/SingleBank"
import MultipleBank from "./sendMoney/MultipleBank"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import { useMutation, useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { formatCurrencyNoSymbol } from "utils/currency"
import bluePattern from "../../../../assets/illustrations/blue_bg_pattern.png"
import { Alert } from "antd"
import { IUserWalletID, KycLevel } from "./kyc/interface"
import { getLatestCompletedKycLevel } from "./Wallet"
import { IGetFeeRes } from "./sendMoney/interface"
export interface IPreviewData {
  beneficiary: string
  bank_name: string
  account_number: string
  amount: string
}

const SendMoneyBank = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")

  const [transferType, setTransferType] = useState("Single")
  const [previewData, setPreviewData] = useState<IPreviewData[]>([
    {
      beneficiary: "",
      bank_name: "",
      account_number: "",
      amount: "0",
    },
  ])
  const [fee, setFee] = useState("")
  const [total, setTotal] = useState("")

  const [currentLevel, setCurrentLevel] = useState<null | KycLevel>(null)
  const { isLoading: isLoadingWalletData, data: wallet } =
    useQuery<IUserWalletID>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: IUserWalletID) => {
        const latestCompletedLevel = getLatestCompletedKycLevel(
          data?.data?.kycLevels
        )

        if (latestCompletedLevel) {
          setCurrentLevel(latestCompletedLevel)
        } else {
        }
      },
    })
  const { isLoading: isLoadingFee, mutate: submitFee } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/payout-charges`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      return res?.data
    },

    onSuccess: (data: IGetFeeRes) => {
      const totalFee = data.data.reduce(
        (acc, current) => acc + current.charge,
        0
      )

      const totalAmount = previewData?.reduce(function (acc: any, obj: any) {
        return Number(acc) + Number(obj.amount)
      }, 0)

      setFee(String(totalFee))
      setTotal(String(totalFee + totalAmount))
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })
  const { isLoading: isLoadingOverview, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const ngnBalance = walletData?.data?.balances?.find(
    (balance) => balance.currency === "NGN"
  )

  const amountOverBalance = Number(total) > Number(ngnBalance?.balance)
  const allBeneficiariesFilled = previewData.every(
    (item) => item.beneficiary !== "" && item.beneficiary !== undefined
  )

  return (
    <DashboardWrapper>
      <div className=" flex flex-col-reverse lg:flex-row items-start justify-start lg:mt-5 gap-[30px] lg:gap-[60px]  lg:h-screen ">
        <div className=" h-full  overflow-y-auto scrollbar-hide  w-full lg:w-auto ">
          <div className="w-full lg:w-[650px]">
            <SegmentWrapper
              title="Send Money to Bank"
              headerClassName=" xl:!px-[40px]"
              bodyClassName="!pb-0"
            >
              <div className="w-full   bg-white  rounded-md ">
                <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] ">
                  <Label
                    required="true"
                    className="text-padeBlack font-circular text-sm"
                  >
                    Choose type of transfer
                  </Label>
                  <div className="mt-2 flex items-center gap-10">
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Single")
                          setPreviewData([
                            {
                              beneficiary: "",
                              bank_name: "",
                              account_number: "",
                              amount: "",
                            },
                          ])
                        }}
                        checked={transferType == "Single" ? true : false}
                      />
                      <span className="ml-2 text-sm text-[#5D6B82]">
                        Single
                      </span>
                    </label>
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Multiple")
                          setPreviewData([
                            {
                              beneficiary: "",
                              bank_name: "",
                              account_number: "",
                              amount: "",
                            },
                          ])
                        }}
                        checked={transferType == "Multiple" ? true : false}
                      />
                      <span className="ml-2 text-sm text-[#5D6B82]">
                        Multiple
                      </span>
                    </label>
                  </div>
                </div>
                {transferType == "Single" ? (
                  <SingleBank
                    setPreviewData={setPreviewData}
                    currentLevel={currentLevel}
                    amountOverBalance={amountOverBalance}
                    fee={fee}
                    setFee={setFee}
                    submitFee={submitFee}
                    setTotal={setTotal}
                    x={x}
                    allBeneficiariesFilled={allBeneficiariesFilled}
                  />
                ) : (
                  <MultipleBank
                    setPreviewData={setPreviewData}
                    currentLevel={currentLevel}
                    amountOverBalance={amountOverBalance}
                    fee={fee}
                    setFee={setFee}
                    submitFee={submitFee}
                    setTotal={setTotal}
                    allBeneficiariesFilled={allBeneficiariesFilled}
                  />
                )}
              </div>
            </SegmentWrapper>
          </div>
        </div>
        <div className="h-fit w-full lg:w-auto  ">
          {" "}
          {previewData.length > 0 ? (
            <div className="lg:mt-[37px] shadow-sm w-full lg:w-[370px] bg-white px-[10px] py-3 rounded h-fit">
              <div
                style={{
                  backgroundImage: `url(${bluePattern})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="h-auto bg-primary700  rounded-[10px] px-4 py-5 justify-between flex flex-col"
              >
                <h3 className="text-white font-extrabold text-base mb-2">
                  Payment Information
                </h3>
                <div className="border  border-white w-full" />
                {isLoadingOverview ? (
                  <div className="w-full flex justify-center items-center py-3 mt-4">
                    <LoadingIndicatorWhite />
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-2 my-4">
                      <p className="text-white text-sm">Wallet balance</p>

                      <p className="text-white text-sm font-bold text-right">
                        ₦
                        {ngnBalance?.balance &&
                          formatCurrencyNoSymbol(
                            Number(ngnBalance?.balance) || 0
                          )}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 my-2">
                      <p className="text-white text-sm">Amount</p>
                      <p className={` text-sm font-bold text-right text-white`}>
                        ₦{" "}
                        {formatCurrencyNoSymbol(
                          previewData?.reduce(function (acc: any, obj: any) {
                            return Number(acc) + Number(obj.amount)
                          }, 0)
                        ) || 0}
                      </p>
                    </div>
                    {fee && (
                      <div className="grid grid-cols-2 my-2">
                        <p className="text-white text-sm">Fee</p>
                        <p
                          className={` text-sm font-bold text-right text-white`}
                        >
                          ₦ {formatCurrencyNoSymbol(Number(fee)) || 0}
                        </p>
                      </div>
                    )}
                    {total && (
                      <div className="grid grid-cols-2 my-2">
                        <p className="text-white text-sm">Total</p>
                        <p
                          className={` text-sm font-bold text-right ${
                            amountOverBalance ? "text-danger500" : "text-white"
                          }`}
                        >
                          ₦ {formatCurrencyNoSymbol(Number(total)) || 0}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>

              {amountOverBalance && (
                <div className="my-[11px]">
                  <Alert
                    type="error"
                    message="Insufficient funds"
                    description="You need to fund your wallet to complete this transaction. "
                    // description="You need to fund your wallet with ₦20,000. "
                    showIcon
                    className="invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center  "
                  />
                </div>
              )}
              {previewData.length > 0 && (
                <div className=" py-[23px] px-[10px]">
                  <p className="font-bold text-padeBlack text-base font-avenir">
                    Preview
                  </p>
                  <hr className="mb-4 border-b border-[#C2C7D0]" />
                  <div className=" max-h-[300px] overflow-y-scroll scrollbar-hide ">
                    {previewData.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                      >
                        <div className="flex w-full justify-between items-center gap-4">
                          <span className=" text-[#98A1B0] text-sm">
                            Beneficiary {index + 1}
                          </span>
                          <span className=" text-[#243757] font-bold text-sm truncate">
                            {item.beneficiary || "-"}
                          </span>
                        </div>
                        <div className="flex w-full justify-between items-center gap-4">
                          <span className="text-[#98A1B0] text-sm">
                            Bank name
                          </span>
                          <span className="text-[#243757] font-bold text-sm">
                            {item.bank_name || "-"}
                          </span>
                        </div>
                        <div className="flex w-full justify-between items-center gap-4">
                          <span className="text-[#98A1B0] text-sm">
                            Account number
                          </span>
                          <span className="text-[#243757] font-bold text-sm">
                            {item.account_number || "-"}
                          </span>
                        </div>
                        <div className="flex w-full justify-between items-center gap-4">
                          <span className="text-[#98A1B0] text-sm">Amount</span>
                          <span className="text-[#243757] font-bold text-sm">
                            ₦ {formatCurrencyNoSymbol(Number(item.amount || 0))}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default SendMoneyBank
