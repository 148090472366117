import { Drawer, Spin } from "antd"
import { IPaymentDetail } from "pages/dashboard/wallets/interface"
import React, { Dispatch, SetStateAction, useState } from "react"
import { useLocation } from "react-router-dom"
import { useGet } from "utils/useFetch"
import cardImg from "assets/svg/wallet-card.svg"
import cardLeather from "assets/svg/Wallet-leather.svg"
import ChequeCard from "assets/svg/Cheque.svg"
import { ICurrencyInfo } from "../interface/payouts"
import { logos } from "assets"

import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import { useMutation, useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { formatCurrencyNoSymbol } from "utils/currency"
import { ItransferDet } from "../../Component/PaymentScheduleTemplate"
import getIdFromKey from "utils/getIdFromKey"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import usePermissions from "hooks/usePermissions"
import { Button } from "components"
import { CloseOutlined } from "@ant-design/icons"
import { availableFlags } from "utils/flags"
import { useCustomizationSettingsStore } from "stores/customization-settings"
type Props = {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  activeCurrency: ICurrencyInfo | null
  invalidateQueries: () => void
}
const ProcessPayment = ({
  open,
  onClose,
  activeCurrency,
  invalidateQueries,
}: Props) => {
  const myParam = useLocation().search
  const { toast } = useAppNotificationStore()
  const x = new URLSearchParams(myParam).get("x")
  const { user } = useAuthStore()
  const { customizationSettings } = useCustomizationSettingsStore()
  const [canProcess, canDelete] = usePermissions({
    allowedPermissions: [
      "can_process_pay_schedules",
      "can_delete_pay_schedules",
    ],
  })
  const walletId = user?.organisationWalletId
  const [paymentMethod, setPaymentMethod] = useState("Direct Deposit")
  const [InsufBalance, setInsufBalance] = useState(false)

  const { isLoading: isPaymentLoading, data } = useGet<IPaymentDetail>({
    url: `admin/payment_schedules/view/index?id=detail&x=${x}`,
  })
  const { isLoading: isLoadingOverview, data: newWalletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const currentBalance = newWalletData?.data?.balances?.find(
    (balance) => balance.currency === activeCurrency?.currency_code
  )

  const { isFetching: isLoadingTransferDetails, data: transferDetails } =
    useGet<ItransferDet>({
      url: `/admin/payment_schedules/gettransferdetails?id=${getIdFromKey(
        x || ""
      )}&x=${activeCurrency?.currency_code}`,
      onSuccess: (data: any) => {
        console.log(data)

        currentBalance && data?.pagi > currentBalance?.balance
          ? setInsufBalance(true)
          : setInsufBalance(false)
      },
    })

  const { isLoading: isProcessingPayment, mutate: process } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/process_payment_schedule_new`,
        {
          id: data?.payment_schedule_id,
          payment_option: paymentMethod,
          currency_code: activeCurrency?.currency_code,
          // x: activeCurrency?.currency_code,
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        onClose(false)
        // setAllSelectedRows([])
        // setProcessSelected(false)
        {
          data.msg && toast.success(data.msg)
        }

        invalidateQueries()
        mixPanelEvent("pay-schedule-payout-process-success")
      } else {
        {
          data.msg && toast.error(data.msg)
        }
        invalidateQueries()
        // setAllSelectedRows([])
        // setProcessSelected(false)
      }
    },
    onError: (data: any) => {
      // setProcessSelected(false)

      {
        data.msg && toast.error(data.msg)
      }
    },
  })

  return (
    <Drawer
      width={455}
      title={
        <span className=" text-padeBlack font-circular text-xl">
          Process Payment
        </span>
      }
      open={open}
      // onClose={() => }
      closeIcon={false}
      className="bg-white relative"
      extra={
        <button onClick={() => onClose(false)}>
          <CloseOutlined className="h-6 w-6" />
        </button>
      }
    >
      <div className="overflow-y-scroll  pb-16 font-circular text-padeBlack w-full ">
        <div className="w-full">
          <p className=" text-sm font-circular text-padeBlack  font-medium ">
            Choose Payment Option
          </p>
          <div className="mt-3 h-[52px] w-full bg-[#F3F5FB] rounded-5px p-[6px] flex items-center mb-4">
            {["Direct Deposit", "Cheque"].map((item, index) => (
              <button
                key={index}
                className={`h-full  w-full text-sm font-circular ${
                  paymentMethod === item &&
                  "bg-white rounded shadow-sm text-padeBlack"
                }  `}
                onClick={() => {
                  setPaymentMethod(item)
                }}
              >
                {item}
              </button>
            ))}
          </div>
          {paymentMethod === "Cheque" ? (
            <div className="">
              <p className=" text-padeBlack font-circular text-sm mb-5">
                When you process by cheque please go ahead and write cheques for
                the selected beneficiaries.
              </p>
              {/* Cheque Card */}
              <div className="relative z-10">
                <img
                  src={ChequeCard}
                  alt="card"
                  className="w-full"
                />
                <div className="absolute top-0 bottom-0 right-0 left-0 px-6 py-8 z-50">
                  <div className="w-full flex items-center justify-between">
                    <div className="px-[5px] py-1 rounded-full flex items-center gap-1 bg-transparent border border-black w-fit">
                      <img
                        src={
                          availableFlags?.find(
                            (item) =>
                              item?.code === activeCurrency?.currency_code
                          )?.flag || ""
                        }
                        alt=""
                        className="h-[18px] w-[18px]"
                      />
                      <p className="text-padeBlack text-[10px] font-circular">
                        {activeCurrency?.currency_code}
                      </p>
                    </div>
                    <p className="">Cheque</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <p className=" text-padeBlack font-circular text-sm mb-5">
                If you choose Direct Deposit,
                {""} {customizationSettings?.brand.platformName} will debit your
                wallet after you process this schedule. Note: Each deposit may
                attract a processing fee depending on the transfer amount and
                currency. Learn more about transfer charges in our Help Center.
              </p>
              <div className="relative mt-[95px]">
                {/* Wallet Card */}

                <div className="absolute card-cover-animation">
                  <img
                    src={cardImg}
                    alt="card"
                    className="w-full"
                  />
                  <div className="absolute top-0 bottom-0 right-0 left-0 px-6 py-8 z-50">
                    <div className="w-full flex items-center justify-between">
                      <div className="py-[5px] px-[10px] rounded-full flex items-center gap-1 bg-white w-fit">
                        <img
                          src={
                            availableFlags?.find(
                              (item) =>
                                item?.code === activeCurrency?.currency_code
                            )?.flag || ""
                          }
                          alt=""
                          className="h-[18px] w-[18px]"
                        />
                        <p className="text-padeBlack text-[10px] font-circular">
                          {activeCurrency?.currency_code}
                        </p>
                      </div>
                      <img
                        src={logos?.PadeLogoWhiteNoName}
                        alt=""
                        className="mr-2"
                      />
                    </div>
                    <div className="mt-9">
                      <h5 className="font-circular  text-white text-[28px]">
                        {activeCurrency?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          Number(currentBalance?.balance) || 0
                        )}
                      </h5>
                      <p className="mt-[10px] text-padeLightBlue font-circular text-sm">
                        Wallet Balance
                      </p>
                    </div>
                  </div>
                </div>

                {/* Card Cover */}
                <div className="relative z-10">
                  <img
                    src={cardLeather}
                    alt="cover"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Spin spinning={isLoadingTransferDetails}>
          {InsufBalance && (
            <p className=" font-avenir text-sm text-[#EB3949] font-bold my-4 ">
              Insufficient Funds
            </p>
          )}
          <div className="mt-4">
            <span className=" text-sm font-circular text-padeBlack  font-medium">
              Payment Information
            </span>
            <div className="mt-[9px] rounded border p-4 flex flex-col">
              <div className=" grid grid-cols-2 my-3 pr-5">
                <p className="text-[#0C1B3D]  font-circular text-sm">Amount</p>
                <p className=" text-padeBlack text-sm  text-right">
                  {activeCurrency?.currency_symbol}
                  {data &&
                    formatCurrencyNoSymbol(Number(transferDetails?.data) || 0)}
                </p>
              </div>
              {paymentMethod !== "Cheque" && (
                <div className=" grid grid-cols-2 my-3 pr-5">
                  <p className="text-[#0C1B3D]  font-circular text-sm">
                    Charge
                  </p>
                  <p className=" text-padeBlack text-sm  text-right">
                    {activeCurrency?.currency_symbol}
                    {data &&
                      formatCurrencyNoSymbol(
                        Number(transferDetails?.record) || 0
                      )}
                  </p>
                </div>
              )}
              {paymentMethod !== "Cheque" && (
                <div className=" grid grid-cols-2 my-3 pr-5">
                  <p className="text-[#0C1B3D]  font-circular text-sm">
                    Total Amount
                  </p>
                  <p className=" text-padeBlack text-base  text-right font-bold">
                    {activeCurrency?.currency_symbol}
                    {transferDetails &&
                      formatCurrencyNoSymbol(
                        Number(transferDetails?.pagi) || 0
                      )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Spin>
        <div className="mt-5  bg-[#FEECBD] px-[25px] py-[17px]">
          <p className=" text-padeBlack text-sm font-medium">
            We'll update the status of this payroll to processed after
            processing the selected payment(s). You can still cancel the{" "}
            {data?.description} after processing.
          </p>
        </div>
      </div>
      <div className="absolute bottom-0 flex justify-end left-0 right-0 h-[64px]  py-3 px-4 lg:px-6 bg-white ">
        <Button
          color={InsufBalance ? "secondary" : "primary"}
          className="w-full"
          submitting={isProcessingPayment}
          disabled={InsufBalance}
          onClick={() => {
            if (canProcess) {
              process()
              mixPanelEvent("pay-schedule-payout-process-init")
            } else {
              toast.unauthorized()
            }
          }}
        >
          {InsufBalance ? "Insufficient funds to process" : "Process"}
        </Button>
      </div>
    </Drawer>
  )
}

export default ProcessPayment
