import GBPFlag from "assets/svg/uk_flag.svg"
import USFlag from "assets/svg/us_flag.svg"
import NigFlag from "assets/svg/nig_flag.svg"
import KenYFlag from "assets/svg/ken_flag.svg"
import CADFlag from "assets/svg/cad_flag.svg"
import Global from "assets/svg/countries/Global.svg"
import GHCFlag from "assets/svg/countries/Ghana (GH).svg"

export const availableFlags = [
  {
    code: "Global",
    flag: Global,
    symbol: "",
  },
  {
    code: "GBP",
    flag: GBPFlag,
    symbol: "£",
  },

  {
    code: "NGN",
    flag: NigFlag,
    symbol: "₦",
  },
  {
    code: "USD",
    flag: USFlag,
    symbol: "$",
  },
  {
    code: "KES",
    flag: KenYFlag,
    symbol: "KSh",
  },
  {
    code: "CAD",
    flag: CADFlag,
    symbol: "$",
  },
  {
    code: "GHS",
    flag: GHCFlag,
    symbol: "₵",
  },
]

export const getCurrencyDataBySymbol = (symbol: string) => {
  return availableFlags.find((i) => i.symbol === symbol)
}
