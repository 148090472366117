import { IPayroll } from "pages/dashboard/payroll/typings"
import { IDashboard } from "../typings"
import { useNavigate } from "react-router-dom"
import { useAuthStore } from "stores"
import { useState } from "react"
import Reminder from "assets/images/reminder.svg"
import Avatar from "assets/images/avatar.svg"
import Bg from "assets/new-auth/new-Auth.png"

import Security from "assets/images/security.svg"
import { useGet } from "utils/useFetch"
import { AntCustomLoader } from "components/antd/AntCustomLoader"
import { Progress } from "antd"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { isPadeDomain } from "utils/utils"
import { illustrations } from "assets"

export const Profile = () => {
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const [activeButton, setActiveButton] = useState("tab-1")
  const { colorScheme } = useCustomizationSettingsStore(
    (state) => state.customizationSettings.brand
  )

  const { isLoading: isPersonLoading, data: dashboard } = useGet<IDashboard>({
    url: "/admin/view/dashboard",
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const { isLoading: isPayrollLoading, data: payroll } = useGet<IPayroll>({
    url: "/admin/payrolls/view/index",
    cacheTime: 24 * 60 * 60 * 1000,
  })

  const { data: twoFaStatus, isLoading: gettingStatus } = useGet<{
    twoFactorEnabled: boolean
    twoFactorSetup: boolean
  }>({
    url: `/account/2fa/details`,
  })

  return (
    <div className="">
      <h2 className="text-base font-semibold text-padeBlack font-circular mb-4">
        Profile
      </h2>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2  gap-6 md:gap-[18px]">
        <div
          id="dashboard-profile"
          className=" col-span-1 xl:col-span-2 "
        >
          <CustomCard
            rootClassName="min-h-[212px]"
            activeTabKey={activeButton}
            onTabChange={(key) => setActiveButton(key as string)}
            tabList={[
              {
                key: "tab-1",
                tab: (
                  <div className="flex gap-4 ">
                    <div className="">
                      <Progress
                        rootClassName="overflow-hidden"
                        className=""
                        strokeWidth={10}
                        strokeColor={colorScheme}
                        size={50}
                        type="circle"
                        percent={dashboard?.person?.setup_percentage as number}
                        format={() => (
                          <img
                            src={user?.userAvatarFilename || Avatar}
                            alt="user"
                            className=" w-[40px] aspect-square rounded-[50%] object-cover"
                          />
                        )}
                      />
                    </div>
                    <div className="flex-grow">
                      <p className="mb-1 text-sm font-semibold font-circular  text-neutral500">
                        {dashboard?.person?.full_name}
                      </p>
                      <p className="mb-4 text-xs font-medium text-subText3">
                        {dashboard?.person?.employee_title ||
                          dashboard?.person?.identification_number}
                      </p>
                      <p className="mb-4 text-sm text-subText3">
                        Your profile is {dashboard?.person?.setup_percentage}%
                        complete
                      </p>
                      {dashboard?.person?.setup_percentage &&
                        dashboard?.person?.setup_percentage < 100 && (
                          <button
                            className="py-2  px-4 font-semibold text-primary500 bg-transparent text-xs rounded-[13px] border border-primary500"
                            onClick={() => navigate("/dashboard/profile")}
                          >
                            Complete your profile
                          </button>
                        )}
                    </div>
                  </div>
                ),
                label: "Personal",
              },
              {
                key: "tab-2",
                tab: (
                  <div className="flex gap-4 ">
                    <div className="">
                      <Progress
                        rootClassName="overflow-hidden"
                        className=""
                        strokeWidth={10}
                        strokeColor={colorScheme}
                        size={50}
                        type="circle"
                        percent={parseInt(user?.setupPercentage || "0")}
                        format={() => (
                          <img
                            src={user?.organisationAvatarFilename || Avatar}
                            alt="user"
                            className=" w-[40px] aspect-square rounded-[50%] object-cover"
                          />
                        )}
                      />
                    </div>
                    <div className="flex-grow">
                      <p className="mb-2 text-sm font-semibold  text-neutral500">
                        {user?.userOrganisation}
                      </p>
                      <p className="mb-4 text-xs font-medium text-subText3">
                        {user?.userOrganisationEmail}
                      </p>
                      <p className="mb-4 text-sm text-subText3">
                        Your profile is {user?.setupPercentage}% complete
                      </p>
                      <button
                        className="py-2 px-4 font-semibold text-primary500 bg-transparent text-xs rounded-[13px] border border-primary500"
                        onClick={() => navigate("/dashboard/company-details")}
                      >
                        Complete your profile
                      </button>
                    </div>
                  </div>
                ),
                label: "Company",
              },
              {
                key: "tab-3",
                tab: (
                  <div
                    className={`flex gap-4 ${
                      twoFaStatus?.twoFactorEnabled &&
                      twoFaStatus?.twoFactorSetup &&
                      "bg-success100"
                    } ${
                      twoFaStatus?.twoFactorEnabled &&
                      !twoFaStatus?.twoFactorSetup &&
                      "bg-red-100"
                    }  p-2`}
                  >
                    <div className=" flex justify-center items-center  relative h-[57px] w-[57px]">
                      <img
                        src={Security}
                        alt="security"
                      />
                    </div>
                    {twoFaStatus?.twoFactorEnabled &&
                    twoFaStatus?.twoFactorSetup ? (
                      <div className={`flex-grow`}>
                        <p className="mb-2 text-sm font-semibold  text-neutral500">
                          Your account is secured
                        </p>
                        <p className="mb-4 text-xs  text-subText3">
                          Two-factor authentication keeps the bad guys out, even
                          if they know your password.
                        </p>

                        <button
                          className="py-2  px-4 font-semibold text-primary500 bg-transparent text-xs rounded-[13px] border border-primary500"
                          onClick={() => {
                            navigate("/dashboard/profile")
                          }}
                        >
                          Account preferences
                        </button>
                      </div>
                    ) : (
                      <div className={`flex-grow`}>
                        <p className="mb-2 text-sm font-semibold  text-neutral500">
                          Secure your account
                        </p>
                        <p className="mb-4 text-xs  text-subText3">
                          Two-factor authentication is the best way to keep your
                          account safe
                        </p>

                        <button
                          className="py-2  px-4 font-semibold text-primary500 bg-transparent text-xs rounded-[13px] border border-primary500"
                          onClick={() => {
                            navigate("/dashboard/profile")
                          }}
                        >
                          Set up your 2FA
                        </button>
                      </div>
                    )}
                  </div>
                ),
                label: "Security",
              },
            ]}
            loading={isPersonLoading}
          />
        </div>

        <div
          id="dashboard-next-payroll"
          className="min-h-[150px] relative w-full grow bg-primary500 rounded-lg p-7 xl:py-4 xl:px-5 shadow-card col-span-1 xl:col-span-2"
        >
          {isPadeDomain() === true && (
            <div
              className="opacity-50 bg-center bg-cover absolute inset-0 z-[1]"
              style={{
                backgroundImage: `url(${Bg})`,
              }}
            />
          )}
          <div className="relative z-[2] h-full ">
            <h2 className="font-extrabold text-xl text-a11y mb-5 xl:mb-4 font-circular ">
              Next Payroll
              {isPayrollLoading
                ? ""
                : !payroll?.nextRunDateIsToday
                ? ` (${
                    payroll?.nextRunDate_Short &&
                    payroll?.nextRunDate_Short !== null
                      ? payroll?.nextRunDate_Short
                      : ""
                  })`
                : ` is today`}
            </h2>
            <p className="font-semibold text-sm text-a11y max-w-[350px] xl:max-w-[250px] font-avenir ">
              {isPayrollLoading
                ? ""
                : payroll && !payroll?.autoPayrollEnabled
                ? `Please submit payroll before close of business ${
                    payroll?.nextRunDateIsToday
                      ? "today"
                      : `on ${payroll?.nextRunDate}`
                  } to pay your employees on time for their hardwork.`
                : `Payroll will automatically run before close of business  ${
                    payroll?.nextRunDateIsToday
                      ? "today"
                      : `on ${payroll?.nextRunDate}`
                  }.`}
            </p>
            <div>
              <img
                src={illustrations.CalendarAlert}
                className="absolute w-[50px] h-[50px] right-4 xl:-right-2 bottom-2 xl:-bottom-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
