import Requests from "navigation/request"
import WalletRoutes from "navigation/wallet"
import EmployeeRoutes from "navigation/employee"
import React, { Suspense } from "react"
import { Navigate, Route } from "react-router-dom"
import { SentryRoutes as Routes } from "index"
import { UnknownPage } from "./components"
import GetStarted from "./pages/onboarding/GetStarted"
import EmployeeDashboard from "navigation/employee_dashboard"
import { AddWorkplaceForm, ManageWorkplace, EditWorkplaceForm } from "pages"

import Onboarding from "./pages/onboarding/Onboarding"
import OnboardingEmployee from "./pages/onboarding/OnboardingEmployee"
import OnboardingEmployer from "./pages/onboarding/OnboardingEmployer"
import EmployerOnboarding from "./pages/onboarding/EmployerOnboarding"
import OnboardingBusinessRegisteration from "./pages/onboarding/BusinessRegisteration"
import OnboardingEmailVerification from "./pages/onboarding/OnboardingEmailVerification"

import Login from "./pages/auth/Login"
import DownloadPayslip from "pages/employee-dashboard/payslip/downloadPayslips"
import EmployeeSetPassword from "./pages/employee-dashboard/EmployeeSetPassword"
import PaymentRoutes from "navigation/payment"

import Managers from "./pages/dashboard/people/people/Managers"
import ApprovalRoutes from "navigation/approval"
import { Spin } from "antd"
import Withdrawals from "pages/dashboard/earnWageAccess/Withdrawals"
import EnrolledEmployees from "pages/dashboard/earnWageAccess/EnrolledEmployees"
import Invoice from "pages/dashboard/earnWageAccess/Invoice"
import ViewInvoice from "pages/dashboard/earnWageAccess/ViewInvoice"
import EwaSettings from "pages/dashboard/extras/settings/hr-payroll/EwaSettings"
import RolesPermissions from "pages/dashboard/extras/settings/roles-permissions/RolesAndPermissionManagementPage"
import ViewRoleDetail from "pages/dashboard/extras/settings/roles-permissions/ViewRoleDetailPage"
import CreateCustomRole from "pages/dashboard/extras/settings/roles-permissions/CreateRolePage"
import Confirmation from "pages/dashboard/people/confirmation/Confirmation"
import Survey from "pages/dashboard/people/survey/Survey"
import NewCustomField from "pages/dashboard/extras/settings/customization/NewCustomField"
import SurveyTemplate from "pages/dashboard/people/survey/Templates/surveyTemplate"
import CreateSurvey from "pages/dashboard/people/survey/Surveys/component/CreateSurvey"
import Exits from "pages/dashboard/people/exits/Exits"
import SwitchApp from "./pages/auth/SwitchApp"
import EmployeePayslips from "pages/dashboard/people/employee/job-and-pay/Payslips"
import EmployeeViewPayslip from "pages/dashboard/people/employee/job-and-pay/ViewPayslip"
import ViewAllPayslips from "pages/dashboard/people/employee/job-and-pay/ViewAllPayslips"
import EditCustomField from "pages/dashboard/extras/settings/customization/EditCustomField"
import FinishSurvey from "pages/dashboard/people/survey/Surveys/component/FinishSurvey"
import EditCompanyInfo from "pages/dashboard/documents/company-details/EditCompanyInfo"
import ViewOfferDocument from "pages/dashboard/documents/documents/components/ViewOfferDocument"
import { ReportRoutes } from "pages/dashboard/documents/reports/reports.routes"
import NewResponsiveAdminLayout from "components/new-stuff/layout/Layout"
import ReviewReport from "pages/dashboard/tasks/performance/reviews/reviews/component/ReviewReport"
import Analytics from "pages/dashboard/earnWageAccess/Analytics"
import PayInvoice from "pages/dashboard/extras/settings/plan-billing/components/PayInvoice"
import RequireRoutePermit from "RequireRoutePermit"
import payrollConfigRoutes from "pages/dashboard/payroll/configurations/payrollconfig.routes"
import { ToastContainer } from "react-toastify"
import TwoFaSetupPage from "pages/2fa/TwoFaSetUpPage"
import AppEntryPoint from "AppEntryPoint"
import { ViewViolationPage } from "pages/employee-dashboard/disciplinary/ViewViolationPage"
import AcceptOfferLetter from "./pages/dashboard/people/people/offers/AcceptOfferLetter"
import { hiringRoutes } from "pages/dashboard/people/hiring/hiring.routes"
import payrollCompanyOnboardingRoutes from "pages/dashboard/new-company-onboarding/new-company-onboarding.routes"
import ViewPayrollReceipt from "pages/dashboard/documents/reports/ViewPayrollReceipt"
import CreateAnnouncementGroup from "pages/dashboard/extras/settings/announcements/CreateAnnouncementGroup"

const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"))
const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"))
// const WelcomePage = React.lazy(
//   () => import("./pages/dashboard/dashboard/Welcome")
// )

const Dashboard = React.lazy(
  () => import("./pages/dashboard/dashboard/Dashboard")
)
const Profile = React.lazy(() => import("./pages/dashboard/profile/Profile"))
const Employees = React.lazy(
  () => import("./pages/dashboard/people/people/employees/Employees")
)
const AddNewEmployee = React.lazy(
  () => import("./pages/dashboard/people/people/employees/AddNewEmployee")
)
const BulkUploadEmployees = React.lazy(
  () => import("./pages/dashboard/people/people/employees/BulkUploadEmployees")
)
const BulkUploadAccounts = React.lazy(
  () => import("./pages/dashboard/people/people/employees/BulkUploadAccounts")
)
const BulkAnnualGross = React.lazy(
  () =>
    import("./pages/dashboard/people/people/employees/BulkUpdateAnnualGross")
)
const BulkUpdateEmployees = React.lazy(
  () => import("./pages/dashboard/people/people/employees/BulkUpdateEmployees")
)
const BulkSplitSettings = React.lazy(
  () => import("./pages/dashboard/people/people/employees/BulkSplitSettings")
)
const NeedsAttention = React.lazy(
  () => import("./pages/dashboard/people/people/needs-attention")
)
const ReviewEmployees = React.lazy(
  () =>
    import("./pages/dashboard/people/people/needs-attention/review-employees")
)
const Departments = React.lazy(
  () => import("./pages/dashboard/people/people/department/Departments")
)
const ExternalAdmins = React.lazy(
  () => import("./pages/dashboard/people/people/ExternalAdmins")
)
const DepartmentMembers = React.lazy(
  () => import("./pages/dashboard/people/people/department/DepartmentMembers")
)
const AddDepartmentMembers = React.lazy(
  () =>
    import("./pages/dashboard/people/people/department/AddDepartmentMembers")
)
const Offers = React.lazy(
  () => import("./pages/dashboard/people/people/offers/Offers")
)
const Contractor = React.lazy(
  () => import("pages/dashboard/people/Contractor/Contractor")
)
const AddContractor = React.lazy(
  () => import("pages/dashboard/people/Contractor/AddContractor")
)
const ViewContractor = React.lazy(
  () => import("pages/dashboard/people/Contractor/ViewContractor")
)

const BulkCreateContractors = React.lazy(
  () => import("./pages/dashboard/people/Contractor/BulkUploadContactor")
)
const BulkUpdateContractors = React.lazy(
  () => import("./pages/dashboard/people/Contractor/BulkUpdateContractor")
)
const SendOffer = React.lazy(
  () => import("./pages/dashboard/people/people/offers/SendOffer")
)
// const EditOffer = React.lazy(
//   () => import("./pages/dashboard/people/people/offers/EditOffer")
// )

const Documents = React.lazy(
  () => import("./pages/dashboard/documents/documents/Documents")
)

const CreateDocument = React.lazy(
  () => import("./pages/dashboard/documents/documents/CreateDocument")
)
// const CreateOfferDocument = React.lazy(
//   () => import("./pages/dashboard/documents/documents/CreateOfferDocument")
// )
const UploadDocument = React.lazy(
  () => import("./pages/dashboard/documents/documents/UploadDocument")
)
const DocumentComplete = React.lazy(
  () => import("./pages/dashboard/documents/documents/DocumentComplete")
)

const Benefits = React.lazy(
  () => import("./pages/dashboard/people/benefits/Benefits")
)
const AddNewBenefit = React.lazy(
  () =>
    import("./pages/dashboard/people/benefits/add-new-benefit/AddNewBenefit")
)
const BulkEnrolEmployeesPFA = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/benefits/update-benefit/BulkEnrolEmployeesToPFA"
    )
)

const ViewBenefit = React.lazy(
  () => import("./pages/dashboard/people/benefits/ViewBenefit")
)

const UpdateBenefitEmployees = React.lazy(
  () => import("./pages/dashboard/people/benefits/UpdateBenefitEmployees")
)
const UpdatePensionEmployees = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/benefits/update-benefit/pages/UpdatePensionEmployees"
    )
)
const UpdateHMOEmployees = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/benefits/update-benefit/pages/UpdateHMOEmployees"
    )
)

const MedicalBenefit = React.lazy(
  () => import("./pages/dashboard/people/benefits/MedicalBenefit")
)
const BulkEnrolEmployeesToHMO = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/benefits/update-benefit/BulkEnrolEmployeesToHMOPlan"
    )
)
const ManageMedicalBenefit = React.lazy(
  () => import("./pages/dashboard/people/benefits/ManageMedicalBenefit")
)
const ViewManageMedicalBenefit = React.lazy(
  () => import("./pages/dashboard/people/benefits/ViewMedicalBenefit")
)
const PensionBenefit = React.lazy(
  () => import("./pages/dashboard/people/benefits/PensionBenefit")
)
const ViewPensionFundAdmin = React.lazy(
  () => import("./pages/dashboard/people/benefits/ViewPensionFundAdmin")
)
const ManagePensionFundAdmin = React.lazy(
  () => import("./pages/dashboard/people/benefits/ManagePensionFundAdmin")
)
const Calendar = React.lazy(() => import("./pages/dashboard/calendar/Calendar"))
const CalendarTrial = React.lazy(
  () => import("./pages/dashboard/calendar/CalendarTrial")
)
const Assets = React.lazy(
  () => import("./pages/dashboard/documents/assets/Assets")
)
const AddAsset = React.lazy(
  () => import("./pages/dashboard/documents/assets/AddAsset")
)
const EditAsset = React.lazy(
  () => import("./pages/dashboard/documents/assets/EditAsset")
)
const UpdateAsset = React.lazy(
  () => import("./pages/dashboard/documents/assets/UpdateAsset")
)
const DeleteAsset = React.lazy(
  () => import("./pages/dashboard/documents/assets/DeleteAsset")
)
const EnrollPeople = React.lazy(
  () =>
    import(
      "./pages/dashboard/documents/assets/AddOrRemoveEmployeesFromAssetPage"
    )
)
const CompleteAddAsset = React.lazy(
  () => import("./pages/dashboard/documents/assets/CompleteAddAsset")
)

const Assignments = React.lazy(
  () => import("./pages/dashboard/documents/assets/Assignments")
)
const AddAssignment = React.lazy(
  () => import("./pages/dashboard/documents/assets/AddAssignment")
)
const ViewAssetPage = React.lazy(
  () => import("./pages/dashboard/documents/assets/ViewAssetPage")
)
const EditAssignmentPage = React.lazy(
  () => import("./pages/dashboard/documents/assets/EditAssignmentPage")
)
const EmployeeAssetsPage = React.lazy(
  () => import("./pages/dashboard/documents/assets/EmployeeAssetsPage")
)

const Tasks = React.lazy(() => import("./pages/dashboard/tasks/tasks/Tasks"))

const EditRountineTask = React.lazy(
  () => import("./pages/dashboard/tasks/tasks/new/EditRountineTask")
)

const RoutineTasks = React.lazy(
  () => import("./pages/dashboard/tasks/tasks/RoutineTasks")
)
const OnboardingTasks = React.lazy(
  () => import("./pages/dashboard/tasks/tasks/OnboardingTasks")
)
const NewRoutineTask = React.lazy(
  () => import("./pages/dashboard/tasks/tasks/new/NewRoutineTask")
)

const Violations = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/Violations")
)
const Policies = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/Policies")
)
const NewPolicy = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/NewPolicy")
)
const ViewPolicy = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/ViewPolicy")
)
const Triggers = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/Triggers")
)
const ViolationTypes = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/ViolationTypes")
)
const EditPolicy = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/EditPolicy")
)
const EditViolationPolicy = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/EditViolationPolicy")
)
const NewViolation = React.lazy(
  () => import("./pages/dashboard/tasks/disciplinary/NewViolation")
)

const CompanyDetails = React.lazy(
  () => import("./pages/dashboard/documents/company-details/CompanyDetails")
)
const CreateNewLinkedAccount = React.lazy(
  () =>
    import("./pages/dashboard/documents/company-details/CreateNewLinkedAccount")
)
const AnnouncementGroups = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/announcements/AnnouncementGroup")
)

const OrganisationChart = React.lazy(
  () => import("./pages/dashboard/people/people/org-chart/OrganisationChart")
)
const PerformancePeriod = React.lazy(
  () => import("./pages/dashboard/tasks/performance/period/Period")
)
const PerformanceObjectives = React.lazy(
  () => import("./pages/dashboard/tasks/performance/objectives/Objectives")
)
const PerformanceGroup = React.lazy(
  () => import("./pages/dashboard/tasks/performance/group/Groups")
)
const ViewPerformanceGroup = React.lazy(
  () => import("./pages/dashboard/tasks/performance/group/ViewGroup")
)
const NewPerformanceGroup = React.lazy(
  () => import("./pages/dashboard/tasks/performance/group/NewGroups")
)
const EditPerformanceGroup = React.lazy(
  () => import("./pages/dashboard/tasks/performance/group/EditGroup")
)
const Ratings = React.lazy(
  () => import("./pages/dashboard/tasks/performance/ratings/Ratings")
)
const PerformanceReview = React.lazy(
  () => import("./pages/dashboard/tasks/performance/reviews/ViewReview")
)
const ViewReviewDetailed = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/reviews/reviews/ViewReviewDetailed"
    )
)
const ViewReviewsEmployee = React.lazy(
  () =>
    import("./pages/dashboard/tasks/performance/reviews/reviews/ViewEmployee")
)

const EditQuestionTemplate = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/reviews/Templates/EditQuestionTemplate"
    )
)
const CreatePerformanceReview = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/reviews/create-review/CreatePerformanceReview"
    )
)
const CreatePerformanceReviewPending = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/reviews/edit-review/FinishCreateReviewPending"
    )
)
const EditPerformanceReview = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/reviews/edit-review/EditPerformanceReview"
    )
)
const PerformanceManagement = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/performance-management/PerformanceManagement"
    )
)
const PerformanceManagementHistory = React.lazy(
  () =>
    import(
      "./pages/dashboard/tasks/performance/performance-management/PerformanceManagementHistory"
    )
)
const NewGoal = React.lazy(
  () => import("./pages/dashboard/tasks/performance/goals/NewGoal")
)
const Goals = React.lazy(
  () => import("./pages/dashboard/tasks/performance/goals/Goals")
)

const TimeTracking = React.lazy(
  () => import("./pages/dashboard/documents/time-tracking/TimeTracking")
)
const AbsenceBatch = React.lazy(
  () => import("./pages/dashboard/documents/time-tracking/AbsenceBatch")
)
const Absence = React.lazy(
  () => import("./pages/dashboard/documents/time-tracking/Absence")
)
const BulkUploadAbsentDays = React.lazy(
  () => import("./pages/dashboard/documents/time-tracking/BulkUploadAbsentDays")
)
const RecordEmployeeAbsence = React.lazy(
  () =>
    import("./pages/dashboard/documents/time-tracking/RecordEmployeeAbsence")
)
const Permissions = React.lazy(
  () => import("./pages/dashboard/extras/settings/permissions/Permissions")
)
const Admins = React.lazy(
  () => import("./pages/dashboard/extras/settings/permissions/AdminsListPage")
)
const NewAdmin = React.lazy(
  () => import("./pages/dashboard/extras/settings/permissions/AddAdminPage")
)
const EditAdmin = React.lazy(
  () => import("./pages/dashboard/extras/settings/permissions/EditAdminPage")
)

const ManagerPermission = React.lazy(
  () => import("./pages/dashboard/people/people/Managers")
)

const ApprovalWorkflow = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/approval-workflow/ApprovalWorkflow"
    )
)
const PlanAndBilling = React.lazy(
  () => import("./pages/dashboard/extras/settings/plan-billing/PlanAndBilling")
)
const Invoices = React.lazy(
  () => import("./pages/dashboard/extras/settings/plan-billing/Invoices")
)
const ViewBillingInvoice = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/plan-billing/components/ViewBillingInvoice"
    )
)
const PlansModal = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/plan-billing/components/PlansModal"
    )
)
const CustomRoles = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/approval-workflow/CustomRoles")
)
const NewCustomRole = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/approval-workflow/NewCustomRole")
)
const EditCustomRole = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/approval-workflow/EditCustomRoles"
    )
)
const CustomNotifications = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/approval-workflow/CustomNotifications"
    )
)
const EditCustomNotifications = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/approval-workflow/EditCustomNotifications"
    )
)
const NewCustomNotifications = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/approval-workflow/NewCustomNotifications"
    )
)
const Customization = React.lazy(
  () => import("./pages/dashboard/extras/settings/customization/Customization")
)
const ManageCustomFields = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/customization/ManageCustomFields")
)
const ManageCustomFieldGroups = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/customization/ManageCustomFieldGroups"
    )
)
const BulkUploadCustomFields = React.lazy(
  () =>
    import(
      "./pages/dashboard/extras/settings/customization/BulkUploadCustomFields"
    )
)

const HRandPayroll = React.lazy(
  () => import("./pages/dashboard/extras/settings/hr-payroll/HRandPayroll")
)

const EmployeeTitles = React.lazy(
  () => import("./pages/dashboard/people/people/employee-titles/EmployeeTitles")
)
const EmployeeTitleForm = React.lazy(
  () =>
    import("./pages/dashboard/people/people/employee-titles/EmployeeTitleForm")
)
const Audits = React.lazy(
  () => import("./pages/dashboard/extras/audits/Audits")
)

// ======================= //
// ========POLICIES======= //
// ========POLICIES======= //

const PaidHolidays = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/leave-policy/other-holidays/PaidHolidays"
    )
)
const FederalHolidays = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/leave-policy/other-holidays/FederalHolidays"
    )
)

const RequestPolicy = React.lazy(
  () => import("./pages/dashboard/people/requests/policies/RequestPolicy")
)
const CreateRequestPolicy = React.lazy(
  () => import("./pages/dashboard/people/requests/policies/CreateRequestPolicy")
)
const NewLeavePolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/leave-policy/new-leave-policy/NewLeavePolicy"
    )
)
const EditLeavePolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/leave-policy/edit-leave-policy/EditLeavePolicy"
    )
)
const NewDocumentRequestPolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/document-policies/new-document-policy/NewDocumentPolicy"
    )
)
const EditDocumentPolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/document-policies/edit-document-policy/EditDocumentPolicy"
    )
)
const NewLoanSalaryAdvancePolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/loan-salaries-policies/new-loan-salary-advance-policy/NewLoanSalaryAdvancePolicy"
    )
)
const EditLoanSalaryAdvancePolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/loan-salaries-policies/edit-loan-salary-advance-policy/EditLoanSalaryAdvancePolicy"
    )
)
const NewExitPolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/exit-policies/new-exit-policy/NewExitPolicy"
    )
)
const EditExitPolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/exit-policies/edit-exit-policy/EditExitPolicy"
    )
)
const EditReploymentPolicy = React.lazy(
  () =>
    import(
      "./pages/dashboard/people/requests/policies/redeployment-policies/edit-redeployment-policy/AddRemovePaygrades"
    )
)

const Announcements = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/announcements/AnnouncementList")
)
const AnnouncementDetail = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/announcements/AnnouncementDetail")
)
const EditAnnouncement = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/announcements/EditAnnouncement")
)
const CreateAnnouncement = React.lazy(
  () =>
    import("./pages/dashboard/extras/settings/announcements/CreateAnnouncement")
)
// const CreateNewRequestPolicy = React.lazy(
//   () =>
//     import(
//       "./pages/dashboard/people/requests/policies/new/CreateNewRequestPolicy"
//     )
// )
// const NewDocumentRequestPolicy = React.lazy(
//   () =>
//     import(
//       "./pages/dashboard/people/requests/policies/new/CreateDocumentRequestPolicy"
//     )
// )
const ViewLeaveRequestPolicy = React.lazy(
  () =>
    import("./pages/dashboard/people/requests/policies/ViewLeaveRequestPolicy")
)
const ViewRequestPolicy = React.lazy(
  () => import("./pages/dashboard/people/requests/policies/ViewRequestPolicy")
)
// const EditPolicyPaygrade = React.lazy(
//   () => import("./pages/dashboard/people/requests/policies/EditPolicyPaygrade")
// )
const PayRoll = React.lazy(() => import("./pages/dashboard/payroll/PayRoll"))
const PayPeriod = React.lazy(
  () => import("./pages/dashboard/payroll/payperiod/PayPeriod")
)
const PayRollChange = React.lazy(
  () => import("./pages/dashboard/payroll/change/PayRollChange")
)
const PayRollChangeForm = React.lazy(
  () => import("./pages/dashboard/payroll/change/PayRollChangeForm")
)
const PayRollRecord = React.lazy(
  () => import("./pages/dashboard/payroll/records/PayRollRecord")
)
const PayRollRecordView = React.lazy(
  () => import("./pages/dashboard/payroll/records/PayRollRecordView")
)
// const PayRollRecordViewDetails = React.lazy(
//   () => import("./pages/dashboard/payroll/payroll-record-view-details")
// )
const PayRollReportSummary = React.lazy(
  () => import("./pages/dashboard/payroll/records/PayRollReportSummary")
)
const PayRollReportDetails = React.lazy(
  () => import("./pages/dashboard/payroll/records/PayRollReportDetails")
)

const PayRollReportVariance = React.lazy(
  () => import("./pages/dashboard/payroll/records/PayRollReportVariance")
)
const PayrollRecordViewPending = React.lazy(
  () => import("./pages/dashboard/payroll/components/PayRollRecordViewPending")
)
const PreparePayrollPreviewPending = React.lazy(
  () =>
    import("./pages/dashboard/payroll/components/PreparePayrollPreviewPending")
)
const SkipPayRollForm = React.lazy(
  () => import("./pages/dashboard/payroll/skip/PayRollSkip")
)
const SkipPayRollBulk = React.lazy(
  () => import("./pages/dashboard/payroll/skip/PayRollSkipBulk")
)
const PayRollBulkChange = React.lazy(
  () => import("./pages/dashboard/payroll/change/BulkPayRollChangeUpload")
)

const ReportSummary = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summary")
)
const CustomReport = React.lazy(
  () => import("./pages/dashboard/documents/reports/CustomReport")
)

const DocumentsEditor = React.lazy(
  () => import("./pages/dashboard/documents/documents/components/DocEditor")
)
const ViewEmployeeDoc = React.lazy(
  () => import("./pages/dashboard/documents/documents/ViewEmployeeDoc")
)

const EditOfferTemplate = React.lazy(
  () => import("./pages/dashboard/documents/documents/EditOfferTemplate")
)
const PreviewJob = React.lazy(
  () => import("./pages/dashboard/people/hiring/screens/PreviewRole")
)
const ApplicantPage = React.lazy(
  () => import("./pages/dashboard/people/hiring/screens/ApplicantPage")
)
const JobsPublicPage = React.lazy(
  () => import("./pages/dashboard/people/hiring/screens/PublicJobListing")
)

const PayrollHistoryReport = React.lazy(
  () => import("./pages/dashboard/documents/reports/PayrollHistory")
)
const ViewPayrollHistoryReport = React.lazy(
  () => import("./pages/dashboard/documents/reports/ViewPayrollHistory")
)
const PayrollJournal = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PayrollJournal")
)
const PayrollSummary = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PayrollSummary")
)
const PayrollVariance = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PayrollVariance")
)
const PayrollYearToDate = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/YearToDate")
)
const PayrollStatustoryBenefits = React.lazy(
  () =>
    import("./pages/dashboard/documents/reports/Summaries/StatustoryBenefits")
)
const PayrollCustomBenefits = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/CustomBenefits")
)
const EmployeeReportBuilder = React.lazy(
  () =>
    import(
      "./pages/dashboard/documents/reports/Summaries/EmployeeReportBuilder"
    )
)
const ReportPayrollChange = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PayrollChange")
)
const ReportPayrollExemption = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PayrollExemption")
)
const ReportPaygradeSummary = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PaygradeSummary")
)
const ReportPaybandSummary = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/PaybandSummary")
)
const ReportEmployeeRequest = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/EmployeeRequest")
)
const ReportLeaveBalances = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/LeaveBalances")
)
const ReportBankTransactions = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/BankTransactions")
)
const AgencyPaymentsReport = React.lazy(
  () => import("./pages/dashboard/documents/reports/Summaries/AgencyPayments")
)
const KYC = React.lazy(() => import("./pages/dashboard/kyc/Kyc"))

export function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0 left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}

function App() {
  return (
    <AppEntryPoint>
      <Suspense
        fallback={
          <div className="max-w-full max-h-full overflow-hidden">
            <div className=" w-full h-screen bg-white grid place-content-center">
              <Spin />
            </div>
          </div>
        }
      >
        <Routes>
          {/* Onboarding */}
          <Route
            path="/get-started"
            element={<GetStarted />}
          />
          <Route
            path="/"
            element={<Login />}
          />
          <Route
            path="/onboarding"
            element={<Onboarding />}
          />

          <Route
            path="/onboarding/employer"
            element={<EmployerOnboarding />}
          />
          <Route
            path="/onboarding/employer/old"
            element={<OnboardingEmployer />}
          />

          <Route
            path="/accept-offer"
            element={<AcceptOfferLetter />}
          />

          <Route
            path="/job/:jobId/preview"
            element={<PreviewJob />}
          />
          <Route
            path="/job/:jobId/apply"
            element={<ApplicantPage />}
          />
          <Route
            path="/public-jobs/"
            element={<JobsPublicPage />}
          />

          <Route
            path="/onboarding/business-registration"
            element={<OnboardingBusinessRegisteration />}
          />
          {/* <Route
            path="/onboarding/business-registration-test"
            element={<OnboardingBusinessRegisterationCopy />}
          /> */}
          <Route
            path="/onboarding/verify-email"
            element={<OnboardingEmailVerification />}
          />
          <Route
            path="/onboarding/employee"
            element={<OnboardingEmployee />}
          />

          <Route
            path="/account/view/register"
            element={<OnboardingEmployer />}
          />
          <Route
            path="employee/account/setpassword"
            element={<EmployeeSetPassword />}
          />

          {/* Auth */}
          <Route path="/auth">
            <Route
              path="login"
              element={<Login />}
            />
            <Route
              path="switch"
              element={<SwitchApp />}
            />

            <Route
              path="forgot-password"
              element={<ForgotPassword />}
            />
            <Route
              path="reset-password"
              element={<ResetPassword />}
            />
          </Route>

          {/* Employee Dashboard */}
          {EmployeeDashboard()}

          {/* Payslip */}
          <Route
            path="/download-payslip"
            element={<DownloadPayslip />}
          />
          {/* approval */}
          <Route path="approval">{ApprovalRoutes()}</Route>

          {/* Dashboard */}
          <Route
            path="/dashboard"
            element={<NewResponsiveAdminLayout />}
          >
            <Route
              path="twofa-setup"
              element={<TwoFaSetupPage />}
            />
            <Route
              path="notifications"
              Component={React.lazy(() => import("./pages/notifications/"))}
            />
            <Route
              index
              element={<Dashboard />}
            />
            {/* <Route
              path="welcome"
              element={<WelcomePage />}
            /> */}
            {payrollCompanyOnboardingRoutes}
            {/* Profile */}
            <Route
              path="profile"
              element={<Profile />}
            />
            {/* KYC */}
            <Route
              path="kyc"
              element={<KYC />}
            />
            {/* employees */}
            <Route
              path="employees"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_employee_details"]}
                >
                  <Employees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employee/payslips"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_employee_details"]}
                >
                  <EmployeePayslips />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employee/payslip/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_employee_details"]}
                >
                  <EmployeeViewPayslip />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employee/payslips/all"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_employee_details"]}
                >
                  <ViewAllPayslips />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employees/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_employees"]}
                >
                  <AddNewEmployee />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employees/bulk-upload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_employees"]}
                >
                  <BulkUploadEmployees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employees/accounts/bulk-upload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_employees"]}
                >
                  <BulkUploadAccounts />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employees/annual_gross/bulk-upload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_employees"]}
                >
                  <BulkAnnualGross />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employees/bulk-update"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_employees"]}
                >
                  <BulkUpdateEmployees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="employees/split-settings"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_employees"]}
                >
                  <BulkSplitSettings />
                </RequireRoutePermit>
              }
            />
            {/* Documents */}
            <Route
              path="documents"
              element={
                <RequireRoutePermit
                  allowedPermissions={[
                    "can_view_documents_and_offer_templates",
                  ]}
                >
                  <Documents />
                </RequireRoutePermit>
              }
            />
            <Route
              path="documents/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_offer_templates"]}
                >
                  <CreateDocument />
                </RequireRoutePermit>
              }
            />
            <Route
              path="documents/:type/create/complete"
              element={
                <RequireRoutePermit
                  allowedPermissions={[
                    "can_view_documents_and_offer_templates",
                  ]}
                >
                  <DocumentComplete />
                </RequireRoutePermit>
              }
            />
            <Route
              path="documents/upload"
              element={<UploadDocument />}
            />
            <Route
              path="documents/editor"
              element={<DocumentsEditor />}
            />
            <Route
              path="documents/view-employee-document"
              element={
                <RequireRoutePermit
                  allowedPermissions={[
                    "can_view_documents_and_offer_templates",
                  ]}
                >
                  <ViewEmployeeDoc />
                </RequireRoutePermit>
              }
            />
            <Route
              path="documents/offer-template"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_offer_templates"]}
                >
                  <EditOfferTemplate />
                </RequireRoutePermit>
              }
            />
            {/* ======== Hiring  ======== */}
            {hiringRoutes()}
            {/* ======== PAYROLL ======== */}
            {/* ======== PAYROLL ======== */}
            <Route
              path="payroll"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_overview"]}
                >
                  <PayRoll />
                </RequireRoutePermit>
              }
            />
            {payrollConfigRoutes}
            <Route
              path="payroll/payperiod"
              element={<PayPeriod />}
            />
            <Route
              path="payroll/payrollchange"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_changes"]}
                >
                  <PayRollChange />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/payrollchange/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_changes"]}
                >
                  <PayRollChangeForm />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/payrollrecord"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_records"]}
                >
                  <PayRollRecord />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/payrollrecord/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_records"]}
                >
                  <PayRollRecordView />
                </RequireRoutePermit>
              }
            />
            {/* <Route
              path="payroll/payrollrecord/view/details"
              element={<PayRollRecordViewDetails />}
            /> */}
            <Route
              path="payroll/report-summary"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_records"]}
                >
                  <PayRollReportSummary />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/report-variance"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_records"]}
                >
                  <PayRollReportVariance />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/:payrollId/report-details"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_records"]}
                >
                  <PayRollReportDetails />
                </RequireRoutePermit>
              }
            />
            {/* Report Preview */}
            {ReportRoutes()}
            {/* Report Preview end */}
            <Route
              path="payroll/payrollrecord/view/pending"
              element={<PayrollRecordViewPending />}
            />
            <Route
              path="payroll/payperiod/pending"
              element={<PreparePayrollPreviewPending />}
            />
            <Route
              path="payroll/payrollskip"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_skipped_employees"]}
                >
                  <SkipPayRollForm />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/payrollskip/bulkupload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_skipped_employees"]}
                >
                  <SkipPayRollBulk />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/change/bulkupload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_changes"]}
                >
                  <PayRollBulkChange />
                </RequireRoutePermit>
              }
            />
            <Route
              path="payroll/change/bulkupload-new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payroll_changes"]}
                >
                  <PayRollBulkChange />
                </RequireRoutePermit>
              }
            />
            {/* ======= REPORTS ======= */}
            {/* ======= REPORTS ======= */}
            <Route
              path="report"
              element={<ReportSummary />}
            >
              {/* <Route
                path="payroll-journal"
                element={<PayrollJournal/>}
              /> */}
            </Route>
            <Route
              path="report/payroll-journal"
              element={<PayrollJournal />}
            />
            <Route
              path="report/payroll-summary"
              element={<PayrollSummary />}
            />
            <Route
              path="report/payroll-variance"
              element={<PayrollVariance />}
            />
            <Route
              path="report/year-to-date"
              element={<PayrollYearToDate />}
            />
            <Route
              path="report/statustory-benefits"
              element={<PayrollStatustoryBenefits />}
            />
            <Route
              path="report/custom-benefits"
              element={<PayrollCustomBenefits />}
            />
            <Route
              path="report/employee-report-builder"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
                  <EmployeeReportBuilder />
                </RequireRoutePermit>
              }
            />
            <Route
              path="report/payroll-change"
              element={<ReportPayrollChange />}
            />
            <Route
              path="report/payroll-exemption"
              element={<ReportPayrollExemption />}
            />
            <Route
              path="report/paygrade-summary"
              element={<ReportPaygradeSummary />}
            />
            <Route
              path="report/payband-summary"
              element={<ReportPaybandSummary />}
            />
            <Route
              path="report/employee-request"
              element={<ReportEmployeeRequest />}
            />
            <Route
              path="report/leave-balances"
              element={<ReportLeaveBalances />}
            />
            <Route
              path="report/bank-transactions"
              element={<ReportBankTransactions />}
            />
            <Route
              path="report/statutory-payments"
              element={<AgencyPaymentsReport />}
            />
            <Route
              path="report/custom-report"
              element={<CustomReport />}
            />
            <Route
              path="reports-payroll-history"
              element={<PayrollHistoryReport />}
            />
            <Route
              path="reports-payroll-history/view"
              element={<ViewPayrollHistoryReport />}
            />
            <Route
              path="reports/payroll-receipt"
              element={<ViewPayrollReceipt />}
            />
            {/* Assets */}
            <Route
              path="assets"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_assets"]}>
                  <Assets />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/new"
              element={
                <RequireRoutePermit allowedPermissions={["can_create_assets"]}>
                  <AddAsset />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/new/complete"
              element={
                <RequireRoutePermit allowedPermissions={["can_create_assets"]}>
                  <CompleteAddAsset />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/edit/:assetid"
              element={
                <RequireRoutePermit allowedPermissions={["can_update_assets"]}>
                  <EditAsset />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/delete/:assetid"
              element={
                <RequireRoutePermit allowedPermissions={["can_delete_assets"]}>
                  <DeleteAsset />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/update/:assetid"
              element={
                <RequireRoutePermit allowedPermissions={["can_update_assets"]}>
                  <UpdateAsset />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/:assetid/enroll-people"
              element={
                <Suspense
                  fallback={
                    <div className="max-w-full max-h-full overflow-hidden">
                      <div className=" w-full h-screen bg-white grid place-content-center">
                        <Spin />
                      </div>
                    </div>
                  }
                >
                  <RequireRoutePermit
                    allowedPermissions={["can_update_assets"]}
                  >
                    <EnrollPeople />
                  </RequireRoutePermit>
                </Suspense>
              }
            />
            <Route
              path="assignments"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_assets"]}>
                  <Assignments />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assignments/new"
              element={
                <RequireRoutePermit allowedPermissions={["can_update_assets"]}>
                  <AddAssignment />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assignments/view/:id"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_assets"]}>
                  <ViewAssetPage />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assets/view/:id"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_assets"]}>
                  <ViewAssetPage />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assignments/edit/:id"
              element={
                <RequireRoutePermit allowedPermissions={["can_update_assets"]}>
                  <EditAssignmentPage />
                </RequireRoutePermit>
              }
            />
            <Route
              path="assignments/employee/:id"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_assets"]}>
                  <EmployeeAssetsPage />
                </RequireRoutePermit>
              }
            />
            {/* Benefits */}
            <Route
              path="benefits"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_benefits"]}>
                  <Benefits />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_benefits"]}
                >
                  <AddNewBenefit />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/medical"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_benefits"]}>
                  <MedicalBenefit />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/medical/bulk-upload"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_benefits"]}>
                  <BulkEnrolEmployeesToHMO />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/medical/manage"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <ManageMedicalBenefit />
                </RequireRoutePermit>
              }
            />
            {/* <Route
              path="benefits/medical/:hmo/details"
              element={<ViewMedicalBenefit />}
              // element={<ViewManageMedicalBenefit />}
            /> */}
            <Route
              path="benefits/pensions"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_benefits"]}>
                  <PensionBenefit />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/pensions/manage"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <ManagePensionFundAdmin />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/pensions/:pfaid/details"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <ViewPensionFundAdmin />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/pensions/bulk-upload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <BulkEnrolEmployeesPFA />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/view/:benefitid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <ViewBenefit />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/edit-employees/:benefitid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <UpdateBenefitEmployees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/pensions/edit-employees/:benefitid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <UpdatePensionEmployees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="benefits/hmo/edit-employees/:hmoPlanKey"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_benefits"]}
                >
                  <UpdateHMOEmployees />
                </RequireRoutePermit>
              }
            />
            {/* tasks */}
            <Route
              path="tasks"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_tasks"]}>
                  <Tasks />
                </RequireRoutePermit>
              }
            >
              <Route
                index={true}
                element={
                  <RequireRoutePermit allowedPermissions={["can_view_tasks"]}>
                    <RoutineTasks />
                  </RequireRoutePermit>
                }
              />
              <Route
                path="onboarding"
                element={
                  <RequireRoutePermit allowedPermissions={["can_view_tasks"]}>
                    <OnboardingTasks />
                  </RequireRoutePermit>
                }
              />

              {/* <Route
                path="view"
                element={<EditRountineTask />}
              /> */}
            </Route>
            <Route
              path="tasks/new"
              element={
                <RequireRoutePermit allowedPermissions={["can_create_tasks"]}>
                  <NewRoutineTask />
                </RequireRoutePermit>
              }
            />
            <Route
              path="tasks/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_tasks", "can_view_tasks"]}
                >
                  <EditRountineTask />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/violations/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_violations"]}
                >
                  <NewViolation />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/violations"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_violations"]}
                >
                  <Violations />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/violations/:violationId"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_violations"]}
                >
                  <ViewViolationPage />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/triggers"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_triggers"]}
                >
                  <Triggers />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/policies"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_violation_policies"]}
                >
                  <Policies />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/policies/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_violation_policies"]}
                >
                  <NewPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/policies/view/:policyid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_violation_policies"]}
                >
                  <ViewPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/policies/:policyid/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_violation_policies"]}
                >
                  <EditPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/policies/update/:policyid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_violation_policies"]}
                >
                  <EditViolationPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="disciplinary/violation-types"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_violation_types"]}
                >
                  <ViolationTypes />
                </RequireRoutePermit>
              }
            />
            {/* performance */}
            <Route
              path="performance/performance-period"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_periods"]}>
                  <PerformancePeriod />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/performance-objectives"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_objectives"]}
                >
                  <PerformanceObjectives />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/groups"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_groups"]}>
                  <PerformanceGroup />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/groups/view-group"
              element={<ViewPerformanceGroup />}
            />
            <Route
              path="performance/groups/new"
              element={<NewPerformanceGroup />}
            />
            <Route
              path="performance/groups/edit"
              element={<EditPerformanceGroup />}
            />
            <Route
              path="performance/ratings"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_set_rating_weights"]}
                >
                  <Ratings />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <PerformanceReview />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews/reports"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <ReviewReport />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews/round/view"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <ViewReviewDetailed />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews/round/view-employee"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <ViewReviewsEmployee />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews/create"
              element={
                <RequireRoutePermit allowedPermissions={["can_create_reviews"]}>
                  <CreatePerformanceReview />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews/finish-review-pending"
              element={
                <RequireRoutePermit allowedPermissions={["can_create_reviews"]}>
                  <CreatePerformanceReviewPending />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance/reviews/edit"
              element={
                <RequireRoutePermit allowedPermissions={["can_create_reviews"]}>
                  <EditPerformanceReview />
                </RequireRoutePermit>
              }
            />

            <Route
              path="performance/reviews/template"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <EditQuestionTemplate />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance-management"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <PerformanceManagement />
                </RequireRoutePermit>
              }
            />
            <Route
              path="performance-management/history"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_reviews"]}>
                  <PerformanceManagementHistory />
                </RequireRoutePermit>
              }
            />
            {/* Calendar */}
            <Route
              path="calendar"
              element={<Calendar />}
            />
            {/* <Route
              path="calendar"
              element={

                  <CalendarTrial />

              }
            /> */}
            {/* goals */}
            <Route
              path="goals"
              element={<Goals />}
            />
            <Route
              path="goals/new"
              element={<NewGoal />}
            />

            {/* company details */}
            <Route
              path="company-details"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_company_details"]}
                >
                  <CompanyDetails />
                </RequireRoutePermit>
              }
            />
            <Route
              path="company-details/edit-info"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_edit_company_info"]}
                >
                  <EditCompanyInfo />
                </RequireRoutePermit>
              }
            />
            <Route
              path="company-details/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_new_businesses"]}
                >
                  <CreateNewLinkedAccount />
                </RequireRoutePermit>
              }
            />
            <Route
              path="company-details/add-workplace"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_workplaces"]}
                >
                  <AddWorkplaceForm />
                </RequireRoutePermit>
              }
            />
            <Route
              path="company-details/edit-workplace/:id"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_workplaces"]}
                >
                  <EditWorkplaceForm />
                </RequireRoutePermit>
              }
            />
            <Route
              path="company-details/manage-workplace"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_workplaces"]}
                >
                  <ManageWorkplace />
                </RequireRoutePermit>
              }
            />
            {/* Org. Chart */}
            <Route
              path="org-chart"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_org_chart"]}>
                  <OrganisationChart />
                </RequireRoutePermit>
              }
            />
            {/* Time tracking */}
            <Route
              path="time-tracking"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_time_tracking"]}
                >
                  <TimeTracking />
                </RequireRoutePermit>
              }
            />
            {/* Absencee */}
            <Route
              path="time-tracking/absence"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_time_tracking"]}
                >
                  <AbsenceBatch />
                </RequireRoutePermit>
              }
            />
            <Route
              path="time-tracking/absence/:id"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_time_tracking"]}
                >
                  <Absence />
                </RequireRoutePermit>
              }
            />
            <Route
              path="time-tracking/absence/:id/bulk-upload-absent-days"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_time_tracking"]}
                >
                  <BulkUploadAbsentDays />
                </RequireRoutePermit>
              }
            />
            <Route
              path="time-tracking/absence/:id/record-absence"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_time_tracking"]}
                >
                  <RecordEmployeeAbsence />
                </RequireRoutePermit>
              }
            />
            {/* Settings */}
            {/* permissions */}
            <Route
              path="settings/permissions"
              element={<Permissions />}
            />
            <Route
              path="settings/permissions/admins"
              element={<Admins />}
            />
            <Route
              path="settings/permissions/admins/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_invite_new_team_members"]}
                >
                  <NewAdmin />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/permissions/admins/edit/:userId"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_change_team_member_roles"]}
                >
                  <EditAdmin />
                </RequireRoutePermit>
              }
            />
            {/* ======= HRandPayroll ======== */}
            {/* ======= HRandPayroll ======== */}
            <Route
              path="settings/hr-and-payroll"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <HRandPayroll />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/ewa"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EwaSettings />
                </RequireRoutePermit>
              }
            />
            {/* <Route
              path="settings/hr-and-payroll/pay-schedules"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <PaySchedules />
                </RequireRoutePermit>
              }
            /> */}
            {/* <Route
              path="settings/hr-and-payroll/pay-schedules/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <CreateSchedule />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/pay-schedules/edit-custom"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EditScheduleCustom />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/pay-schedules/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EditScheduleRegular />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/paygrades"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <Paygrades />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/paygrades/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <CreateNewPaygrade />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/paygrades/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EditPaygrade />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/paygrades/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <ViewPaygrade />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/paygrades/view/employees"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <PaygradeEmployees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/allowances"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <Allowances />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/allowances/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <PargradeAllowanceForm />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/allowances/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EditAllowance />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/tax-tables"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <TaxTables />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/statutory-benefits"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <StatutoryBenefits />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/statutory-benefits/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <StatutoryBenefitForm />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/hr-and-payroll/statutory-benefits/edit/:statutorybenefitid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <StatutoryBenefitForm />
                </RequireRoutePermit>
              }
            /> */}
            <Route
              path="settings/hr-and-payroll/job-titles/edit/:employeetitleid"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EmployeeTitleForm />
                </RequireRoutePermit>
              }
            />
            {/* ======= Plan And Billing ======== */}
            {/* ======= Plan And Billing ======== */}
            <Route
              path="settings/plan-and-billing"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_plan_&_billing_information"]}
                >
                  <PlanAndBilling />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/plan-and-billing/invoices"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_plan_&_billing_information"]}
                >
                  <Invoices />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/plan-and-billing/invoices/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_plan_&_billing_information"]}
                >
                  <ViewBillingInvoice />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/plan-and-billing/invoices/pay"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_plan_&_billing_information"]}
                >
                  <PayInvoice />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/plan-and-billing/plans"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_plan_&_billing_information"]}
                >
                  <PlansModal />
                </RequireRoutePermit>
              }
            />
            {/* ======== Announcements ======== */}
            {/* ======== Announcements ======== */}
            <Route
              path="settings/announcements"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_announcements"]}
                >
                  <Announcements />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/announcement_groups"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_announcements"]}
                >
                  <AnnouncementGroups />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/announcements/view/:id"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_announcements"]}
                >
                  <AnnouncementDetail />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/announcements/edit/:id"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_announcements"]}
                >
                  <EditAnnouncement />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/announcements/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_announcements"]}
                >
                  <CreateAnnouncement />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/announcement-group/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_audience"]}
                >
                  <CreateAnnouncementGroup />
                </RequireRoutePermit>
              }
            />
            {/* ======= Approval Workflow ======== */}
            {/* ======= Approval Workflow ======== */}
            <Route
              path="settings/approval-workflow"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <ApprovalWorkflow />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/approval-workflow/custom-roles"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <CustomRoles />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/approval-workflow/custom-roles/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <NewCustomRole />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/approval-workflow/custom-roles/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <EditCustomRole />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/approval-workflow/custom-notifications"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <CustomNotifications />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/approval-workflow/custom-notifications/edit/:id"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <EditCustomNotifications />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/approval-workflow/custom-notifications/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <NewCustomNotifications />
                </RequireRoutePermit>
              }
            />
            {/* ======== Roles and permission ======== */}
            {/* ======== Roles and permission ======== */}
            <Route
              path="settings/roles-permissions"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <RolesPermissions />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/roles-permissions/view/:roleId"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_approval_workflow_settings"]}
                >
                  <ViewRoleDetail />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/roles-permissions/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_custom_roles"]}
                >
                  <CreateCustomRole />
                </RequireRoutePermit>
              }
            />
            {/* ======== Customization ======== */}
            {/* ======== Customization ======== */}
            <Route
              path="settings/customization"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_customization_settings"]}
                >
                  <Customization />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/customization/custom-fields"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_customization_settings"]}
                >
                  <ManageCustomFields />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/customization/custom-fields/new"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_customization_settings"]}
                >
                  <NewCustomField />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/customization/custom-fields/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_customization_settings"]}
                >
                  <EditCustomField />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/customization/custom-fields/bulk-upload"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_customization_settings"]}
                >
                  <BulkUploadCustomFields />
                </RequireRoutePermit>
              }
            />
            <Route
              path="settings/customization/custom-fields/groups"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_customization_settings"]}
                >
                  <ManageCustomFieldGroups />
                </RequireRoutePermit>
              }
            />
            {/* Audits */}
            <Route
              path="audits"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_audit_trails"]}
                >
                  <Audits />
                </RequireRoutePermit>
              }
            ></Route>
            {/* requests */}
            <Route path="requests">{Requests()}</Route>
            {/* wallet */}
            <Route path="wallets">{WalletRoutes()}</Route>
            {/* Earn wage access */}
            <Route
              path="ewa/analytics"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_analytics"]}>
                  <Analytics />
                </RequireRoutePermit>
              }
            />
            <Route
              path="ewa/employees"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_employees"]}>
                  <EnrolledEmployees />
                </RequireRoutePermit>
              }
            />
            <Route
              path="ewa/withdrawals"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_withdrawals"]}
                >
                  <Withdrawals />
                </RequireRoutePermit>
              }
            />
            <Route
              path="ewa/invoice"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payment_notes"]}
                >
                  <Invoice />
                </RequireRoutePermit>
              }
            />
            <Route
              path="ewa/invoice/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_payment_notes"]}
                >
                  <ViewInvoice />
                </RequireRoutePermit>
              }
            />
            {/* payment Schedules */}
            <Route path="payment-schedules">{PaymentRoutes()}</Route>
            {/* { Profile } */}
            <Route path="employee/:employeeKey">{EmployeeRoutes()}</Route>
            {/* policies */}
            <Route
              path="requests/policies"
              element={<RequestPolicy />}
            />
            <Route
              path="requests/policies/create"
              element={<CreateRequestPolicy />}
            />
            <Route
              path="requests/policies/create/leaves"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_leave_policies"]}
                >
                  <NewLeavePolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/edit/leaves"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_edit_leave_policies"]}
                >
                  <EditLeavePolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/create/loans"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_loan_policies"]}
                >
                  <NewLoanSalaryAdvancePolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/edit/loans"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_loan_policies"]}
                >
                  <EditLoanSalaryAdvancePolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/create/salary-advance"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_salary_advance_policies"]}
                >
                  <NewLoanSalaryAdvancePolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/edit/salary-advance"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_salary_advance_policies"]}
                >
                  <EditLoanSalaryAdvancePolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/create/exits"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_exit_policies"]}
                >
                  <NewExitPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/edit/exits"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_exit_policies"]}
                >
                  <EditExitPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/edit/redeployment"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_redeployment_policies"]}
                >
                  <EditReploymentPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/create/documents"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_create_document_policies"]}
                >
                  <NewDocumentRequestPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/edit/documents"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_update_document_policies"]}
                >
                  <EditDocumentPolicy />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/paid-holidays"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_leave_policies"]}
                >
                  <PaidHolidays />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/federal-holidays"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_leave_policies"]}
                >
                  <FederalHolidays />
                </RequireRoutePermit>
              }
            />
            <Route
              path="requests/policies/leave/view/:id"
              element={<ViewLeaveRequestPolicy />}
            />
            <Route
              path="requests/policies/:type/view/:id"
              element={<ViewRequestPolicy />}
            />
            {/* <Route
              path="requests/policies/:type/edit_paygrade/:id"
              element={<EditPolicyPaygrade />}
            /> */}
            {/* ======== Needs Attention ======= */}
            {/* ======== Needs Attention ======= */}
            <Route
              path="needs-attention"
              element={<NeedsAttention />}
            />
            <Route
              path="needs-attention/review-employees/:id"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_approve_employees"]}
                >
                  <ReviewEmployees />
                </RequireRoutePermit>
              }
            />
            {/* ======== Employee Titles ======= */}
            {/* ======== Employee Titles ======= */}
            <Route
              path="job-titles"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EmployeeTitles />
                </RequireRoutePermit>
              }
            />
            <Route
              path="job-titles/create"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_hr_&_payroll_settings"]}
                >
                  <EmployeeTitleForm />
                </RequireRoutePermit>
              }
            />
            {/* Manager */}
            <Route
              path="manager"
              element={<Managers />}
            />
            {/* ExternalAdmins */}
            <Route
              path="external-admins"
              element={<ExternalAdmins />}
            />
            {/* department */}
            <Route
              path="departments"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_departments"]}
                >
                  <Departments />
                </RequireRoutePermit>
              }
            />
            <Route
              path="departments/view"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_view_departments"]}
                >
                  <DepartmentMembers />
                </RequireRoutePermit>
              }
            />
            <Route
              path="departments/edit"
              element={
                <RequireRoutePermit
                  allowedPermissions={["can_manage_departments"]}
                >
                  <AddDepartmentMembers />
                </RequireRoutePermit>
              }
            />
            {/* Contractors */}
            <Route
              path="contractor"
              element={<Contractor />}
            />
            <Route
              path="contractor/create"
              element={<AddContractor />}
            />
            <Route
              path="contractor/bulk-create"
              element={<BulkCreateContractors />}
            />
            <Route
              path="contractor/bulk-update"
              element={<BulkUpdateContractors />}
            />
            <Route
              path="contractor/view"
              element={<ViewContractor />}
            />
            {/* offers */}
            <Route
              path="offers"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_offers"]}>
                  <Offers />
                </RequireRoutePermit>
              }
            />
            {/* <Route
              path="offers/edit"
              element={
                <RequireRoutePermit allowedPermissions={["can_view_offers"]}>
                  <EditOffer />
                </RequireRoutePermit>
              }
            /> */}
            <Route
              path="offers/send-offer"
              element={
                <RequireRoutePermit allowedPermissions={["can_make_offers"]}>
                  <SendOffer />
                </RequireRoutePermit>
              }
            />
            <Route
              path="documents/offer-template/view"
              element={<ViewOfferDocument />}
            />
            <Route
              path="exits"
              element={<Exits />}
            />
            <Route
              path="confirmation"
              element={<Confirmation />}
            />
            <Route
              path="confirmation/view-response"
              element={<Confirmation />}
            />
            <Route
              path="survey"
              element={<Survey />}
            />
            <Route
              path="survey/new"
              element={<CreateSurvey />}
            />
            <Route
              path="survey/edit"
              element={<FinishSurvey />}
            />
            <Route
              path="survey/template"
              element={<SurveyTemplate />}
            />
            <Route
              path="*"
              element={<UnknownPage />}
            />
          </Route>

          <Route
            path="*"
            element={
              <Navigate
                to="/auth/login"
                replace
              />
            }
          />
        </Routes>
        <ToastContainer />
      </Suspense>
    </AppEntryPoint>
  )
}

export default App
