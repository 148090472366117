import React, { useRef, useMemo, useState } from "react"
import TotalSalary from "assets/images/total_salary.svg"
import EmployeeLeave from "assets/images/employee_leave.svg"
import TotalEmployee from "assets/images/total_employee.svg"

import Barchart from "components/charts/Barchart"
import DoughnutChart from "components/charts/DoughnutChart"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useGet } from "utils/useFetch"
import { IDashboard } from "./typings"
import StackedBarchart from "components/charts/StackedBarchart"

import { Profile } from "./component/Profile"
import { Actions } from "./component/Actions"
import CustomCard from "components/new-stuff/ui/CustomCard"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useNavigate } from "react-router-dom"
import CustomInfoCard from "components/new-stuff/ui/CustomInfoCard"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const Dashboard: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { isLoading: isEventsDataLoading, data: EventsData } =
    useGet<IDashboard>({ url: "/admin/view/dashboard_events" })

  const { isLoading: isActionDataLoading, data: actionData } =
    useGet<IDashboard>({
      url: "/admin/view/dashboard_actions",
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: isPayrollLoading, data: payrollData } = useGet<IDashboard>(
    {
      url: "/admin/view/dashboard_payroll",
      cacheTime: 24 * 60 * 60 * 1000,
    }
  )
  const currentYear = new Date().getFullYear()

  const payroll = useMemo(() => {
    if (!payrollData || !payrollData.payrollReports) {
      return []
    }
    return payrollData.payrollReports
  }, [payrollData, currentYear])

  const attritionData = useMemo(() => {
    if (!EventsData || !EventsData.attritionRates) {
      return []
    }
    return EventsData.attritionRates
  }, [EventsData])

  const payrollTotalPayment = payroll?.map((pyt: any) => pyt?.total_payment)
  const payrollTotalPayroll = payroll?.map((pyt: any) => pyt?.total_payroll)
  const allLabels = payroll?.map((pyt: any) => pyt?.cut_off_date)
  const totalPayment = payrollTotalPayment?.reverse()
  const totalPayroll = payrollTotalPayroll?.reverse()
  const labels = allLabels?.reverse()
  const attritionLabels = attritionData?.map((att: any) => att.description)
  const attritionExited = attritionData?.map((att: any) => att?.exits)
  const attritionEmployees = attritionData?.map((att: any) => att?.employees)

  return (
    <DashboardWrapper>
      <div className="relative flex flex-col">
        <div className=" flex flex-col lg:flex-row gap-4 xl:justify-between  mb-6 lg:mb-8 flex-wrap">
          <CustomInfoCard
            rootClassName=" grow"
            id="dashboard-total-employees"
          >
            <StatCard
              className=" p-4 lg:p-5 "
              img={TotalEmployee}
              title="Total Employees"
              loading={isEventsDataLoading}
              value={
                EventsData?.femaleCount! +
                EventsData?.maleCount! +
                EventsData?.noGenderCount!
              }
              handleTitleClick={() => navigate("/dashboard/employees")}
            />
          </CustomInfoCard>

          <CustomInfoCard
            rootClassName=" grow"
            id="dashboard-total-salaries"
          >
            <StatCard
              className=" p-4 lg:p-5 "
              img={TotalSalary}
              title="Total Salary (Last Payroll) "
              loading={isEventsDataLoading}
              value={`${
                payrollData?.person?.currency_symbol || "₦"
              }${formatCurrencyNoSymbol(
                Number(payrollData?.lastPayroll?.total_payment || "0")
              )}`}
              handleTitleClick={() => navigate("/dashboard/payroll")}
            />
          </CustomInfoCard>

          <CustomInfoCard
            rootClassName=" grow"
            id="dashboard-total-leaves"
          >
            <StatCard
              className=" p-4 lg:p-5 "
              img={EmployeeLeave}
              title="Employees On Leave"
              loading={isActionDataLoading}
              value={`${actionData?.leaveCount ? actionData?.leaveCount : "0"}`}
            />
          </CustomInfoCard>
        </div>

        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-4 h-fit mb-6 lg:mb-8">
          <div className=" sm:col-span-3 xl:col-span-2 flex flex-col">
            <div className="flex gap-4 justify-between items-center">
              <h2 className="text-base font-semibold text-padeBlack font-circular mb-4">
                Recent Payroll History
              </h2>
            </div>

            <CustomCard
              rootClassName="grow"
              loading={isPayrollLoading}
              id="dashboard-payroll"
            >
              <Barchart
                currency="#"
                radius="8"
                thick={20}
                labels={labels}
                datasets={[
                  {
                    // label: "# of Votes",
                    data: totalPayment,
                    backgroundColor: ["#93B8FA"],
                    borderColor: ["#93B8FA"],
                    borderWidth: 1,
                    borderRadius: 0,
                    barThickness: 20,
                  },
                  {
                    // label: "# of Votes",
                    data: totalPayroll,
                    backgroundColor: ["#004AF5"],
                    borderColor: ["#004AF5"],
                    borderWidth: 1,
                    borderRadius: 0,
                    barThickness: 20,
                  },
                ]}
              />

              <div className="flex items-center justify-center w-full gap-4 mt-3">
                <div className="flex items-center gap-2 ">
                  <div className=" bg-[#93B8FA] h-3 w-3 rounded-full"></div>
                  <div className=" text-subText3">
                    <p>Disbursed Payment</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 ">
                  <div className=" bg-[#004AF5] h-3 w-3 rounded-full"></div>
                  <div className=" text-subText3">
                    <p>Total Payroll</p>
                  </div>
                </div>
              </div>
            </CustomCard>
          </div>

          <div className=" sm:col-span-3 xl:col-span-1">
            <Profile />
          </div>
        </div>

        <div
          id="dashboard-actions"
          className="mb-6 lg:mb-8"
        >
          <Actions
            actionData={actionData}
            isActionDataLoading={isActionDataLoading}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-6 lg:mb-8 ">
          <div className=" lg:col-span-2 ">
            <h5 className="text-base font-semibold text-padeBlack font-circular mb-4">
              Employee Attrition-Trend
            </h5>

            <div
              id="dashboard-attrition"
              className="h-[336px] w-full bg-white shadow-card rounded-5px px-5 py-4 flec flex-col justify-between"
            >
              <div className="flex items-center gap-4 justify-end mb-1">
                <div className="flex items-center gap-2">
                  <p className="text-subText3 text-xs">
                    No. of exited employees in the month
                  </p>
                  <div
                    className="bg-[#FF4253] h-5 w-5  rounded-5px
                    "
                  ></div>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-subText3 text-xs">
                    No. of employees in the Company{" "}
                  </p>
                  <div
                    className="bg-[#1041B6] h-5 w-5  rounded-5px
                    "
                  ></div>
                </div>
              </div>
              <div>
                <StackedBarchart
                  currency="#"
                  radius="8"
                  thick={20}
                  labels={attritionLabels}
                  datasets={[
                    {
                      // label: "# of Votes",
                      data: attritionEmployees,
                      backgroundColor: ["#1244B9"],
                      borderColor: ["#1244B9"],
                      borderWidth: 1,
                      borderRadius: 0,
                      barThickness: 20,
                    },
                    {
                      // label: "# of Votes",
                      data: attritionExited,
                      backgroundColor: ["#FF4253"],
                      borderColor: ["#FF4253"],
                      borderWidth: 1,
                      borderRadius: 0,
                      barThickness: 20,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div
            id="dashboard-headcount"
            className=" flex flex-col"
          >
            <h5 className="text-base font-semibold text-padeBlack font-circular mb-4">
              Headcount
            </h5>

            <CustomCard
              rootClassName="w-full grow"
              childrenWrapperClassName="py-4"
            >
              <div className="relative">
                <DoughnutChart
                  values={[
                    `${Number(EventsData?.femaleCount)}`,
                    `${Number(EventsData?.maleCount)}`,
                    `${Number(EventsData?.noGenderCount)}`,
                  ]}
                  labels={["Male", "Female", "Others"]}
                  color={["#2614F4", "#0BCE88", "#FF4253"]}
                  height={200}
                  width={200}
                  radius={2}
                />
                <div className="absolute top-0 bottom-0 left-0 right-0 z-10  flex flex-col items-center justify-center ">
                  <p className="text-sm  text-neutral100">Total Employees</p>
                  <h6 className="text-2xl font-black  text-neutral500">
                    {EventsData
                      ? Number(
                          EventsData?.femaleCount! +
                            EventsData?.maleCount! +
                            EventsData?.noGenderCount!
                        )
                      : "0"}
                  </h6>
                </div>
              </div>

              <div className="flex gap-4 mt-3 justify-center">
                <div className="flex items-start  gap-2 ">
                  <div className="w-3 h-3 rounded-full  bg-[#0BCE88] mt-1"></div>
                  <div className=" text-subText3 text-center">
                    <p className="text-sm">Male</p>
                    <p className="text-xs">({EventsData?.maleCount})</p>
                  </div>
                </div>
                <div className="flex items-start  gap-2 ">
                  <div className="w-3 h-3 rounded-full  bg-[#2614F4] mt-1"></div>
                  <div className=" text-subText3 text-center">
                    <p className="text-sm">Female</p>
                    <p className="text-xs">({EventsData?.femaleCount})</p>
                  </div>
                </div>
                <div className="flex items-start  gap-2 ">
                  <div className="w-3 h-3 rounded-full  bg-[#FF4253] mt-1"></div>
                  <div className=" text-subText3 text-center">
                    <p className="text-sm">Others</p>
                    <p className="text-xs">({EventsData?.noGenderCount})</p>
                  </div>
                </div>
              </div>
            </CustomCard>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default Dashboard

const StatCard = ({
  img,
  title = "",
  value,
  loading,
  className,
  handleTitleClick,
}: {
  img?: string
  title: string
  value: string | number
  loading?: boolean
  className?: string
  handleTitleClick?: () => void
}) => {
  return (
    <div className={`h-full  ${className}`}>
      <Spin
        spinning={loading}
        indicator={antIcon}
        className="w-full max-w-full"
      >
        <div className=" flex gap-3">
          <div className="h-11 w-11 flex justify-center items-center shrink-0">
            <img
              src={img}
              alt="User Profile"
            />
          </div>
          <div className="font-circular">
            <span
              className={`text-sm text-padeBlack cursor-pointer font-circular font-medium`}
              onClick={handleTitleClick}
            >
              {title}
            </span>
            <span
              className={`w-full !break-words flex flex-wrap text-2xl font-bold text-padeBlack font-circular`}
            >
              {loading ? "0" : value}
            </span>
          </div>
        </div>
      </Spin>
    </div>
  )
}
export const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 24 }}
    spin
  />
)
