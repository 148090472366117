// logos
import PadeLogoWhite from "./logos/paidhr-logo-white.svg"
import PadeLogo from "./logos/paidhr-logo.svg"
import PadeLogoNoName from "./logos/pade-logo-noname.svg"
import PadeLogoWhiteNoName from "./logos/paidhr-logo-white-noname.svg"

// images
import DefaultProfilePicture from "./images/default-profile-picture.svg"

// illustrations
import Help from "./illustrations/Help.png"
import Celebration from "./illustrations/celebration.svg"
import SuccessfullOnboarding from "./images/Successful-Mission.png"
import OnboardingEmailRegistration from "./illustrations/onboarding-email-registration.svg"
import OnboardingSignature from "./illustrations/onboarding-signature.svg"
import InviteTeamMembers from "./illustrations/invite-team-members.svg"
import DocumentPlaceholder from "./illustrations/document-placeholder.svg"
import EmptyDepartments from "./illustrations/empty-departments.svg"
import EmptyOffers from "./illustrations/empty-offers.svg"
import Referral from "./illustrations/referral.svg"
import { ReactComponent as ExclamationIcon } from "./images/exclamation-circle.svg"
import SignatureIcon from "./images/signature.svg"
import { ReactComponent as EyeIcon } from "./images/eye.svg"
import { ReactComponent as DocIcon } from "./images/doc.svg"
import { ReactComponent as FilterIcon } from "./images/filter.svg"
import { ReactComponent as DocIcon2 } from "./images/doc-icon.svg"
import { ReactComponent as BenefitsSubscription } from "./svg/benefits-subscription.svg"
import { ReactComponent as ArrowLeftIcon } from "./images/arrow-left.svg"
import { ReactComponent as ArrowRightIcon } from "./images/arrow-right.svg"
import BG from "./bg.png"

import Heart from "./illustrations/heart.svg"
import StartCalendar from "./illustrations/start-calendar.svg"
import CountEmployees from "./illustrations/count-employees.svg"
import CountPayroll from "./illustrations/count-payroll.svg"
import CreateGoals from "./illustrations/create-goals.svg"
import Absence from "./illustrations/absence.svg"
import TimeTrackingSettings from "./illustrations/time-tracking-settings.svg"
import TimeTracking from "./illustrations/time-tracking.svg"
import AdminShield from "./illustrations/admin-shield.svg"
import ForwardArrow from "./illustrations/forward-arrow.svg"
import TotalPayment from "./illustrations/total-payment.svg"
import PayRoll from "./illustrations/payroll.svg"
import UpArrow from "./illustrations/up-arrow.svg"
import DownArrow from "./illustrations/down-arrow.svg"
import Eligible from "./illustrations/eligible.svg"
import EligibleActive from "./illustrations/eligible-active.svg"
import Changes from "./illustrations/changes.svg"
import Records from "./illustrations/records.svg"
import Skip from "./illustrations/skip.svg"
import CalendarAlert from "./illustrations/calendar-alert.svg"
import CalendarBg from "../assets/images/circle.svg"
import Check from "./illustrations/check.svg"
import GreenCheck from "./illustrations/green-check.svg"
import Download from "./illustrations/download.svg"
import OpenArrow from "./illustrations/open-arrow.svg"
import Workspace from "./illustrations/workspace.png"
import FirstThings from "./illustrations/first-things.png"
import Paygrade from "./illustrations/paygrade.png"
import GlobalPartnership from "./illustrations/GlobalPartnership.png"
import { ReactComponent as ManageWorkspaceIcon } from "./svg/manage-workspace.svg"
import { ReactComponent as PhoneIcon } from "./svg/phone.svg"

// Documents
import company_name from "./documents/company_name.png"
import employee_compensation from "./documents/employee_compensation.png"
import employee_first_name from "./documents/employee_first_name.png"
import employee_last_name from "./documents/employee_last_name.png"
import employee_full_name from "./documents/employee_full_name.png"
import employee_start_date from "./documents/employee_start_date.png"
import employee_title from "./documents/employee_title.png"
import employee_email from "./documents/employee_email.png"
import employees_manager from "./documents/employees_manager.png"
import employer_signatory_name from "./documents/employer_signatory_name.png"
import employer_signatory_title from "./documents/employer_signatory_title.png"
import employer_signature from "./documents/employer_signature.png"
import full_time_part_time from "./documents/full_time_part_time.png"
import offer_accepted_date from "./documents/offer_accepted_date.png"
import offer_expiration_date from "./documents/offer_expiration_date.png"
import offer_sent_date from "./documents/offer_sent_date.png"
import full_work_address from "./documents/full_work_address.png"

export const logos = {
  PadeLogoWhite,
  PadeLogo,
  PadeLogoNoName,
  PadeLogoWhiteNoName,
}

export const images = {
  DefaultProfilePicture,
  SignatureIcon,
}

export const icons = {
  ExclamationIcon,
  DocIcon2,
  ArrowLeftIcon,
  ArrowRightIcon,
  DocIcon,
  FilterIcon,
  EyeIcon,
  ManageWorkspaceIcon,
  BenefitsSubscription,
  PhoneIcon,
}

export const illustrations: any = {
  SuccessfullOnboarding,
  Celebration,
  Help,
  OnboardingEmailRegistration,
  OnboardingSignature,
  InviteTeamMembers,
  DocumentPlaceholder,
  EmptyDepartments,
  EmptyOffers,
  Referral,
  Heart,
  StartCalendar,
  CountEmployees,
  CountPayroll,
  CreateGoals,
  TimeTracking,
  TimeTrackingSettings,
  Absence,
  AdminShield,
  ForwardArrow,
  TotalPayment,
  UpArrow,
  DownArrow,
  PayRoll,
  Eligible,
  Changes,
  Records,
  Skip,
  CalendarAlert,
  CalendarBg,
  Check,
  EligibleActive,
  GreenCheck,
  Download,
  OpenArrow,
  Workspace,
  FirstThings,
  Paygrade,
  BG,
  GlobalPartnership,
}

export const documentImages: DocumentImages = {
  company_name,
  employee_compensation,
  employee_first_name,
  employee_start_date,
  employee_title,
  employees_manager,
  employer_signatory_name,
  employer_signatory_title,
  employer_signature,
  full_time_part_time,
  offer_accepted_date,
  offer_expiration_date,
  offer_sent_date,
  full_work_address,
  employee_last_name,
  employee_full_name,
  employee_email,
}

interface DocumentImages {
  [key: string]: string
}
