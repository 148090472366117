import React, { Suspense, useEffect } from "react"
import { Alert, App, Layout, Spin } from "antd"
import { useState } from "react"
import { useAuthStore } from "stores"
import { Navigate, Outlet, useNavigate } from "react-router-dom"
import { SideNavigationBar } from "./SideNavigationBar"
import { TopNavigationBar } from "./TopNavigationBar"
import { NotificationBar } from "./NotificationBar"
import Intercom from "react-intercom"
import { isPadeDomain } from "utils/utils"
import { useGet } from "utils/useFetch"
import { IEarningsWalletData } from "pages/employee-dashboard/wallet/interface/wallet"
import { newBaseUrl } from "utils/newbaseurl"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const { Header, Content } = Layout

const NewResponsiveEmployeeLayout: React.FC = () => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const [isSidebarOpen, toggleSidebar] = useState(false)

  const { isAuthenticated, signOut } = useAuthStore((state) => state)

  const {
    isLoading: isEarningWalletLoading,
    data: earningWalletData,
    refetch,
  } = useGet<IEarningsWalletData>({
    url: `${newBaseUrl}/v1/earnings/wallet`,
    external: true,
    retry: 0,
    enabled: user?.isEwaEnabled === "True" ? true : false,
  })

  useEffect(() => {
    if (!isAuthenticated) {
      signOut(true)
    }
  }, [isAuthenticated])

  const intercomUser = {
    user_id: user?.userId,
    email: user?.userName,
    name: user?.userFullName,
  }

  if (user?.userStatusId === "2" || user?.userStatusId === "3") {
    return <Navigate to="/employee/susp-exit/payslips" />
  }

  return (
    <App
      notification={{
        placement: "topRight",
      }}
    >
      {isPadeDomain() === true ? (
        <div className="app">
          <Intercom
            appID="bi604cch"
            {...intercomUser}
          />
        </div>
      ) : null}
      <Layout>
        <SideNavigationBar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />

        {earningWalletData
          ? user?.isEwaEnabled === "True" &&
            !earningWalletData?.data?.employee?.enrollmentStatus && (
              <div className="sticky top-0 z-20 md:pl-[280px] font-avenir  ">
                <Alert
                  message="Set up your Earned Wage Access pin to gain access to up to 50% of your salary."
                  type="warning"
                  showIcon
                  icon={
                    <InformationCircleIcon className="h-4 w-4 lg:h-12 lg:w-12 !text-primary500" />
                  }
                  closable
                  action={
                    <div className="lg:pr-5  absolute lg:relative  bottom-2 lg:bottom-0  left-7 lg:left-0 ">
                      <button
                        onClick={() => {
                          mixPanelEvent(`empl-ewa-alert-clicked-init`)
                          navigate(
                            "/employee/dashboard/wallet/earned-wage-access/pin"
                          )
                        }}
                        className="bg-primary500 px-4 py-1 text-white rounded"
                      >
                        Set PIN
                      </button>
                    </div>
                  }
                  className="bg-[#CCDBFD] invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center border-[#CCDBFD] !rounded-none"
                />
              </div>
            )
          : null}
        <Layout>
          <Header className="bg-white p-0 sticky top-0 z-10 w-full border-b md:pl-[280px] h-[70px]">
            <TopNavigationBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          </Header>
          <Content className=" ">
            <div className="bg-[#F3F5FB] min-h-[100dvh] md:pl-[280px] font-avenir employee__layout">
              <Suspense fallback={<Loading />}>
                <Outlet />
              </Suspense>
              {/* <Dashboard /> */}
            </div>
          </Content>
        </Layout>
      </Layout>

      <NotificationBar />
    </App>
  )
}

export default NewResponsiveEmployeeLayout

function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0 left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}
