import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { Table, Tag, Tooltip } from "antd"
import { useAuthStore } from "stores"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { formatCurrencyNoSymbol } from "utils/currency"
// import { TransRes } from "../interface"
import { useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import { ICurrenciesData } from "pages/dashboard/wallets/crossBorderPayments/interface"
import { availableFlags } from "pages/dashboard/wallets/crossBorderPayments/sampleData"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import ViewTransferDetails from "../ViewTransferDetails"
import { useState } from "react"
import { title } from "process"

interface props {
  transactionsData: any[] | undefined
  loading: boolean
}
const TransfersTable = ({ transactionsData, loading }: props) => {
  const { user } = useAuthStore()
  const [activeReference, setActiveReference] = useState("")
  const [showDetails, setShowDetails] = useState(false)
  const { isLoading: isLoadingCurrencies, data: availableCurrencies } =
    useQuery<ICurrenciesData>({
      queryKey: [`${walletBaseUrl}/v1/misc/wallet-currencies`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/misc/wallet-currencies`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const TableData =
    transactionsData?.map((item: any) => {
      const currencyInfo = availableFlags.find(
        (flag) => flag.code === item.currency
      )
      const currencySymbol = availableCurrencies?.data?.find(
        (curr) => curr.currency === item.currency
      )

      return {
        key: item._id,
        date: {
          date: formatDateReadable(String(item?.createdAt)),
          time: formatTimeReadble(String(item?.createdAt)),
        },
        amount: {
          value: item.amount,
          charge: item.fee,
          symbol: currencySymbol?.symbol,
        },
        method: item.paymentMethod || "-",
        reference: item.reference,
        recipient:
          item.currency == "NGN"
            ? item?.accountName
            : `${item.beneficiaryFirstName} ${item?.beneficiaryLastName}` ||
              "-",
        bank: {
          bank_name: item.bankCode || "-",
          account_number: item.accountNumber,
        },
        currency: {
          flag: currencyInfo?.flag,
          currency_code: item.currency,
        },
        remark: item.description,
        status: {
          status: item.status,
          statusMessage: item?.statusMessage,
        },
        currencyInfo,
        action: item.reference,
      }
    }) || []

  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: { value: string; charge: string; symbol: string }) => (
          <div className={`font-avenir text-sm text-[#42526D] flex-nowrap`}>
            <p>
              {amount?.symbol}
              {formatCurrencyNoSymbol(Number(amount.value))}
            </p>
            {amount?.charge ? (
              <span className="text-xs flex-nowrap`">
                Charge: {amount?.symbol}
                {formatCurrencyNoSymbol(Number(amount.charge))}
              </span>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        filters:
          availableCurrencies?.data?.map((item) => ({
            text: item.currency,
            value: item.currency,
          })) || [],
        onFilter: (value: any, record: any) => {
          return record?.currency?.currency_code === value
        },
        render: (currency: { flag: any; currency_code: any }) => (
          <div className="flex items-center gap-2">
            <div>
              <img
                src={currency?.flag}
                alt=""
                className="h-6 w-6"
              />
            </div>
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {currency?.currency_code}
            </span>
          </div>
        ),
      },
      {
        title: "Recipient",
        dataIndex: "recipient",
        render: (recipient: string) => (
          <div className={`font-avenir text-sm text-[#42526D]`}>
            <p>{recipient}</p>
          </div>
        ),
      },
      {
        title: "Account",
        dataIndex: "bank",
        render: (bank: { bank_name: string; account_number: string }) => (
          <div className={`font-avenir text-sm text-[#42526D] flex-nowrap`}>
            <p>{bank.bank_name}</p>
            <span className="text-xs flex-nowrap`">{bank.account_number}</span>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D]  overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      {
        title: "Method",
        dataIndex: "method",
        render: (method: string) => (
          <div className={`font-avenir text-sm text-[#42526D] capitalize`}>
            <p>{method}</p>
          </div>
        ),
      },
      {
        title: "Description",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D]   overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: { status: string; statusMessage: string }) => (
          <div className="flex items-center ">
            <Tag
              color={
                status.status === "successful"
                  ? "success"
                  : status.status === "failed"
                  ? "error"
                  : status?.status === "processing"
                  ? "warning"
                  : "default"
              }
            >
              {status.status}
            </Tag>
            {status.status === "failed" && (
              <Tooltip
                title={status?.statusMessage}
                className=" cursor-pointer"
              >
                <InformationCircleIcon className="h-4 w-4" />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (action: string) => (
          <button
            onClick={() => {
              setActiveReference(action)
              setShowDetails(true)
            }}
            className="text-primary500"
          >
            View
          </button>
        ),
      },
    ] || []

  return (
    <>
      <Table
        dataSource={TableData}
        columns={TableColumns}
        loading={loading}
        className="text-[#42526D]"
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{
          x: "100%",
        }}
      />
      {activeReference && (
        <ViewTransferDetails
          reference={activeReference}
          onClose={() => {
            setActiveReference("")
            setShowDetails(false)
          }}
          open={showDetails}
        />
      )}
    </>
  )
}
export default TransfersTable
