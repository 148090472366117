import { Button, Label, RadioInput } from "components"
import { useState } from "react"
import {
  Select,
  Form,
  Space,
  Radio,
  InputNumber,
  Input,
  Tooltip,
  Checkbox,
} from "antd"
import { Link, useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import DeleteIcon from "assets/images/delete_icon.svg"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { useGet } from "utils/useFetch"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
// import EnterPin from "../pin/EnterPin"

interface Props {
  setPreviewData: any
  handleProceed: () => void
  found: any | undefined
  currencyCode: string
}

const MultipleSchedule = ({
  setPreviewData,
  handleProceed,
  found,
  currencyCode,
}: Props) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const dataConfirmed = Form.useWatch("scheduleConfirmation", form)
  const includeExitedValue = Form.useWatch("includeExited", form)
  const [nameError, setNameError] = useState("")
  const [useSameSettings, setUseSameSettings] = useState(true)
  const [payToEmployees, setPayToEmployees] = useState(true)
  const [formBodyMultiple, setFormBodyMultiple] = useState([
    {
      accountNumber: "",
      accountName: "",
      beneficiaryName: "",
      beneficiaryType: "",
      routingType: "",
      routingCode: "",
      sendToEmployees: true,
      beneficiaryId: "",
      amount: "",
      persons: [],
    },
  ])

  const { isLoading: isEmployeeLoading, data: employees } = useGet<any[]>({
    url: `/admin/people/getforselectionwithaccountdetails?active=${!includeExitedValue}&currency_code=${currencyCode}`,
  })
  const { isLoading: isContractorsLoading, data: contractors } = useGet<any[]>({
    url: `/admin/contractors/getforselectionwithaccountdetails?currency_code=${currencyCode}`,
  })

  const { isLoading: isCheckingScheduleName, mutate: checkScheduleName } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.get(
          `/admin/payment_schedules/check_custom_payment_schedule?description=${body.description}&payment_schedule_id=${body.id}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "error" && data.msg) {
          setNameError(data.msg)

          toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        setNameError(data.msg)
        toast.error(data.msg)
      },
    })

  // Handler to add a new form body
  const handleAddRecipient = () => {
    setFormBodyMultiple([
      ...formBodyMultiple,
      {
        accountNumber: "",
        accountName: "",
        beneficiaryName: "",
        beneficiaryType: "",
        routingType: "",
        routingCode: "",
        sendToEmployees: true,
        beneficiaryId: "",
        amount: "",
        persons: [],
      },
    ])
  }

  // Handler to delete a form body
  const handleDeleteRecipient = (index: number) => {
    const updatedFormBodies = formBodyMultiple.filter((_, i) => i !== index)
    setFormBodyMultiple(updatedFormBodies)
  }

  const onFinish = (values: any) => {
    const { description, amount } = values
    if (useSameSettings) {
      const body = formBodyMultiple[0].persons.map((item: any) => ({
        beneficiary: item?.beneficiaryName,
        account_name: item?.accountName,
        beneficiary_id: item?.beneficiaryId,
        beneficiary_type: item?.beneficiaryType,
        account_number: item?.accountNumber,
        routing_type: item?.routingType,
        routing_code: item?.routingCode,
        amount: amount ?? "0",
        role: payToEmployees ? "employee" : "contractor",
        description: description ?? "",
      }))

      setPreviewData(body)
    } else {
      const body = formBodyMultiple.map((item) => ({
        beneficiary: item?.beneficiaryName,
        account_name: item?.accountName,
        beneficiary_id: item?.beneficiaryId,
        beneficiary_type: item?.beneficiaryType,
        account_number: item?.accountNumber,
        routing_type: item?.routingType,
        routing_code: item?.routingCode,
        amount: item?.amount ?? "0",
        role: item?.sendToEmployees ? "employee" : "contractor",
        description: description ?? "",
      }))
      setPreviewData(body)
    }

    handleProceed()
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ scheduleConfirmation: false }}
      >
        <>
          <div className="pb-4">
            <div className=" mt-5 flex flex-col items-start w-full ">
              <div className="w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
                <Form.Item
                  name="description"
                  label={
                    <span className="text-padeBlack text-sm font-circular flex items-center">
                      Payment description
                      <Tooltip
                        title="Mamixum of 50 characters"
                        trigger={["hover"]}
                      >
                        <span>
                          <ExclamationCircleIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
                        </span>
                      </Tooltip>
                    </span>
                  }
                  extra={
                    nameError && (
                      <span className="text-danger500">{nameError}</span>
                    )
                  }
                  rules={[
                    { required: true, message: "Please enter this field!" },

                    {
                      validator: async (_, value) => {
                        const wordCount = value
                          ? value.trim().split(/\s+/).length
                          : 0
                        if (wordCount > 50) {
                          throw new Error(
                            "Description must be 50 words or less"
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter"
                    maxLength={50}
                    onChange={(e) => setNameError("")}
                    onBlur={(e) => {
                      if (e.target.value) {
                        // Perform additional onBlur actions if needed
                        checkScheduleName({
                          description: e.target.value,
                          id: "-1",
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] ">
                <div className="mt-2 flex items-center gap-10">
                  <AntDesignRadio
                    label="Do you want to use the same amount setting?"
                    name="sameAmount"
                    value={useSameSettings}
                  >
                    <Space direction="horizontal">
                      <Radio
                        value={true}
                        checked={useSameSettings ? true : false}
                        onChange={() => {
                          setUseSameSettings(true)
                        }}
                      >
                        Yes
                      </Radio>
                      <Radio
                        value={false}
                        checked={!useSameSettings ? true : false}
                        onChange={() => {
                          setUseSameSettings(false)
                        }}
                      >
                        No
                      </Radio>
                    </Space>
                  </AntDesignRadio>
                </div>
              </div>

              {useSameSettings ? (
                <div className="w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
                  <AntDesignRadio
                    label="Who do you want to pay?"
                    name={`paidTo`}
                    value={payToEmployees}
                  >
                    <Space direction="horizontal">
                      <Radio
                        value={true}
                        checked={payToEmployees ? true : false}
                        onChange={() => {
                          setPayToEmployees(true)
                        }}
                      >
                        Employees{" "}
                      </Radio>
                      <Radio
                        value={false}
                        checked={payToEmployees === false ? true : false}
                        onChange={() => {
                          setPayToEmployees(false)
                        }}
                      >
                        Contractors{" "}
                      </Radio>
                    </Space>
                  </AntDesignRadio>
                  {payToEmployees ? (
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm">
                          Select employees
                        </span>
                      }
                      name={`employees`}
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        loading={isEmployeeLoading}
                        options={
                          isEmployeeLoading
                            ? []
                            : employees?.map((person) => ({
                                label: person.full_name,
                                value: person.person_id,
                              }))
                        }
                        onChange={(selectedValues) => {
                          form.setFieldValue("amount", "")
                          const selectedEmployees = employees?.filter((emp) =>
                            selectedValues.includes(emp?.person_id)
                          )

                          setFormBodyMultiple((prev: any) => {
                            return prev.map((item: any) => {
                              const updatedEmployees = selectedEmployees?.map(
                                (employee) => ({
                                  accountNumber: employee?.account_number,
                                  beneficiaryName: employee?.full_name,
                                  accountName:
                                    currencyCode === "NGN"
                                      ? employee?.full_name
                                      : `${employee?.account_first_name} ${employee?.account_last_name}`,
                                  beneficiaryType: employee?.beneficiary_type,
                                  routingType: employee?.routing_type,
                                  routingCode: employee?.routing_code,
                                  beneficiaryId: employee?.person_id,
                                })
                              )

                              return {
                                ...item,
                                persons: updatedEmployees, // Store multiple employee data here
                              }
                            })
                          })
                        }}
                        className="w-full"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm">
                          Select contractors
                        </span>
                      }
                      name={`contractors`}
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        loading={isContractorsLoading}
                        options={
                          isContractorsLoading
                            ? []
                            : contractors?.map((cont) => ({
                                label: cont.name,
                                value: cont.contractor_id,
                              }))
                        }
                        onChange={(selectedValues) => {
                          form.setFieldValue("amount", "")
                          const selectedContractors = contractors?.filter(
                            (cont) =>
                              selectedValues.includes(cont?.contractor_id)
                          )

                          setFormBodyMultiple((prev: any) => {
                            return prev.map((item: any) => {
                              const updatedContractor =
                                selectedContractors?.map((contractor) => ({
                                  accountNumber: contractor?.account_number,
                                  beneficiaryName: contractor?.name,
                                  accountName:
                                    contractor?.account_company_name ??
                                    `${contractor?.account_first_name} ${contractor?.account_last_name}`,
                                  beneficiaryType: contractor?.beneficiary_type,
                                  routingType: contractor?.routing_type,
                                  routingCode: contractor?.routing_code,
                                  beneficiaryId: contractor?.contractor_id,
                                }))

                              return {
                                ...item,
                                persons: updatedContractor, // Store multiple employee data here
                              }
                            })
                          })
                        }}
                        className="w-full"
                      />
                    </Form.Item>
                  )}
                  <div className="w-full mb-6">
                    <Form.Item
                      label={
                        <span className="text-padeBlack text-sm flex items-center font-circular">
                          Enter amount{" "}
                        </span>
                      }
                      name={`amount`}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <InputNumber<number>
                        formatter={(value) =>
                          value !== undefined
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : ""
                        }
                        parser={(value) =>
                          value ? parseFloat(value.replace(/,/g, "")) : 0
                        }
                        className="w-full font-circular text-neutral400"
                        addonBefore={found?.symbol}
                        placeholder="e.g 200"
                        onChange={(value) => {
                          if (value !== undefined) {
                            setFormBodyMultiple((prev) =>
                              prev.map((item, i) => {
                                return {
                                  ...item,
                                  amount: value !== null ? String(value) : "0",
                                }
                              })
                            )
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : (
                formBodyMultiple.map((item, index) => (
                  <div
                    key={index}
                    className={` w-full ${
                      index !== formBodyMultiple?.length - 1
                        ? "mb-6 pb-6 border-b border-[#C2C7D0]"
                        : ""
                    } px-4 md:px-6 lg:px-8 xl:px-[40px]`}
                  >
                    <div className="flex items-center mb-4">
                      <p className="font-bold text-[15px] font-circular  text-padeBlack  ">
                        Recipient {index + 1}
                      </p>
                      {formBodyMultiple.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleDeleteRecipient(index)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            className="ml-6 "
                          />
                        </button>
                      )}
                    </div>

                    <AntDesignRadio
                      label="Who do you want to pay?"
                      name={`access~${index}`}
                      value={item?.sendToEmployees}
                    >
                      <Space direction="horizontal">
                        <Radio
                          value={true}
                          checked={item?.sendToEmployees ? true : false}
                          onChange={() => {
                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    sendToEmployees: true,
                                  }
                                }
                                return item
                              })
                            })
                          }}
                        >
                          Employees{" "}
                        </Radio>
                        <Radio
                          value={false}
                          checked={
                            item?.sendToEmployees === false ? true : false
                          }
                          onChange={() => {
                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    sendToEmployees: false,
                                  }
                                }
                                return item
                              })
                            })
                          }}
                        >
                          Contractors{" "}
                        </Radio>
                      </Space>
                    </AntDesignRadio>
                    {item?.sendToEmployees ? (
                      <div className="w-full">
                        <Form.Item
                          label={
                            <span className="font-circular text-padeBlack text-sm">
                              Select employees
                            </span>
                          }
                          name={`employee~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            loading={isEmployeeLoading}
                            options={
                              isEmployeeLoading
                                ? []
                                : employees?.map((person) => ({
                                    label: person.full_name,
                                    value: person.person_id,
                                  }))
                            }
                            onChange={(e) => {
                              const employee = employees?.find(
                                (emp) => emp?.person_id == e
                              )

                              setFormBodyMultiple((prev) => {
                                return prev.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      accountNumber: employee?.account_number,
                                      beneficiaryName: employee?.full_name,
                                      accountName:
                                        currencyCode === "NGN"
                                          ? employee?.full_name
                                          : `${employee?.account_first_name} ${employee?.account_last_name}`,
                                      beneficiaryType:
                                        employee?.beneficiary_type,
                                      routingType: employee?.routing_type,
                                      routingCode: employee?.routing_code,
                                      beneficiaryId: employee?.person_id,
                                    }
                                  }
                                  return item
                                })
                              })
                            }}
                          />
                        </Form.Item>
                      </div>
                    ) : (
                      <div className="w-full">
                        <Form.Item
                          label={
                            <span className="font-circular text-padeBlack text-sm">
                              Select contractor
                            </span>
                          }
                          name={`contractor~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            loading={isContractorsLoading}
                            options={
                              isContractorsLoading
                                ? []
                                : contractors?.map((cont) => ({
                                    label: cont.name,
                                    value: cont.contractor_id,
                                  }))
                            }
                            onChange={(e) => {
                              const contractor = contractors?.find(
                                (cont) => cont?.contractor_id == e
                              )

                              setFormBodyMultiple((prev) => {
                                return prev.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      accountNumber: contractor?.account_number,
                                      beneficiaryName: contractor?.name,
                                      accountName:
                                        contractor?.account_company_name ??
                                        `${contractor?.account_first_name} ${contractor?.account_last_name}`,
                                      beneficiaryType:
                                        contractor?.beneficiary_type,
                                      routingType: contractor?.routing_type,
                                      routingCode: contractor?.routing_code,
                                      beneficiaryId: contractor?.contractor_id,
                                    }
                                  }
                                  return item
                                })
                              })
                            }}
                          />
                        </Form.Item>
                      </div>
                    )}

                    <div className="w-full mb-6">
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm flex items-center font-circular">
                            Enter amount{" "}
                          </span>
                        }
                        name={`amount~${index}`}
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                      >
                        <InputNumber<number>
                          formatter={(value) =>
                            value !== undefined
                              ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""
                          }
                          parser={(value) =>
                            value ? parseFloat(value.replace(/,/g, "")) : 0
                          }
                          className="w-full font-circular text-neutral400"
                          addonBefore={found?.symbol}
                          placeholder="e.g 200"
                          onChange={(value) => {
                            if (value !== undefined) {
                              setFormBodyMultiple((prev) =>
                                prev.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      amount:
                                        value !== null ? String(value) : "0",
                                    }
                                  }
                                  return item
                                })
                              )
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))
              )}
            </div>

            {!useSameSettings && (
              <div className="mt-4 w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
                {formBodyMultiple.length < 5 && (
                  <button
                    type="button"
                    onClick={handleAddRecipient}
                    className="text-primary500  font-circular text-sm underline"
                  >
                    +Add Another Recipient
                  </button>
                )}
              </div>
            )}
          </div>
          <div className=" flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Button
              title="Previous"
              color="secondary"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={() => form.submit()}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
      {/* <EnterPin
        title="Enter Transaction Pin"
        onClose={() => setShowPinModal(false)}
        open={showPinModal}
        loading={isLoading}
        handleSubmit={handleSubmit}
      /> */}
    </>
  )
}

export default MultipleSchedule
