import { logos } from "assets"
import ImageComponent from "components/custom/image"
import React, { forwardRef } from "react"
import { useAuthStore } from "stores"
import Success from "../../../../assets/svg/success.svg"
import Failed from "../../../../assets/svg/failed.svg"
import copyright from "../../../../assets/svg/copyright.svg"
import { formatCurrencyNoSymbol } from "utils/currency"
import { isPadeDomain } from "utils/utils"
import { useCustomizationSettingsStore } from "stores/customization-settings"
type Props = {
  payment: any
}
const DownloadReceipt = forwardRef(({ payment }: Props, ref: any) => {
  const { user } = useAuthStore()
  const { customizationSettings } = useCustomizationSettingsStore()
  const transaction = [
    {
      title: "Beneficiary",
      value: payment?.full_name
        ? payment?.full_name
        : payment?.beneficiary_name,
    },
    { title: "Bank", value: payment?.bank_name },
    { title: "Account number", value: payment?.account_number },
    {
      title: "Amount",
      value: `₦${formatCurrencyNoSymbol(payment?.amount || 0)}`,
    },
    {
      title: "Transfer reference",
      value: payment?.reference_number,
      key: "reference",
    },
    { title: "Description", value: payment?.description },
  ]
  return (
    <div
      ref={ref}
      className="border p-[42px] bg-white font-avenir"
    >
      <div className="border-b border-[#42526D] pb-2 flex justify-between items-center ">
        {" "}
        <div className="w-[80px] h-[80px] object-contain ">
          {isPadeDomain() ? (
            <ImageComponent
              src={logos.PadeLogo || ""}
              alt="org"
              className={` w-full h-full object-contain `}
            />
          ) : null}
        </div>
        <h6 className="text-[#15294B] font-bold text-base">
          {payment?.description}
        </h6>
      </div>
      <div className="my-12 flex flex-col justify-center items-center">
        <img
          src={
            payment?.payment_schedule_item_status === "Succeeded"
              ? Success
              : Failed
          }
          // src={Failed}
          alt="success"
        />
        <h6 className="mt-2 text-[#0BCE88]  font-bold text-xl">
          Transfer{" "}
          {payment?.payment_schedule_item_status === "Succeeded"
            ? "Successful"
            : "Failed"}
        </h6>
      </div>
      <div>
        <h6 className="text-[#2859BF] text-2xl font-bold">
          Hello {user?.userOrganisation}
        </h6>
        <p className="mt-1 text-bluesubtext text-sm">
          Your transfer of NGN {formatCurrencyNoSymbol(payment?.amount || 0)} to{" "}
          {payment?.full_name ? payment?.full_name : payment?.beneficiary_name}{" "}
          {payment?.payment_schedule_item_status === "Succeeded"
            ? "was successful"
            : "failed"}
        </p>
      </div>
      <div className="border p-[30px] rounded-5px bg-[#E8F0FE] mt-5">
        {transaction.map((trans, index) => (
          <div className="border-b-2 border-dashed py-4 flex justify-between items-center ">
            <h6 className=" text-[15px] font-light text-[#42526D]">
              {trans.title}
            </h6>
            <h6
              className={`    text-[#15294B] text-[15px] font-bold flex items-center   `}
            >
              {trans.value}
            </h6>
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-center items-center mt-10 text-[#75829A]  text-sm">
        <p>
          <img
            src={copyright}
            // src={Failed}
            alt="success"
          />
          {customizationSettings?.brand.companyName} {new Date().getFullYear()}
        </p>
        {isPadeDomain() ? <p>The people platform</p> : null}
      </div>
    </div>
  )
})
export default DownloadReceipt
