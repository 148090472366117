import { Button, Label } from "components"
import React, { useState } from "react"
import { Select, Form, Switch, Input, Alert, InputNumber } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import DeleteIcon from "assets/images/delete_icon.svg"
import { KycLevel } from "pages/employee-dashboard/wallet/wallet/kyc/interface"
import { IBeneficiaries } from "pages/employee-dashboard/wallet/wallet/beneficiaries/interface"
import { groupFormDataByIndex } from "pages/employee-dashboard/wallet/wallet/sendMoney/component"
import { getAcronym } from "pages/employee-dashboard/wallet/wallet/Wallet"
import { sampleAmounts } from "./SendMoneyEmpCB"
import { AllBeneficiaryType, DataItem, PaymentMethods } from "./SingleSend"
import AntToggle from "components/inputs/AntToggle"
import { useGetCountries } from "components/new-stuff/library/common-utils"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
// import EnterPin from "../pin/EnterPin"

interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<any[]>>
  currentLevel: null | KycLevel
  amountOverBalance: boolean
  fee: string

  setFee: React.Dispatch<React.SetStateAction<string>>
  submitFee: (body: any) => void

  showPinModal: boolean
  setShowPinModal: React.Dispatch<React.SetStateAction<boolean>>
  handleProceed: () => void
  found: any | undefined
  currencyCode: string
  availableBalance: number | undefined
}

const MultipleSend = ({
  availableBalance,
  setPreviewData,
  currentLevel,
  amountOverBalance,
  fee,
  setFee,
  submitFee,

  showPinModal,
  setShowPinModal,
  handleProceed,
  found,
  currencyCode,
}: Props) => {
  const { countries, isLoading: isLoadingCountries } = useGetCountries()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const description = Form.useWatch("description", form)
  const [formBodyMultiple, setFormBodyMultiple] = useState<any[]>([
    {
      sendToBeneficiary: "",
      beneficiaryType: "",
    },
  ])

  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries?currency=${currencyCode}`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries?currency=${currencyCode}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })
  // Purpose TYPES
  const { isLoading: isLoadingBenTypes, data: purposeRes } = useQuery<any>({
    queryKey: [`transfer-purposes`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/transfer-purposes`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })

  // Handler to add a new form body
  const handleAddRecipient = () => {
    setFormBodyMultiple([
      ...formBodyMultiple,
      {
        sendToBeneficiary: "",
        beneficiaryType: "",
      },
    ])
  }

  // Handler to delete a form body
  const handleDeleteRecipient = (index: number) => {
    const updatedFormBodies = formBodyMultiple.filter((_, i) => i !== index)
    setFormBodyMultiple(updatedFormBodies)
  }

  const onFinish = (values: any) => {
    const formData = form.getFieldsValue()
    const groupedData = groupFormDataByIndex(formData)

    const body: any[] =
      groupedData?.length > 0
        ? groupedData.map((data) => ({
            accountNumber: data?.accountNumber,
            amount: data?.amount,
            currency: currencyCode,
            description,
            paymentMethodType: data?.paymentMethodType,
            bankCode: data?.bankCode,
            beneficiaryCountryISO: data?.beneficiaryCountryISO,
            beneficiaryType: data?.beneficiaryType,
            beneficiaryFirstName: data?.beneficiaryFirstName,
            beneficiaryLastName: data?.beneficiaryLastName,
            beneficiaryCompanyName: data?.beneficiaryCompanyName,
            purposeId: String(data?.purpose),
            addToBeneficiary: data?.addBeneficiary,
          }))
        : []

    setPreviewData(body)

    handleProceed()
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ scheduleConfirmation: false }}
      >
        <>
          <div className="pb-4">
            <div className=" mt-5 flex flex-col items-start w-full ">
              <div className="w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
                <CustomInput
                  label="Payment description"
                  name="description"
                />{" "}
              </div>
              {formBodyMultiple.map((item, index) => (
                <div
                  key={index}
                  className={` w-full ${
                    index !== formBodyMultiple?.length - 1
                      ? "mb-6 pb-6 border-b border-[#C2C7D0]"
                      : ""
                  } px-4 md:px-6 lg:px-8 xl:px-[40px]`}
                >
                  <div className="flex items-center mb-4">
                    <p className="font-bold text-[15px] font-circular  text-padeBlack  ">
                      Recipient {index + 1}
                    </p>
                    {formBodyMultiple.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleDeleteRecipient(index)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          className="ml-6 "
                        />
                      </button>
                    )}
                  </div>
                  {/* AMOUNT */}
                  <div className="mb-6">
                    <span className="text-padeBlack text-sm font-circular  ">
                      Select amount{" "}
                    </span>
                    <div className="flex  flex-wrap items-center gap-2">
                      {sampleAmounts.map((amount, index) => (
                        <button
                          type="button"
                          key={index}
                          className="h-[36px] w-[97px] border border-[#6c7a9326] rounded-5px bg-neutral30  focus:bg-padeLightBlue  focus:border-primary500"
                          onClick={() => {
                            form.setFieldValue(`amount~${index}`, "")
                            form.setFieldValue(`amount~${index}`, amount)
                          }}
                        >
                          {found?.symbol} {formatCurrencyNoSymbol(amount)}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="w-full mb-6">
                    <Form.Item
                      label={
                        <div className="flex w-screen justify-between ">
                          <span className="text-padeBlack text-sm font-circular  ">
                            {" "}
                            Enter other amount
                          </span>
                          {availableBalance && (
                            <span>
                              Balance:{" "}
                              <span className="font-medium">
                                {found?.symbol}
                                {formatCurrencyNoSymbol(availableBalance)}
                              </span>
                            </span>
                          )}
                        </div>
                      }
                      name={`amount~${index}`}
                      required
                      rules={[
                        // {
                        //   validator: (_, value) => {
                        //     const limit =
                        //       currentLevel && currentLevel.level > 2
                        //         ? Number.MAX_SAFE_INTEGER
                        //         : 200000
                        //     if (!value || value <= limit) {
                        //       return Promise.resolve()
                        //     }
                        //     return Promise.reject(
                        //       new Error(
                        //         `You can only send a maximum of ${formatCurrencyNoSymbol(
                        //           limit
                        //         )} to other banks, kindly upgrade your account `
                        //       )
                        //     )
                        //   },
                        // },
                        {
                          validator: (_, value) => {
                            const minimumAmount = 5
                            if (!value || value >= minimumAmount) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                `Amount must be at least ${found?.symbol} ${minimumAmount}`
                              )
                            )
                          },
                        },
                      ]}
                    >
                      {/* <Input
                        type="number"
                        className="font-circular text-neutral400"
                        addonBefore={found?.symbol}
                        placeholder=""
                      /> */}
                      <InputNumber<number>
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) =>
                          value?.replace(/,/g, "") as unknown as number
                        }
                        className="w-full font-circular text-neutral400"
                        addonBefore={found?.symbol}
                        placeholder="e.g 200"
                      />
                    </Form.Item>
                  </div>
                  {/* SAVED BENEFICIARIES */}

                  {/* {beneficiariesData && beneficiariesData?.data?.length > 0 && (
                    <div className=" flex  items-center gap-9 mb-6">
                      <span className="mb-3 text-padeBlack text-sm font-circular">
                        Select from saved beneficiaries to send
                        <div className="relative mt-1 h-6">
                          {beneficiariesData?.data
                            ?.slice(0, 5)
                            ?.map((ben, index) => (
                              <div
                                key={index}
                                className="h-8 w-8 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold absolute border-2 border-white"
                                style={{ left: `${index * 20}px` }}
                              >
                                {getAcronym(ben?.accountName)}
                              </div>
                            ))}
                        </div>
                      </span>
                      <Form.Item
                        name={`usebeneficiary~${index}`}
                        valuePropName="checked"
                      >
                        <Switch
                          onChange={(checked: boolean) => {
                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    sendToBeneficiary: checked,
                                  }
                                }
                                return item
                              })
                            })
                            form.setFieldValue(
                              `usebeneficiary~${index}`,
                              checked
                            )
                          }}
                          className="bg-[#ededed]"
                        />
                      </Form.Item>
                    </div>
                  )} */}

                  {item?.sendToBeneficiary ? (
                    <div className="w-full">
                      <div className="mt-1">
                        <Form.Item
                          label={
                            <span className="text-padeBlack text-sm font-circular  ">
                              Select beneficiary to send
                            </span>
                          }
                          name={`beneficiary~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            loading={isLoadingBeneficiaries}
                            onChange={(value) => {
                              const selected = beneficiariesData?.data?.find(
                                (item) => item.accountName === value
                              )

                              if (selected) {
                                form.setFieldValue(
                                  `bank~${index}`,
                                  String(selected?.bankCode)
                                )

                                form.setFieldValue(
                                  `accountNumber~${index}`,
                                  String(selected?.accountNumber)
                                )

                                // setFormBodyMultiple((prev) => {
                                //   return prev.map((item, i) => {
                                //     if (i === index) {
                                //       return {
                                //         ...item,
                                //         bankDetails: selected?.accountName,
                                //         bankCode: String(selected?.bankCode),
                                //         bankName: String(selected?.bankName),
                                //         accountNumber: String(
                                //           selected?.accountNumber
                                //         ),
                                //       }
                                //     }
                                //     return item
                                //   })
                                // })
                              }
                            }}
                            options={beneficiariesData?.data
                              ?.filter((item) => item.type === "bank")
                              ?.map((item) => ({
                                label: item?.accountName,
                                value: item?.accountName,
                              }))}
                            filterOption={(input, option) => {
                              if (option) {
                                const value = (option?.label as string) || ""
                                return (
                                  value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                )
                              } else {
                                return false
                              }
                            }}
                            placeholder="Select beneficiary"
                          />
                        </Form.Item>
                        <div className="opacity-0 h-1 overflow-hidden">
                          <Form.Item name={`bank~${index}`}></Form.Item>
                          <Form.Item
                            name={`accountNumber~${index}`}
                          ></Form.Item>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full grid grid-cols-2 gap-x-6">
                      {/* // COUNTRYISO */}
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm font-circular  ">
                            {" "}
                            Beneficiary country
                          </span>
                        }
                        name={`beneficiaryCountryISO~${index}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={isLoadingBeneficiaries}
                          // onChange={(value) => {
                          //   form.setFieldValue(
                          //     `beneficiaryCountryISO~${index}`,
                          //     String(value)
                          //   )
                          // }}
                          options={countries?.map((item) => ({
                            label: item?.description,
                            value: item?.alpha2Code,
                          }))}
                          filterOption={(input, option) => {
                            if (option) {
                              const value = (option?.label as string) || ""
                              return (
                                value
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              )
                            } else {
                              return false
                            }
                          }}
                          placeholder="Select country"
                        />
                      </Form.Item>
                      {/* BENEFICIARY TYPES */}
                      <div>
                        <Form.Item
                          label={
                            <span className="font-circular text-padeBlack text-sm  ">
                              Beneficiary type
                            </span>
                          }
                          name={`beneficiaryType~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            options={AllBeneficiaryType?.map((item) => ({
                              label: item?.label,
                              value: item?.value,
                            }))}
                            filterOption={(input, option) => {
                              if (option) {
                                const value = (option?.label as string) || ""
                                return (
                                  value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                )
                              } else {
                                return false
                              }
                            }}
                            onChange={(e) => {
                              setFormBodyMultiple((prev) => {
                                return prev.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      beneficiaryType: e,
                                    }
                                  }
                                  return item
                                })
                              })
                            }}
                            placeholder="Select"
                          />
                        </Form.Item>
                      </div>
                      {/*ACCOUNT NUMBER  */}
                      <CustomInput
                        label="Beneficiary account number"
                        name={`accountNumber~${index}`}
                        required
                        onChange={() => {}}
                      />

                      {/* PAYMENT METHOD */}

                      <div>
                        <Form.Item
                          label={
                            <span className="font-circular text-padeBlack text-sm  ">
                              Payment method type
                            </span>
                          }
                          name={`paymentMethodType~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            options={PaymentMethods?.map((item) => ({
                              label: item.label,
                              value: item.value,
                            }))}
                            filterOption={(input, option) => {
                              if (option) {
                                const value = (option?.label as string) || ""
                                return (
                                  value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                )
                              } else {
                                return false
                              }
                            }}
                            placeholder="Select"
                          />
                        </Form.Item>
                      </div>

                      {/* BENEFICIARY INDIVIDUAL INFO */}
                      {item?.beneficiaryType === "individual" && (
                        <>
                          <CustomInput
                            label="Beneficiary first name"
                            name={`beneficiaryFirstName~${index}`}
                            required
                          />
                          <CustomInput
                            label="Beneficiary last name"
                            name={`beneficiaryLastName~${index}`}
                            required
                          />
                        </>
                      )}
                      {/* BENEFICIARY COMPANY NAME */}
                      {item?.beneficiaryType === "company" && (
                        <CustomInput
                          label="Beneficiary company name"
                          name={`beneficiaryCompanyName~${index}`}
                          required
                        />
                      )}
                      {/*Purpose*/}
                      <div>
                        <Form.Item
                          label={
                            <span className="font-circular text-padeBlack text-sm  ">
                              Purpose
                            </span>
                          }
                          name={`purpose~${index}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter this field!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            options={purposeRes?.data?.map(
                              (item: DataItem) => ({
                                label: item.title,
                                value: item.id,
                              })
                            )}
                            filterOption={(input, option) => {
                              if (option) {
                                const value = (option?.label as string) || ""
                                return (
                                  value
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                )
                              } else {
                                return false
                              }
                            }}
                            placeholder="Select"
                          />
                        </Form.Item>
                      </div>
                      {/* BANK CODE */}
                      <CustomInput
                        label="Routing number"
                        name={`bankCode~${index}`}
                        required
                      />
                      <div className=" col-span-2">
                        <Alert
                          message={""}
                          type="warning"
                          showIcon
                          description={
                            "Please double-check your payment details before proceeding. We won't be liable for any losses due to incorrect information."
                          }
                          className="invoice-alert font-avenir text-neutral500 text-sm w-full items-center mb-6 rounded-none"
                        />
                      </div>
                      <div className=" flex  items-center gap-2 h-fit mb-5  col-span-2">
                        <div className=" flex  items-center gap-2">
                          <span className="text-neutral500 text-sm font-avenir">
                            Save beneficiary
                          </span>
                          <Form.Item
                            name={`addBeneficiary~${index}`}
                            valuePropName="checked"
                            style={{ marginBottom: 0 }}
                          >
                            <Switch
                              onChange={(checked: boolean) => {
                                setFormBodyMultiple((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        addToBeneficiary: checked,
                                      }
                                    }
                                    return item
                                  })
                                })
                                form.setFieldValue(
                                  `addBeneficiary~${index}`,
                                  checked
                                )
                              }}
                              className="bg-[#ededed]"
                            />
                          </Form.Item>
                          {/* s */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-4 w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
              {formBodyMultiple.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddRecipient}
                  className="text-primary500  font-circular text-sm underline"
                >
                  +Add Another Recipient
                </button>
              )}
            </div>
          </div>
          <div className=" flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={() => form.submit()}
            >
              Proceed
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default MultipleSend
// setArchiveRestoreData((prev) => ({
//   ...prev,
//   id: payroll_id,
//   type: "archive",
// }))
