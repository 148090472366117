import { formatCurrencyNoSymbol } from "utils/currency"
import { Tag, Tooltip } from "antd"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import addBtn from "assets/svg/add_button.svg"
const RequestCurCard = () => {
  const disabled = true
  return (
    <Tooltip title={disabled ? "This feature is coming soon " : ""}>
      <div
        className={` min-w-[250px] h-[156px]  rounded-5px border pt-5 pb-[18px] px-4 flex flex-col justify-between cursor-pointer ${
          disabled ? "bg-gray-100 cursor-not-allowed" : " bg-white"
        } `}
      >
        <div className="flex gap-1 items-center">
          <img
            src={addBtn}
            alt=""
            className=" h-[32px] w-[32px]"
          />
          <p
            className={` text-padeBlack text-sm font-circular ${
              disabled ? "cursor-not-allowed" : ""
            }`}
          >
            Request Currency
          </p>
        </div>

        <div className={`${disabled ? "cursor-not-allowed" : ""}`}>
          <h4 className="text-xl font-circular  font-bold leading-tight">
            20+ currencies
          </h4>
          <p className=" text-subText3 text-sm font-avenir opacity-0 ">
            Available Balance
          </p>
        </div>
      </div>
    </Tooltip>
  )
}

export default RequestCurCard
