import { Form, FormItemProps, Input } from "antd"
import { ReactNode } from "react"
const { TextArea } = Input
export const AntDesignInput = ({
  label,
  name,
  placeholder,
  required = false,
  onChange,
  onBlur,
  rules,
  maxLength,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      required={required}
      name={name}
      rules={
        rules ? rules : [{ required, message: "Please enter this field!" }]
      }
      {...restProps}
    >
      <Input
        onChange={onChange}
        placeholder={placeholder}
        className="text-neutral500  font-circular"
        onBlur={onBlur}
        maxLength={maxLength}
      />
    </Form.Item>
  )
}
export const AntDesignTextArea = ({
  label,
  name,
  placeholder,
  required = false,
  onChange,
  onBlur,
  rules,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      required={required}
      name={name}
      rules={
        rules ? rules : [{ required, message: "Please enter this field!" }]
      }
      {...restProps}
    >
      <TextArea
        rows={4}
        placeholder={placeholder}
        className="text-neutral500  font-circular"
      />
    </Form.Item>
  )
}

type InputItemProps = FormItemProps & {
  label: string | ReactNode
  placeholder?: string
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  rules?: any
  maxLength?: number
}
