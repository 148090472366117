import { useMutation } from "@tanstack/react-query"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import {
  IPaymentDetail,
  StatutoryPaymentFile,
} from "pages/dashboard/wallets/interface"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import { formatCurrencyNoSymbol } from "utils/currency"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
import { useGet } from "utils/useFetch"
import { IPaymentScheduleItem, IPayoutsResponse } from "../../interface/payouts"
import TopCards from "pages/dashboard/paymentSchedules/Component/TopCards"
import { Input, Table } from "antd"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { ColumnsType } from "antd/es/table"
import { Button, ModalWrapper } from "components"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import CustomCard from "components/new-stuff/ui/CustomCard"
import AllDocPreviewer from "components/new-stuff/ui/AllDocPreviewer"

const StatsPage = ({
  currency_code,
  StatName,
  StatNameSmall,
  ItemId,
  StatustoryId,
  showReportBtn = true,
  showPrepareBtn = true,
}: {
  currency_code: string
  StatName: string
  StatNameSmall: string
  ItemId: string
  StatustoryId?: number
  showReportBtn?: boolean
  showPrepareBtn?: boolean
}) => {
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const navigate = useNavigate()
  const { customizationSettings } = useCustomizationSettingsStore()
  const x = new URLSearchParams(myParam).get("x")
  const id = getIdFromKey(x || "")
  const [prepareScheduleModal, setPrepareScheduleModal] = useState(false)
  const [search, setSearch] = useState("")

  const {
    isLoading: isPaymentLoading,
    data,
    refetch: RefetchStatus,
  } = useGet<IPaymentDetail>({
    url: `admin/payment_schedules/view/index?id=detail&x=${x}&s=${currency_code}`,
  })
  const {
    isLoading: isStatustoryLoading,
    data: StatustoryData,
    refetch,
  } = useGet<IPayoutsResponse>({
    url: `admin/payment_schedules/selectpaymentscheduleitems_statutory?id=${getIdFromKey(
      x || ""
    )}&payment_schedule_item_type_id=${ItemId}`,
    onSuccess: (data: any) => {
      mixPanelEvent(`pay-schedule-${StatNameSmall}-listings`)
    },
  })
  const { isLoading: isPrepareScheduleLoading, mutate: submitPrepareSchedule } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/upload_statutory_schedule`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          data.msg && toast.success(data.msg)
          setPrepareScheduleModal(false)
          RefetchStatus()

          mixPanelEvent(`pay-schedule-${StatNameSmall}-prepare-success`)
        } else {
          data.msg && toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        data.msg && toast.error(data.msg)
      },
    })
  const { isLoading: isRefreshing, mutate: RefreshPayment } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/refresh_payment_schedule_statutory_items`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        {
          data.msg && toast.success(data.msg)
          refetch()
        }
      } else {
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const handleConfirm = () => {
    submitPrepareSchedule({
      payment_schedule_id: id,
      payment_schedule_item_type_id: ItemId,
    })
    mixPanelEvent(`pay-schedule-${StatNameSmall}-prepare-init`)
  }
  const UploadedFiles: StatutoryPaymentFile[] | any[] =
    data?.statutoryPaymentFiles.filter(
      (files) => files.statutory_payment_type_id === StatustoryId
    ) || []

  const TableData =
    StatustoryData?.data?.map((record, index) => ({
      key: index,
      ...record,
    })) || []

  const TableColumns: ColumnsType<IPaymentScheduleItem> =
    [
      {
        title: "Beneficiary",
        dataIndex: "beneficiary",
        render: (_: any, record: IPaymentScheduleItem) => (
          <span className={`font-circular text-sm text-padeBlack`}>
            {record?.beneficiary_name || `Unspecified ${StatName} Office`}
          </span>
        ),
      },

      {
        title: "Amount",
        dataIndex: "amount",
        render: (_: any, record: IPaymentScheduleItem) => (
          <span className={`font-circular text-sm text-padeBlack`}>
            {record?.currency_symbol}
            {formatCurrencyNoSymbol(Number(record?.amount))}
          </span>
        ),
      },
    ] || []
  return (
    <div className="mt-[47px]">
      <TopCards
        data={data}
        typeId={ItemId}
      />
      {/* TABLE */}
      <div className="bg-white">
        <div className=" flex   items-start sm:items-center justify-between flex-col-reverse gap-2 sm:flex-row py-[10px] px-4">
          <div className="flex gap-3 w-full sm:w-auto ">
            <Input
              placeholder="Search"
              className="bg-none w-full px-2 outline-none  text-sm h-9"
              prefix={
                <MagnifyingGlassIcon className="w-5 h-5 text-neutral-300" />
              }
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <div className="flex gap-[7px]">
            {showPrepareBtn && (
              <Button
                title="Process selected payments"
                outline={true}
                onClick={() => {
                  RefreshPayment({
                    payment_schedule_id: id,
                    payment_schedule_item_type_id: ItemId,
                  })
                }}
                submitting={isRefreshing}
              >
                Refresh
              </Button>
            )}
            {StatustoryData?.data?.length && showReportBtn ? (
              <Button
                title="Process selected payments"
                outline={true}
                onClick={() => {
                  mixPanelEvent(`pay-schedule-${StatNameSmall}-download-init`)
                  navigate(
                    `/dashboard/payment-schedules/view-${StatNameSmall}-payment/report?id=${data?.payroll_id}&x=${data?.payment_schedule_key}`
                  )
                }}
              >
                View Report
              </Button>
            ) : null}

            {StatustoryData &&
              StatustoryData?.data?.length > 0 &&
              showPrepareBtn && (
                <Button
                  title="Process selected payments"
                  color="primary"
                  onClick={() => {
                    setPrepareScheduleModal(true)
                  }}
                  disabled={isPrepareScheduleLoading}
                >
                  Prepare schedule
                </Button>
              )}
          </div>
        </div>
        <div className="border ">
          <Table
            columns={TableColumns}
            dataSource={TableData}
            loading={isStatustoryLoading}
            className=" text-padeBlack"
            pagination={{
              defaultPageSize: 20,
            }}
            scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
          />
        </div>
      </div>
      {/* UPLOAD PROOF */}
      {UploadedFiles && UploadedFiles?.length > 0 && (
        <CustomCard
          title={`Files uploaded by ${customizationSettings?.brand.platformName}`}
          rootClassName="my-2"
        >
          <div>
            <p className="text-sm text-neutral500 mb-4">
              This is where you will find {StatNameSmall} receipts and control
              documents uploaded by {customizationSettings?.brand.platformName}{" "}
              for this payroll.
            </p>
            <div className="flex flex-col items-start justify-center">
              {UploadedFiles?.map((file) => (
                <AllDocPreviewer
                  url={file?.url}
                  documentName={file?.file_name}
                />
              ))}
            </div>
          </div>
        </CustomCard>
      )}
      {/* PROCESS MODAL */}
      {prepareScheduleModal && (
        <ModalWrapper
          title={`Upload ${StatName} Schedule`}
          onClose={() => {
            setPrepareScheduleModal(false)
          }}
        >
          <div className="border p-4   flex flex-col">
            <p className="text-neutral300 text-sm  mt-2">
              After uploading the {StatNameSmall}
              schedule, a new entry will appear in the ‘Payment Schedule’ tab.
              Payments will be processed within 3-5 working days after
              confirmation. Click ‘Confirm’ to proceed
            </p>
            <div className="mt-4 gap-4 flex justify-end items-center">
              <Button
                color="secondary"
                title="Cancel"
                onClick={() => setPrepareScheduleModal(false)}
              >
                Cancel
              </Button>

              <Button
                submitting={isPrepareScheduleLoading}
                color="primary"
                title="Confirm"
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  )
}

export default StatsPage
export const paymentScheduleStatus = [
  {
    id: "1",
    title: "Succeeded",
  },
  {
    id: "2",
    title: "Queued",
  },
  {
    id: "3",
    title: "Reversed",
  },
  {
    id: "4",
    title: "Failed",
  },
  {
    id: "5",
    title: "New",
  },
]
