import { useState } from "react"
import { formatCurrencyNoSymbol } from "utils/currency"
import Balance from "../../../assets/images/balance_icon.svg"
import TotalCredit from "../../../assets/images/total_credit_icon.svg"
import TotalDebit from "../../../assets/images/total_debit.svg"

import { Button, DashboardContainer } from "components"
import { useAuthStore } from "stores"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { StatsCard } from "../documents/company-details/Cards"
import { DatePicker, Table } from "antd"

import { INewTransactionData } from "./interface/statement"
import dayjs from "dayjs"
import { CSVLink } from "react-csv"
import { IWalletPade } from "./interface/wallet"

const Statements = () => {
  const { user } = useAuthStore()
  const walletId = user?.organisationWalletId

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )

  const [isCustom, setIsCustom] = useState(false)
  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "1",
    period: "One Week",
    startDate: "",
    endDate: "",
  })

  const {
    isLoading: isStatementLoading,
    data: statementData,
    refetch: refetchStatement,
  } = useQuery<INewTransactionData>({
    queryKey: [
      `${walletBaseUrl}/v1/wallets/${walletId}/transactions?limit=0&startDate=${
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
      }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
    ],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}/transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data: any) => {
      mixPanelEvent("wallet-statement-listing")
    },
  })
  const {
    isLoading: isStatementStatsLoading,
    data: statementStatsData,
    refetch: refetchStatsStatement,
  } = useQuery<any>({
    queryKey: [`v1/wallets/stats`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}/stats`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data: any) => {
      mixPanelEvent("wallet-statement-listing")
    },

    // keepPreviousData: true,
    // refetchOnWindowFocus: false,
    // cacheTime: 24 * 60 * 60 * 1000,
  })
  const { isLoading: isLoadingOverview, data: newWalletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const ngnBalance = newWalletData?.data?.balances?.find(
    (balance) => balance.currency === "NGN"
  )
  const StatementTableData =
    statementData?.data?.docs?.map((item: any) => ({
      key: item._id,
      date: {
        date: formatDateReadable(String(item?.createdAt)),
        time: formatTimeReadble(String(item?.createdAt)),
      },
      amount: {
        value: item.amount,
        code: item.type,
      },
      before: `₦ ${formatCurrencyNoSymbol(Number(item?.previousBalance))}`,
      after: `₦ ${formatCurrencyNoSymbol(Number(item?.newBalance))}`,
      reference: item.reference,
      remark: item.description,
    })) || []

  const StatementTableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: { value: string; code: string }) => (
          <div
            className={`font-avenir text-sm ${
              amount.code === "debit" ? "text-danger500" : "text-green"
            }`}
          >
            <p>₦{formatCurrencyNoSymbol(Number(amount.value))}</p>
          </div>
        ),
      },

      {
        title: "Previous Balance",
        dataIndex: "before",
        render: (before: string) => (
          <div className={`font-avenir text-sm text-[#42526D]`}>
            <p>{before}</p>
          </div>
        ),
      },
      {
        title: "New Balance",
        dataIndex: "after",
        render: (after: string) => (
          <div className={`font-avenir text-sm text-[#42526D]`}>
            <p>{after}</p>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[150px] overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      {
        title: "Remark",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[170px] overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
    ] || []
  const cardData = [
    {
      id: "balance",
      icon: Balance,
      title: "Balance",
      value: ` ₦ ${
        ngnBalance?.balance
          ? formatCurrencyNoSymbol(Number(ngnBalance?.balance) || 0)
          : 0
      }`,
    },
    {
      id: "Total credit",
      icon: TotalCredit,
      title: "Total Credit",
      value: ` ₦ ${
        statementStatsData?.data?.credit
          ? formatCurrencyNoSymbol(
              Number(statementStatsData?.data?.credit) || 0
            )
          : 0
      }`,
    },
    {
      id: "Total debit",
      icon: TotalDebit,
      title: "Total Debit",
      value: ` ₦ ${
        statementStatsData?.data?.debit
          ? formatCurrencyNoSymbol(Number(statementStatsData?.data?.debit) || 0)
          : 0
      }`,
    },
  ]
  const periods: dateData[] = [
    {
      id: "1",
      period: "One Week",
      startDate: "",
      endDate: "",
    },
    {
      id: "2",
      period: "One Month",
      startDate: "",
      endDate: "",
    },

    {
      id: "3",
      period: "Custom",
      startDate: "",
      endDate: "",
    },
  ]
  const headers = [
    // { label: "Date", key: "createdAt" },
    { label: "Date", key: "createdAt" },
    { label: "Amount", key: "amount" },
    { label: "Type", key: "type" },
    { label: "Previous Balance", key: "previousBalance" },
    { label: "New Balance", key: "newBalance" },
    { label: "Reference", key: "reference" },
    { label: "Remark", key: "description" },
  ]

  const csvProps = {
    filename: "statement.csv",
    headers: headers,
    data: statementData?.data?.docs || [],
  }

  return (
    <DashboardContainer>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between w-full items-center">
          <h5 className="text-lg font-bold text-neutral700 ">Statement</h5>
          <div>
            <CSVLink {...csvProps}>
              <Button
                color="primary"
                title="Download"
              >
                Download
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 ">
          {cardData.map((card, idx) => (
            <StatsCard
              id={card.id}
              key={idx}
              text={card.value}
              image={card.icon}
              title={card.title}
            />
          ))}
        </div>
        <div className=" bg-white my-4 px-4 pt-5">
          <div className="flex gap-2 justify-end items-center mb-4">
            <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
              {periods?.map((it: dateData) => (
                <div
                  onClick={() => {
                    if (it?.id === "1") {
                      setStartDate(
                        dayjs(new Date())
                          .subtract(7, "day")
                          .format("YYYY/MM/DD") || ""
                      )
                      setEndDate(dayjs(new Date())?.format("YYYY/MM/DD") || "")
                    } else if (it?.id === "2") {
                      setStartDate(
                        dayjs(new Date())
                          .subtract(30, "day")
                          .format("YYYY/MM/DD") || ""
                      )
                      setEndDate(dayjs(new Date())?.format("YYYY/MM/DD") || "")
                    } else {
                      setIsCustom(true)
                      setActivePeriod({
                        id: "3",
                        period: "Custom",
                        startDate: "",
                        endDate: "",
                      })
                      return
                    }
                    setActivePeriod(it)
                    setIsCustom(false)
                  }}
                  key={it?.id}
                  className={`${
                    it.id === activePeriod?.id && "bg-primary500 text-white"
                  } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                >
                  {it?.period}
                </div>
              ))}
            </div>
            {isCustom && (
              <div className="flex gap-2">
                <DatePicker
                  placeholder="Start date"
                  onChange={(value) => {
                    setStartDate(value?.format("YYYY/MM/DD") || "")
                    setEndDate("")
                  }}
                  disabledDate={(date) => {
                    return date.isAfter(dayjs())
                  }}
                />
                <DatePicker
                  placeholder="End date"
                  disabled={!startDate}
                  onChange={(value) => {
                    setEndDate(value?.format("YYYY/MM/DD") || "")
                  }}
                  disabledDate={(date) => {
                    const testDate = dayjs(startDate)
                    const before = date.isBefore(testDate)
                    const after = date.isAfter(testDate.add(1, "M"))
                    const afterToday = date.isAfter(dayjs())

                    return before || after || afterToday
                  }}
                />
              </div>
            )}
          </div>
          <Table
            columns={StatementTableColumns}
            dataSource={StatementTableData}
            loading={isStatementLoading}
            className="text-[#42526D]"
            pagination={{
              defaultPageSize: 20,
            }}
            scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
          />
        </div>
      </div>
    </DashboardContainer>
  )
}

export default Statements
export interface dateData {
  id: string
  period: string
  startDate: string
  endDate: string
}
