import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useState } from "react"
import CustomCard from "components/new-stuff/ui/CustomCard"

import { useAuthStore } from "stores"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import { Spin } from "antd"
import { IEmployeeWalletRes } from "./transactions/interface"
import { availableFlags } from "pages/dashboard/wallets/crossBorderPayments/sampleData"
import CurrencyCard from "pages/dashboard/wallets/crossBorderPayments/component/CurrencyCard"
import CrossBorderTransTable from "pages/dashboard/wallets/crossBorderPayments/transactions/component/Table"
import RequestAccount from "pages/dashboard/wallets/crossBorderPayments/modals/RequestAccount"

import RequestCurCard from "pages/dashboard/wallets/crossBorderPayments/component/CustomCard"
import Rates from "pages/dashboard/wallets/crossBorderPayments/component/Rates"
import { rearrangeBalances } from "pages/dashboard/wallets/crossBorderPayments/Overview"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import TransfersTable from "./transfers/component/TransfersTable"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const CrossBorderEmployee = () => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const EmployeeWalletID = user?.userWalletId

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )

  const [openRequestModal, setOpenRequestModal] = useState(false)
  const { isLoading: iswalletLoading, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {
        if (data.status === "success") {
          mixPanelEvent(`empl-wallet-listing`)
        }
      },
    })

  const { isLoading: isTransactionsLoading, data: transactionsData } =
    useQuery<IEmployeeWalletRes>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions?limit=0&startDate=${
            startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
          }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {
        mixPanelEvent(`empl-wallet-transaction-listing`)
      },
    })
  const { isLoading: isTransfersLoading, data: transfersData } = useQuery<any>({
    queryKey: [`transfers`, startDate, endDate],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/payment-transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    onSuccess: (data: any) => {
      mixPanelEvent(`empl-wallet-transfer-listing`)
    },
  })

  return (
    <DashboardWrapper>
      <div className=" flex flex-col w-full ">
        {/* HEADER */}
        <div className="flex items-center justify-between">
          <h2 className=" text-padeBlack font-bold font-circular text-xl">
            Wallet Balances{" "}
          </h2>
          {/* <Button
            color="primary"
            onClick={() => setOpenRequestModal(true)}
          >
            Request Currency
          </Button> */}
        </div>
        {/* BALANCES */}
        <Spin spinning={iswalletLoading}>
          <div className="mt-4 flex  justify-start  items-center max-w-full min-h-[156px] overflow-x-auto gap-4">
            {walletData?.data?.balances &&
              rearrangeBalances(walletData?.data?.balances)?.map(
                (curr: any, index: any) => {
                  const flag = availableFlags.find(
                    (flag) => flag.code === curr.currency
                  )?.flag

                  return (
                    <CurrencyCard
                      key={index}
                      curr={curr}
                      flag={flag}
                      handleClick={() => {
                        // curr?.type === "default" &&
                        curr?.currency === "NGN"
                          ? navigate(
                              "/employee/dashboard/wallet/cross-border/local"
                            )
                          : navigate(
                              `/employee/dashboard/wallet/cross-border/view`,
                              {
                                state: { currencyCode: curr.currency },
                              }
                            )
                      }}
                    />
                  )
                }
              )}
            <RequestCurCard />
          </div>
        </Spin>
        {/* RATES */}
        <div className="mt-[31px] h-[159px]">
          <Rates />
        </div>
        {/* TRANSACTION */}
        <div className="mt-[25px]">
          <CustomCard
            title="Recent Transactions"
            ctaBtn={
              <button
                className="text-primary500 flex text-sm items-center font-avenir"
                onClick={() =>
                  navigate(
                    "/employee/dashboard/wallet/cross-border/transactions"
                  )
                }
              >
                See all
              </button>
            }
          >
            <CrossBorderTransTable
              transactionsData={transactionsData?.data?.docs?.slice(0, 4)}
              loading={isTransactionsLoading}
            />
          </CustomCard>
        </div>
        {/* TRANSFERS */}
        <div className="mt-[25px]">
          <CustomCard
            title="Transfers"
            ctaBtn={
              <button
                className="text-primary500 flex text-sm items-center font-avenir"
                onClick={() =>
                  navigate("/employee/dashboard/wallet/cross-border/transfers")
                }
              >
                See all
              </button>
            }
          >
            <TransfersTable
              transactionsData={transfersData?.data?.docs?.slice(0, 4)}
              loading={isTransfersLoading}
            />
          </CustomCard>
        </div>
        <RequestAccount
          open={openRequestModal}
          onClose={() => setOpenRequestModal(false)}
        />
      </div>
    </DashboardWrapper>
  )
}

export default CrossBorderEmployee
