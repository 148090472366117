import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import { useAppNotificationStore, useAuthStore } from "stores"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import WalletBg from "assets/images/wallet/bg-wallet-black.svg"
import FundImg from "assets/illustrations/fund_img.svg"
import styled from "styled-components"
import { formatCurrencyNoSymbol } from "utils/currency"
import { Button, LinkButton } from "components"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { IBeneficiaries } from "pages/employee-dashboard/wallet/wallet/beneficiaries/interface"
import { useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import CrossBorderTransTable from "../transactions/component/Table"
import dayjs from "dayjs"
import { IEmployeeWalletRes } from "../transactions/interface"
import QuickSend from "./component/QuickSend"
import FundMoney from "../modals/FundMoney"
import { IWalletPade } from "../../interface/wallet"
import { availableFlags } from "../sampleData"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

export const WalletWrapper = styled.div`
  background-image: url(${WalletBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 5px;
`
const ViewCurrency = () => {
  const [_, copy] = useCopyToClipboard()
  const { toast } = useAppNotificationStore()
  const [hideDetails, setHideDetails] = useState(true)
  const [funded, setFunded] = useState(false)
  const { user } = useAuthStore()
  const walletId = user?.organisationWalletId

  const location = useLocation()
  const { currencyCode } = location.state || {}

  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )

  const [openFundModal, setOpenFundModal] = useState(false)
  const {
    isLoading: iswalletLoading,
    data: walletData,
    refetch: refetchWalletData,
  } = useQuery<IWalletPade>({
    queryKey: [`${walletBaseUrl}/v1/wallets`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    onSuccess: (data: any) => {
      mixPanelEvent(`${currencyCode}-balance-open`)
    },
  })

  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${walletId}/transfer-beneficiaries?currency=${currencyCode}`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}/transfer-beneficiaries?currency=${currencyCode}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      enabled: currencyCode ? true : false,
      onSuccess: (data: any) => {},
    })
  const {
    isLoading: isTransactionsLoading,
    data: transactionsData,
    refetch: refetchTransactions,
  } = useQuery<IEmployeeWalletRes>({
    queryKey: [
      `${walletBaseUrl}/v1/wallets/${walletId}/transactions?limit=0&startDate=${
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
      }&endDate=${
        endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
      }&currency=${currencyCode}`,
    ],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}/transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${
          endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
        }&currency=${currencyCode}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: currencyCode ? true : false,
    onSuccess: (data: any) => {},
  })

  const foundBalance = walletData?.data?.balances?.find(
    (item) => item?.currency === currencyCode
  )

  const found =
    foundBalance && currencyCode === "NGN"
      ? walletData?.data?.balances?.find(
          (item) => item.type === "currency-swap"
        )
      : foundBalance

  const currencyInfo = availableFlags.find((flag) => flag.code === currencyCode)

  const link = walletData?.data?.paymentLinks?.find(
    (payment) => payment?.currency === currencyCode
  )?.link

  return (
    <DashboardWrapper>
      <div className="relative font-avenir">
        <div className="gap-[11px] flex items-center ">
          <img
            src={currencyInfo?.flag}
            alt=""
            className=" h-[31px] w-[31px]"
          />
          <h2 className=" text-padeBlack font-bold font-avenir  text-lg ">
            {currencyCode} Account
          </h2>
        </div>
        {currencyInfo?.subContent && (
          <p className="text-neutral100 text-sm mb-2">
            {currencyInfo?.subContent}
          </p>
        )}
        {/* VIRTUAL ACCOUNT AND BALANCE */}
        <div className="flex flex-col xl:grid grid-cols-5  gap-4 lg:gap-7 mt-2 ">
          <div className={`h-full w-full col-span-3`}>
            <div className="w-full h-full  ">
              <WalletWrapper
                className={`px-4 lg:px-12 py-8 w-full flex items-center bg-padeBlack relative  ${
                  walletData && walletData?.data?.virtualAccounts?.length > 1
                    ? "!h-[250px] "
                    : "!h-[235px] "
                }`}
                id="wallet-balance"
              >
                {/* {found?.tag && (
                  <div className="absolute top-7 right-10 font-medium">
                    <Tag
                      color="blue"
                      className={`text-[10px] font-bold bg-[#E4ECFF] border-none ${
                        found?.tag === "Local"
                          ? "  text-primary500 "
                          : "text-[#DE14A4]"
                      }`}
                    >
                      {found?.tag}
                    </Tag>
                  </div>
                )} */}
                <div className="w-full flex flex-col gap-4">
                  <div className="flex justify-between items-center">
                    {" "}
                    <div className=" flex flex-col gap-[2px]">
                      <p className="text-[#98A2B3] text-base font-bold font-circular">
                        Your Balance is
                      </p>
                      {hideDetails ? (
                        <h3 className="text-a11y font-bold font-circular text-4xl lg:text-[48px]">
                          ****
                        </h3>
                      ) : (
                        <>
                          <p className="text-a11y font-bold font-circular text-4xl lg:text-[48px]">
                            {currencyInfo?.symbol}
                            {found?.balance &&
                              formatCurrencyNoSymbol(
                                Number(found?.balance) || 0
                              )}
                          </p>
                        </>
                      )}
                    </div>
                    <button
                      className=" flex"
                      onClick={() => setHideDetails(!hideDetails)}
                    >
                      {hideDetails ? (
                        <EyeIcon className="text-a11y w-6 h-6 cursor-pointer" />
                      ) : (
                        <EyeSlashIcon className="text-a11y w-6 h-6 cursor-pointer" />
                      )}
                    </button>
                  </div>
                  <div className="mt-3 flex items-center gap-5">
                    {/* <Button
                      outline={true}
                      onClick={() =>
                        navigate("/dashboard/wallets/cross-border/swap-money")
                      }
                      className="text-white"
                    >
                      Swap
                    </Button> */}

                    <Button
                      color="primary"
                      className="text-white"
                      onClick={() =>
                        navigate(`/dashboard/wallets/cross-border/send-money`, {
                          state: { currencyCode },
                        })
                      }
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </WalletWrapper>
            </div>
          </div>

          <div className="border col-span-2 border-neutral20 flex  flex-col bg-white shadow-md p-5  rounded-5px relative ">
            <div className="flex items-center gap-2">
              <h6 className=" font-bold text-base">Fund Your Account</h6>
              <span> </span>
            </div>
            <p className="text-sm  text-neutral700 mb-1">
              Click on the button below to fund your {currencyCode} Account
            </p>
            <div className="flex-grow h-full flex items-center">
              <button
                className="w-[191px] h-8 border border-primary500 rounded  font-bold text-primary500"
                onClick={() => setOpenFundModal(true)}
              >
                Fund now
              </button>
            </div>
            <div className="absolute  right-0 bottom-0">
              <div className="pt-4">
                <img
                  src={FundImg}
                  alt=""
                  className="h-[170px] w-[150px]"
                />
              </div>
            </div>
          </div>
        </div>
        {/* {QUICK SEND} */}
        <QuickSend
          beneficiariesData={beneficiariesData}
          handleClick={() => {
            navigate("/dashboard/wallets/cross-border/view/beneficiaries")
          }}
        />
        {/* TRANSACTION */}
        <div className="mt-6">
          <CustomCard
            title="Recent Transactions"
            ctaBtn={
              <button
                className="text-primary500 flex text-sm items-center font-avenir"
                onClick={() =>
                  navigate(
                    "/dashboard/wallets/cross-border/view/transactions",
                    {
                      state: { currencyCode: found?.currency },
                    }
                  )
                }
              >
                See All
              </button>
            }
          >
            <CrossBorderTransTable
              transactionsData={transactionsData?.data?.docs?.slice(0, 4)}
              loading={isTransactionsLoading}
            />
          </CustomCard>
        </div>

        <FundMoney
          onClose={() => setOpenFundModal(false)}
          open={openFundModal}
          paymentLink={
            walletData?.data?.paymentLinks?.find(
              (payment) => payment?.currency === currencyCode
            )?.link
          }
        />
      </div>
    </DashboardWrapper>
  )
}

export default ViewCurrency
