import React, { useState } from "react"
import BasicDetails from "./component/BasicDetails"
import { Form, Spin } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import Bvn from "./component/Bvn"
import ProofOfResidence from "./component/ProofOfResidence"
import { useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import { useAuthStore } from "stores"
import { IUserWalletID, KycLevel } from "./interface"
import { getLatestCompletedKycLevel } from "../Wallet"
import dayjs from "dayjs"

const KYC = () => {
  const [form] = Form.useForm()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  // const EmployeeWalletID = "66781fb2ad3d6d8abead10a5"
  const [currentLevel, setCurrentLevel] = useState<null | KycLevel>(null)
  const [currentPage, setCurrentPage] = useState(1)

  const [formBody, setFormBody] = useState({
    firstName: "",
    lastName: "",
    company_email: "",
    secondary_email: "",
    bvn_firstName: "",
    bvn_lastName: "",
    bvn_PhoneNumber: "",
    bvn: "",
    dateOfBirth: "",
  })
  let isLoadingEdit = false
  const { isLoading: isLoadingWalletData, data: walletData } =
    useQuery<IUserWalletID>({
      queryKey: [`kyc`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: IUserWalletID) => {
        const latestCompletedLevel = getLatestCompletedKycLevel(
          data?.data?.kycLevels
        )

        if (latestCompletedLevel) {
          setCurrentLevel(latestCompletedLevel)
          const nextLevel = latestCompletedLevel.level + 1
          setCurrentPage(nextLevel)
        } else {
        }

        setFormBody({
          ...formBody,
          firstName: data?.data?.firstName,
          lastName: data?.data?.lastName,
          bvn: data?.data?.bvn,
          bvn_firstName: data?.data?.bvnFirstName,
          bvn_lastName: data?.data?.bvnLastName,
          bvn_PhoneNumber: data?.data?.bvnPhoneNumber,
          dateOfBirth: data?.data?.dateOfBirth
            ? String(dayjs(data?.data?.dateOfBirth, "YYYY-MM-DD"))
            : "",
          company_email: data?.data?.email,
          secondary_email: data?.data?.alternateEmail || "",
        })

        form.setFieldValue("first_name", data?.data?.firstName)
        form.setFieldValue("last_name", data?.data?.lastName)
        form.setFieldValue("Company_email", data?.data?.email)
        form.setFieldValue("secondary_email", data?.data?.alternateEmail || "")
        form.setFieldValue("bvn", data?.data?.bvn || "")
        form.setFieldValue("bvn_first_name", data?.data?.bvnFirstName || "")
        form.setFieldValue("bvn_last_name", data?.data?.bvnLastName || "")
        {
          data?.data?.dateOfBirth &&
            form.setFieldValue(
              "birthday",
              dayjs(data?.data?.dateOfBirth, "YYYY-MM-DD")
            )
        }

        data?.data?.bvnPhoneNumber &&
          form.setFieldValue("mobileNumber", data?.data?.bvnPhoneNumber)
      },
    })

  const data = [
    {
      id: "Basic Info",
      title: (
        <span className="font-circular text-neutral300">Basic Information</span>
      ),
      content: (
        <BasicDetails
          setCurrentPage={setCurrentPage}
          formBody={formBody}
          setFormBody={setFormBody}
          isActive={currentPage === 1 ? true : false}
          form={form}
          Form={Form}
          isLoadingEdit={isLoadingEdit}
          status={walletData?.data?.kycLevels[0]?.status || "not setup"}
        />
      ),
    },
    {
      id: "Contact details",
      title: (
        <span className="font-circular text-neutral300">Contact details</span>
      ),
      content: (
        <Bvn
          setCurrentPage={setCurrentPage}
          formBody={formBody}
          setFormBody={setFormBody}
          isActive={currentPage === 2 ? true : false}
          form={form}
          Form={Form}
          status={walletData?.data?.kycLevels[1]?.status || "not setup"}
          walletData={walletData}
          currentLevel={currentLevel}
        />
      ),
    },
    {
      id: "Bank Details",
      title: (
        <span className="font-circular text-neutral300">Bank Details</span>
      ),
      content: (
        <ProofOfResidence
          setCurrentPage={setCurrentPage}
          formBody={formBody}
          setFormBody={setFormBody}
          isActive={currentPage === 3 ? true : false}
          form={form}
          Form={Form}
          remarks={walletData?.data?.kycLevels[2]?.remarks}
          status={
            walletData?.data?.kycLevels[2]?.awaitingConfirmation === true
              ? "Awaiting confirmation"
              : walletData?.data?.kycLevels[2]?.status || "not setup"
          }
        />
      ),
    },
  ]
  return (
    <DashboardWrapper>
      <Spin spinning={isLoadingWalletData}>
        <div>
          {data.map((item, index) => (
            <div
              className="mt-6"
              key={index}
            >
              {item.content}
            </div>
          ))}
        </div>
      </Spin>
    </DashboardWrapper>
  )
}

export default KYC
