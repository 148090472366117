import { formatCurrencyNoSymbol } from "utils/currency"
import { Tag, Tooltip } from "antd"

import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { IWalletBalance } from "../../interface/wallet"
interface Props {
  curr: IWalletBalance
  flag: string | undefined
  tag?: string | undefined
  handleClick: () => void
}
const CurrencyCard = ({ curr, flag, handleClick, tag }: Props) => {
  return (
    <div
      className=" bg-white min-w-[250px] h-[156px]  rounded-5px border pt-5 pb-[18px] px-4 flex flex-col justify-between cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex gap-1 items-center">
        <img
          src={flag}
          alt=""
          className=" h-[32px] w-[32px]"
        />
        <p className=" text-padeBlack text-sm font-circular">
          {curr?.currency}
        </p>
        {/* {curr?.currency === "NGN" && (
          <Tooltip title="">
            <InformationCircleIcon className="h-4 w-4 text-[#75829A]" />
          </Tooltip>
        )} */}
      </div>

      <div className="">
        <h4 className="text-xl font-circular  font-bold leading-tight">
          <span className="text-sm text-[#0C1B3D] mr-0.5 font-circular">
            {curr?.symbol}
            {/* {curr?.currency} */}
          </span>
          {formatCurrencyNoSymbol(curr?.balance)}
        </h4>
        <p className=" text-subText3 text-sm font-avenir ">Available Balance</p>
      </div>
    </div>
  )
}

export default CurrencyCard
