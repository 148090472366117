import React, { useEffect, useState } from "react"
import { logos } from "assets"
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom"
import { Tag, Space } from "antd"
import { isPadeDomain } from "utils/utils"
import {
  AdjustmentsVerticalIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  ChevronUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  DocumentTextIcon,
  GiftIcon,
  MagnifyingGlassCircleIcon,
  MegaphoneIcon,
  PresentationChartLineIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  UsersIcon,
  PresentationChartBarIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline"

import {
  Squares2X2Icon,
  PlusIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid"
import { useAppNotificationStore, useAuthStore } from "stores"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { IUser } from "stores/auth"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import SidebarBg from "assets/new-auth/new-Auth.png"

type ActionType = {
  title: string
  icon: JSX.Element
  route?: string
  patterns?: { path: string }[]
  enabled: boolean
  badge: boolean
  newTab?: boolean
  id?: string
  subActions?: {
    id?: string
    title: string
    route: string
    patterns: { path: string }[]
    enabled: boolean
  }[]
}

const NavTab: React.FC<{
  id?: string
  action: ActionType
  index?: number
  toggleSidebar?: VoidFunction
}> = ({ id, action, index, toggleSidebar }) => {
  const { pathname } = useLocation()
  // const [allPatterns, setAllPatterns] = useState<{ path: string }[]>([])
  const [subActionsOpen, toggleSubActionsOpen] = useState<boolean>(false)

  useEffect(() => {
    const allPatterns = action.subActions
      ?.map((action) => action.patterns)
      .reduce((a, e) => [...a, ...e], [])
    // setAllPatterns(allPatterns!)
    toggleSubActionsOpen(
      action.subActions?.length! > 0 &&
        matchRoutes(allPatterns!, pathname) !== null
    )
  }, [action.subActions, pathname])

  return (
    <div>
      {action.subActions?.length! > 0 ? (
        <>
          <button
            className={`w-full flex justify-between items-center gap-3 px-3 py-2 hover:bg-primary600 hover:text-a11y hover:rounded-[10px]  ${
              subActionsOpen === true
                ? `bg-primary600 text-a11y rounded-[10px] font-avenir tracking-wider`
                : "text-a11y/80"
            }`}
            onClick={() => toggleSubActionsOpen(!subActionsOpen)}
          >
            <div className="flex items-center justify-center gap-3 font-avenir">
              {action.icon}
              <span className="mt-0.5">{action.title}</span>
              {action.badge ? (
                <Space
                  size={[0, 2]}
                  wrap
                >
                  <Tag color="gold">beta</Tag>
                </Space>
              ) : (
                ""
              )}
            </div>
            <ChevronUpIcon
              className={`ease-in-out transition-all w-4 rotate-180 ${
                subActionsOpen === true && "rotate-0"
              }`}
            />
          </button>

          {subActionsOpen === true && (
            <div className="border-l border-a11y flex flex-col gap-0.5 ml-[30px] my-1 pl-2">
              {action?.subActions?.map((action, index) =>
                !action.enabled ? (
                  ""
                ) : (
                  <Link
                    id={action.id}
                    key={index}
                    to={action.route}
                    className={`w-full py-2 px-3 rounded-md flex justify-between items-center gap-5
                    hover:bg-primary600 hover:text-a11y cursor-pointer font-avenir ${
                      matchRoutes(action.patterns!, pathname) !== null
                        ? `bg-primary600 text-a11y font-avenir tracking-wider`
                        : "text-a11y/80"
                    }`}
                    onClick={() => {
                      toggleSidebar && toggleSidebar()
                    }}
                  >
                    {action.title}
                  </Link>
                )
              )}
            </div>
          )}
        </>
      ) : (
        <Link
          id={id}
          to={action.route!}
          key={index!}
          aria-label={action.title}
          target={action.newTab === true ? "_blank" : ""}
          className={`w-full flex justify-start items-center gap-3 px-3 py-2
           hover:bg-primary600 hover:text-a11y hover:rounded-[10px] ${
             typeof action.patterns !== "undefined" &&
             matchRoutes(action.patterns!, pathname) !== null
               ? `bg-primary600 text-a11y rounded-[10px]  font-avenir tracking-wider`
               : "text-a11y/80 tracking-wide"
           }`}
          onClick={() => {
            toggleSidebar && toggleSidebar()
          }}
        >
          {action.icon}
          <span className="mt-0.5 font-avenir">{action.title}</span>
        </Link>
      )}
    </div>
  )
}

export const SideNavigationBar: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const [isBusinessListOpen, toggleBusinessListOpen] = useState<boolean>(false)
  const { customizationSettings } = useCustomizationSettingsStore()

  const { changeOrganization, user } = useAuthStore()
  const navigate = useNavigate()
  const peopleActions: ActionType[] = [
    {
      title: "People",
      icon: <UsersIcon className="w-5" />,
      enabled: true,
      badge: false,
      subActions: [
        {
          title: "Employees",
          route: "employees",
          patterns: [
            { path: "/dashboard/employees/*" },
            { path: "/dashboard/employee/*" },
          ],
          enabled: true,
        },
        {
          title: "Needs Attention",
          route: "needs-attention",
          patterns: [{ path: "/dashboard/needs-attention/*" }],
          enabled: true,
        },
        {
          title: "Departments",
          route: "departments",
          patterns: [{ path: "/dashboard/departments/*" }],
          enabled: true,
        },
        {
          title: "Managers",
          route: "manager",
          patterns: [{ path: "/dashboard/manager/*" }],
          enabled: true,
        },
        {
          title: "Offers",
          route: "offers",
          patterns: [{ path: "/dashboard/offers/*" }],
          enabled: true,
        },
        {
          title: "Exits",
          route: "exits",
          patterns: [{ path: "/dashboard/exits/*" }],
          enabled: true,
        },
        {
          title: "External Admins",
          route: "external-admins",
          patterns: [{ path: "/dashboard/external-admins/*" }],
          enabled: true,
        },
        {
          title: "Confirmation",
          route: "confirmation",
          patterns: [{ path: "/dashboard/confirmation/*" }],
          // enabled: false,
          enabled: true,
        },

        {
          title: "Org. Chart",
          route: "org-chart",
          patterns: [{ path: "/dashboard/org-chart/*" }],
          enabled: true,
        },
        {
          title: "Job Titles",
          route: "job-titles",
          patterns: [{ path: "/dashboard/job-titles/*" }],
          enabled: true,
        },
      ],
    },
    {
      title: "Hiring",
      icon: <BriefcaseIcon className="w-5" />,
      enabled: user?.isAtsEnabled === "True" ? true : false,
      badge: false,
      patterns: [
        {
          path: "/dashbord/hiring/dashboard",
        },
        {
          path: "/dashbord/hiring/job",
        },
      ],
      // route: `/dashbord/hiring`,
      subActions: [
        {
          title: "Overview",
          route: "hiring/dashboard/",
          patterns: [{ path: "/dashboard/hiring/dashboard/*" }],
          enabled: true,
        },
        {
          title: "Jobs",
          route: `hiring/jobs`,
          patterns: [
            { path: "/dashboard/hiring/jobs/*" },
            { path: "/dashboard/hiring/jobs/*" },
            { path: "/dashboard/hiring/job/*" },

            { path: "/dashboard/hiring/post-job/*" },
          ],
          enabled: true,
        },
        {
          title: "Applicants",
          route: `hiring/applicants`,
          patterns: [{ path: "/dashboard/hiring/applicants/*" }],
          enabled: true,
        },
        {
          title: "Settings",
          route: `hiring/settings`,
          patterns: [{ path: "/dashboard/hiring/settings/*" }],
          enabled: true,
        },
      ],
    },
    {
      title: "Requests",
      icon: <CurrencyDollarIcon className="w-5" />,
      // route: "/dashboard/requests/list",
      patterns: [{ path: "/dashboard/requests/*" }],
      enabled: true,
      badge: false,
      subActions: [
        {
          title: "Loans",
          route: "requests/list/loans",
          id: "req-loan-nav",
          patterns: [
            { path: "/dashboard/requests/list/loans/*" },
            { path: "/dashboard/requests/loan/create/*" },
            { path: "/dashboard/requests/policies/create/loans/*" },
            { path: "/dashboard/requests/policies/edit/loans/*" },
            { path: "/dashboard/requests/loan/policy/*" },
          ],
          enabled: true,
          // enabled: user?.isHrisEnabled === "True" ? true : false,
        },
        {
          title: "Salary Advances",
          route: "requests/list/salary-advances",
          patterns: [
            { path: "/dashboard/requests/list/salary-advances/*" },
            { path: "/dashboard/requests/salary-advance/create/*" },
            { path: "/dashboard/requests/policies/create/salary-advance/*" },
            { path: "/dashboard/requests/policies/edit/salary-advance/*" },
            { path: "/dashboard/requests/salary-advance/policy/*" },
          ],
          enabled: true,
          // enabled: user?.isHrisEnabled === "True" ? true : false,
        },
        {
          title: "Leaves",
          route: "requests/list/leaves",
          patterns: [
            { path: "/dashboard/requests/list/leaves/*" },
            { path: "/dashboard/requests/leave/create/*" },
            { path: "/dashboard/requests/policies/create/leaves/*" },
            { path: "/dashboard/requests/policies/edit/leaves/*" },
            { path: "/dashboard/requests/leave/policy/*" },
            { path: "/dashboard/requests/policies/paid-holidays" },
            { path: "/dashboard/requests/policies/federal-holidays" },
          ],
          enabled: true,
          // enabled: user?.isHrisEnabled === "True" ? true : false,
        },
        {
          title: "Exits",
          route: "requests/list/exits",
          patterns: [
            { path: "/dashboard/requests/list/exits/*" },
            { path: "/dashboard/requests/exit/create/*" },
            { path: "/dashboard/requests/policies/create/exits/*" },
            { path: "/dashboard/requests/policies/edit/exits/*" },
            { path: "/dashboard/requests/exit/bulk-upload" },
            { path: "/dashboard/requests/exit/policy/*" },
          ],
          enabled: true,
        },
        {
          title: "Redeployments",
          route: "requests/list/redeployments",
          patterns: [
            { path: "/dashboard/requests/list/redeployments/*" },
            { path: "/dashboard/requests/redeployment/create/*" },
            { path: "/dashboard/requests/policies/create/redeployments/*" },
            { path: "/dashboard/requests/policies/edit/redeployment/*" },
            { path: "/dashboard/requests/redeployment/bulk-upload" },
            { path: "/dashboard/requests/redeployment/policy/*" },
          ],
          enabled: user?.isHrisEnabled === "True" ? true : false,
        },
        {
          title: "Documents",
          route: "requests/list/documents",
          patterns: [
            { path: "/dashboard/requests/list/documents/*" },
            { path: "/dashboard/requests/document/create/*" },
            { path: "/dashboard/requests/policies/create/documents/*" },
            { path: "/dashboard/requests/policies/edit/documents/*" },
            { path: "/dashboard/requests/document/policy/*" },
          ],
          enabled: user?.isHrisEnabled === "True" ? true : false,
        },
      ],
    },
    {
      title: "Surveys",
      icon: <DocumentTextIcon className="w-5" />,
      route: "survey",
      patterns: [{ path: "/dashboard/survey/*" }],
      enabled: false,
      badge: false,

      // enabled: false,
    },

    {
      title: "Documents",
      icon: <DocumentIcon className="w-5" />,
      route: "documents",
      patterns: [{ path: "/dashboard/documents/*" }],
      enabled: user?.isHrisEnabled === "True" ? true : false,
      badge: false,
      id: "document-nav",
    },
    {
      title: "Contractors",
      icon: <UsersIcon className="w-5" />,
      route: "contractor",
      patterns: [{ path: "/dashboard/contractor/*" }],
      enabled: true,
      badge: false,
    },

    // {
    //   title: "Claims",
    //   icon: <CurrencyDollarIcon className="w-5" />,
    //   route: "claims",
    //   patterns: [{ path: "/dashboard/claims/*" }],
    // },
  ]
  const compensationActions: ActionType[] = [
    {
      title: "Payrolls",
      icon: <CurrencyDollarIcon className="w-5" />,
      // route: "payroll",
      // patterns: [{ path: "/dashboard/payroll/*" }],
      enabled: true,
      badge: false,
      subActions: [
        {
          id: "payroll-overview-nav",
          title: "Overview",
          route: "payroll",
          patterns: [{ path: "/dashboard/payroll" }],
          enabled: true,
        },
        {
          id: "payroll-changes-nav",
          title: "Changes",
          route: "payroll/payrollchange",
          patterns: [
            { path: "/dashboard/payroll/payrollchange/*" },
            {
              path: "/dashboard/payroll/change/bulkupload",
            },
          ],
          enabled: true,
        },
        {
          title: "Records",
          id: "payroll-records-nav",
          route: "payroll/payrollrecord",
          patterns: [{ path: "/dashboard/payroll/payrollrecord/*" }],
          enabled: true,
        },
        {
          title: "Skip Employees",
          id: "skip-employees-nav",
          route: "payroll/payrollskip",
          patterns: [{ path: "/dashboard/payroll/payrollskip/*" }],
          enabled: true,
        },
        {
          title: "Configuration",
          id: "payroll-config-nav",
          route: "payroll/configuration/pay-schedules",
          patterns: [{ path: "/dashboard/payroll/configuration/*" }],
          enabled: true,
        },
      ],
    },
    {
      title: "Pay Schedules",
      icon: <ClipboardDocumentListIcon className="w-5" />,
      enabled: true,
      badge: false,
      subActions: [
        {
          title: "Regular",
          id: "regular-payment-nav",
          route: "payment-schedules/regular",
          patterns: [
            { path: "/dashboard/payment-schedules/regular/*" },
            {
              path: "dashboard/payment-schedules/view-regular-payment",
            },
          ],
          enabled: true,
        },

        {
          title: "Custom",
          id: "custom-payment-nav",
          route: "payment-schedules/custom",
          patterns: [
            { path: "/dashboard/payment-schedules/custom/*" },
            {
              path: "/dashboard/payment-schedules/view-payment",
            },
            {
              path: "/dashboard/payment-schedules/preview-payment",
            },
          ],
          enabled: true,
        },
        {
          title: "Reversals",
          // id: "regular-payment-nav",
          route: "payment-schedules/reversals",
          patterns: [{ path: "/dashboard/payment-schedules/reversals/*" }],
          enabled: true,
        },
      ],
    },
    {
      title: "Wallets",
      icon: <BanknotesIcon className="w-5" />,
      enabled: true,
      badge: false,
      subActions: [
        // {
        //   title: "Overview",
        //   route: "wallets/cross-border/overview",
        //   id: "wallet-nav",
        //   patterns: [
        //     {
        //       path: "/dashboard/wallets/cross-border/overview/*",
        //     },
        //     {
        //       path: "/dashboard/wallets/overview/*",
        //     },

        //     {
        //       path: "/dashboard/wallets/cross-border/transactions/*",
        //     },
        //     {
        //       path: "/dashboard/wallets/cross-border/view/*",
        //     },
        //     {
        //       path: "/dashboard/wallets/cross-border/swap-money/*",
        //     },
        //     {
        //       path: "/dashboard/wallets/cross-border/send-money/*",
        //     },
        //     {
        //       path: "/dashboard/wallets/cross-border/move-funds/*",
        //     },
        //   ],
        //   enabled: true,
        // },

        {
          title: "Overview",
          route: "wallets/overview",
          patterns: [{ path: "/dashboard/wallets/overview/*" }],
          enabled: true,
          id: "transaction-nav",
        },
        {
          title: "Transactions",
          route: "wallets/transactions",
          patterns: [{ path: "/dashboard/wallets/transactions/*" }],
          enabled: true,
          id: "transaction-nav",
        },
        {
          title: "Statement",
          route: "wallets/statements",
          patterns: [{ path: "/dashboard/wallets/statements/*" }],
          enabled: true,
        },
        {
          title: "Integration",
          route: "wallets/integration",
          patterns: [{ path: "/dashboard/wallets/Integration/*" }],
          enabled: true,
          // id: "",
        },
      ],
    },
    {
      title: "Earned Wage Access",
      icon: <PresentationChartBarIcon className="w-5" />,
      enabled: true,
      badge: false,
      subActions: [
        {
          title: "Analytics",
          route: "ewa/analytics",
          patterns: [{ path: "/dashboard/ewa/analytics/*" }],
          enabled: true,
          id: "ewa-analytics-nav",
        },
        {
          title: "Employees",
          route: "ewa/employees",
          patterns: [{ path: "/dashboard/ewa/employees/*" }],
          enabled: true,
          id: "ewa-employees-nav",
        },
        {
          title: " Withdrawals ",
          route: "ewa/withdrawals",
          patterns: [{ path: "/dashboard/ewa/withdrawals/*" }],
          enabled: true,
          id: "ewa-withdrawals-nav",
        },

        {
          title: "Payment",
          route: "ewa/invoice",
          patterns: [{ path: "/dashboard/ewa/invoice/*" }],
          enabled: true,
          id: "ewa-invoice-nav",
        },
      ],
    },

    {
      title: "Benefits",
      icon: <GiftIcon className="w-5" />,
      route: "benefits",
      badge: false,
      patterns: [{ path: "/dashboard/benefits/*" }],
      enabled: true,
      id: "benefits-nav",
    },
  ]
  const taskActions: ActionType[] = [
    {
      title: "Performance",
      icon: <PresentationChartLineIcon className="w-5" />,
      enabled: user?.isPerformanceEnabled === "True",
      badge: false,
      subActions: [
        {
          title: "Period",
          id: "pf-period-nav",
          route: "performance/performance-period",
          patterns: [{ path: "/dashboard/performance/performance-period/*" }],
          enabled: true,
        },
        {
          title: "Objectives",
          id: "pf-objectives-nav",
          route: "performance/performance-objectives",
          patterns: [
            { path: "/dashboard/performance/performance-objectives/*" },
          ],
          enabled: true,
        },
        {
          title: "Groups",
          id: "pf-group-nav",
          route: "performance/groups",
          patterns: [{ path: "/dashboard/performance/groups/*" }],
          enabled: true,
        },
        {
          title: "Reviews",
          id: "pf-reviews-nav",
          route: "performance/reviews",
          patterns: [{ path: "/dashboard/performance/reviews/*" }],
          enabled: true,
        },
        {
          title: "Ratings",
          id: "pf-ratings-nav",
          route: "performance/ratings",
          patterns: [{ path: "/dashboard/performance/ratings/*" }],
          enabled: true,
        },
        //      {
        //   title: "Performance Management",
        //   route: "performance-management",
        //   patterns: [{ path: "/dashboard/performance-management/*" }],
        // },
        // {
        //   title: "Goals",
        //   route: "goals",
        //   patterns: [{ path: "/dashboard/goals/*" }],
        // },
        // {
        //   title: "Objectives",
        //   route: "objectives",
        //   patterns: [{ path: "/dashboard/objectives/*" }],
        // },
        // {
        //   title: "Feedback",
        //   route: "feedback",
        //   patterns: [{ path: "/dashboard/feedback/*" }],
        // },
        // {
        //   title: "Conversations",
        //   route: "conversations",
        //   patterns: [{ path: "/dashboard/conversations/*" }],
        // },
        // {
        //   title: "Reviews",
        //   route: "reviews",
        //   patterns: [{ path: "/dashboard/reviews/*" }],
        // },
      ],
    },
    {
      title: "Tasks",
      icon: <DocumentTextIcon className="w-5" />,
      route: "tasks",
      patterns: [{ path: "/dashboard/tasks/*" }],
      enabled: true,
      badge: false,
      id: "tasks-nav",
    },
    {
      title: "Time Tracking",
      icon: <ClockIcon className="w-5" />,
      route: "time-tracking",
      subActions: [
        {
          enabled: true,
          route: "time-tracking/absence",
          title: "Absence",
          patterns: [{ path: "/dashboard/time-tracking/absence/*" }],
        },
      ],
      patterns: [{ path: "/dashboard/time-tracking/*" }],
      enabled: true,
      badge: false,
    },
    {
      title: "Disciplinary",
      icon: <ScaleIcon className="w-5" />,
      route: "disciplinary",
      patterns: [{ path: "/dashboard/disciplinary" }],
      enabled: user?.isHrisEnabled === "True",
      badge: false,
      subActions: [
        {
          id: "violations-nav",
          title: "Violations",
          route: "disciplinary/violations",
          patterns: [{ path: "/dashboard/disciplinary/violations/*" }],
          enabled: true,
        },
        {
          id: "policies-nav",
          title: "Policies",
          route: "disciplinary/policies",
          patterns: [{ path: "/dashboard/disciplinary/policies/*" }],
          enabled: true,
        },
        {
          title: "Triggers",
          id: "triggers-nav",
          route: "disciplinary/triggers",
          patterns: [{ path: "/dashboard/disciplinary/triggers/*" }],
          enabled: true,
        },
        {
          title: "Violation Types",
          id: "violation-types-nav",
          route: "disciplinary/violation-types",
          patterns: [{ path: "/dashboard/disciplinary/violation-types/*" }],
          enabled: true,
        },
      ],
    },
  ]
  const otherActions: ActionType[] = [
    // {
    //   title: "Reports",
    //   icon: <DocumentChartBarIcon className="w-5" />,
    //   route: "reports",
    //   patterns: [{ path: "/dashboard/reports/*" }],
    // },
    {
      title: "Settings",
      icon: <AdjustmentsVerticalIcon className="w-5" />,
      enabled: true,
      badge: false,
      subActions: [
        // {
        //   title: "Permissions",
        //   route: "settings/permissions",
        //   patterns: [{ path: "/dashboard/settings/permissions/*" }],
        //   enabled: true,
        // },
        {
          title: "Admins",
          route: "settings/permissions/admins",
          patterns: [{ path: "/dashboard/settings/permissions/*" }],
          enabled: true,
        },
        // {
        //   title: "Managers",
        //   route: "settings/permissions/manager",
        //   patterns: [{ path: "/dashboard/settings/permissions/*" }],
        //   enabled: true,
        // },
        // {
        //   title: "Integration",
        //   route: "settings/integration",
        //   patterns: [{ path: "/dashboard/settings/integration/*" }],
        //   enabled: true,
        // },
        {
          title: "HR & Payroll",
          route: "settings/hr-and-payroll",
          patterns: [{ path: "/dashboard/settings/hr-and-payroll/*" }],
          enabled: true,
        },
        {
          title: "Plan & Billing",
          route: "settings/plan-and-billing",
          patterns: [{ path: "/dashboard/settings/plan-and-billing/*" }],
          enabled: true,
        },
        {
          title: "Customization",
          route: "settings/customization",
          patterns: [{ path: "/dashboard/settings/customization/*" }],
          enabled: true,
        },

        {
          title: "Approval Workflows",
          route: "settings/approval-workflow",
          patterns: [{ path: "/dashboard/settings/approval-workflow/*" }],
          enabled: true,
        },
        {
          title: "Roles & Permissions",
          route: "settings/roles-permissions",
          patterns: [{ path: "/dashboard/settings/roles-permissions/*" }],
          enabled: true,
        },
      ],
    },
    {
      title: "Reports",
      icon: <DocumentChartBarIcon className="w-5" />,
      enabled: true,
      badge: false,
      patterns: [
        { path: "/dashboard/report/*" },
        { path: "/dashboard/report/custom-report/*" },
        {
          path: "/dashboard/report/employee-report-builder",
        },
      ],
      subActions: [
        {
          title: "Summary Reports",
          route: "report",
          patterns: [{ path: "/dashboard/report/*" }],
          enabled: true,
          id: "reports-summary-nav",
        },
        {
          title: "Custom Reports",
          route: "report/custom-report",
          patterns: [
            { path: "/dashboard/report/custom-report/*" },
            {
              path: "/dashboard/report/employee-report-builder",
            },
          ],
          enabled: true,
          id: "custom-reports-nav",
        },
        {
          title: "Payroll History",
          route: "reports-payroll-history",
          patterns: [
            { path: "/dashboard/reports-payroll-history/*" },
            { path: "/dashboard/reports-payroll-receipt/*" },
            { path: "/dashboard/reports-payroll-history/view/*" },
            { path: "/dashboard/reports/payroll-receipt/*" },
          ],
          enabled: true,
          id: "reports-payroll-history-nav",
        },
      ],
    },
    {
      title: "Assets",
      icon: <MegaphoneIcon className="w-5" />,
      enabled: user?.isHrisEnabled === "True" ? true : false,
      badge: false,
      subActions: [
        {
          title: "Assets",
          route: "assets",
          patterns: [{ path: "/dashboard/assets/*" }],
          enabled: true,
        },
        {
          title: "Assignments",
          route: "assignments",
          patterns: [{ path: "/dashboard/assignments/*" }],
          enabled: true,
        },
      ],
    },
    {
      title: "Audit Log",
      icon: <MagnifyingGlassCircleIcon className="w-5" />,
      enabled: true,
      route: "audits",
      patterns: [{ path: "/dashboard/audits/*" }],
      badge: false,
    },
    {
      title: "Announcements",
      route: "settings/announcements",
      patterns: [
        { path: "/dashboard/settings/announcements/*" },
        { path: "/dashboard/settings/announcement_groups/*" },
      ],
      enabled: true,
      badge: false,
      icon: <MegaphoneIcon className="w-5" />,
      subActions: [
        {
          title: "Announcements",
          route: "settings/announcements",
          patterns: [{ path: "/dashboard/settings/announcements/*" }],
          enabled: true,
        },
        {
          title: "Groups",
          route: "settings/announcement_groups",
          patterns: [{ path: "/dashboard/settings/announcement_groups/*" }],
          enabled: true,
        },
      ],
    },
  ]
  const extraActions: ActionType[] = [
    {
      title: "Calendar",
      icon: <ClockIcon className="w-5" />,
      route: "calendar",
      patterns: [{ path: "/dashboard/calendar/*" }],
      enabled: true,
      badge: false,
    },
    {
      title: "Help",
      icon: <QuestionMarkCircleIcon className="w-5" />,
      route: "https://support.padehcm.com/help",
      patterns: [{ path: "/dashboard/help/*" }],
      enabled: isPadeDomain(),
      badge: false,
      newTab: true,
    },
    /* {
      title: "Support",
      icon: <LifebuoyIcon className="w-5" />,
      route: "https://support.padehcm.com/help",
      patterns: [{ path: "/dashboard/support/*" }],
      enabled: true,
      badge: false,
    },*/
  ]
  const { toast } = useAppNotificationStore()

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (id: string) => {
      const res = await axiosInstance.post(`/admin/company/switch?id=${id}`)
      return res.data
    },
    onSuccess: async (data) => {
      if (data.status == "success") {
        const newToken = data.data.accessToken
        const newUser = data.data as IUser
        if (user) {
          newUser.userId = user.userId
          newUser.userAvatarFilename = user.userAvatarFilename
          newUser.userFirstName = user.userFirstName
          newUser.userFullName = user.userFullName
          newUser.userKey = user.userKey
          newUser.userLastName = user.userLastName
          newUser.userName = user.userName
          newUser.members = user.members
          newUser.userStatusId = user.userStatusId
          newUser.userRoles = user.userRoles
          newUser.userPhone = user.userPhone
          newUser.userPermissions = user.userPermissions
          newUser.personKey = user.personKey
          newUser.members = user.members
          newUser.lastLogin = user.lastLogin
          newUser.isSecondaryAdmin = user.isSecondaryAdmin
          newUser.isPrimaryAdmin = user.isPrimaryAdmin
          newUser.isAdmin = user.isAdmin
          newUser.controlOrganisationId = user.controlOrganisationId
          newUser.adminsCanSwitchOrganisations =
            user?.adminsCanSwitchOrganisations
        }
        await changeOrganization(newToken, newUser)
        window.location.href = "/dashboard"
      }
    },
  })
  const handleChangeOrg = (orgId: any) => {
    submit(orgId)
  }

  const handleToggleSidebar = () => {
    toggleSidebar(false)
    document.body.classList.remove("overflow-hidden")
  }

  return (
    <>
      {isSidebarOpen && (
        <div
          className="md:hidden fixed inset-0 z-20 bg-black/30  cursor-pointer backdrop-blur-sm"
          onClick={handleToggleSidebar}
        />
      )}
      <div
        className={`custom__sidebar__scrollbar ${
          !isSidebarOpen ? "w-0" : "w-[280px]"
        } pb-20 md:pb-0 fixed top-0 bottom-0 z-20 md:left-0 md:w-[280px] shadow-lg h-[100dvh] bg-primary500 transition-all duration-75 ease-in-out overflow-y-auto`}
      >
        <div
          className={` ${
            !isSidebarOpen ? "w-0" : "w-[280px]"
          } fixed top-0 bottom-0 z-[1] md:left-0 md:w-[280px] h-[100dvh] opacity-30 bg-center bg-cover `}
          style={{
            backgroundImage: `url(${SidebarBg})`,
          }}
        />
        <div className="w-full py-[18px] px-3 flex flex-col justify-start gap-3 relative z-[2]">
          {/* Brand */}
          <div className="relative font-avenir">
            <div
              className={`bg-primary600 p-3 rounded-md shadow cursor-pointer`}
              onClick={() => {
                toggleBusinessListOpen(!isBusinessListOpen)
              }}
            >
              <div className="flex flex-col items-start justify-between">
                <div className="mb-2">
                  {isPadeDomain() === true ? (
                    <img
                      src={logos.PadeLogoWhite}
                      alt=""
                      className="w-[100px]"
                    />
                  ) : (
                    <img
                      src={customizationSettings?.brand?.menuLogoUrl}
                      alt=""
                      className="w-[100px]"
                    />
                  )}
                </div>
                <div className="flex items-center justify-between mt-2 w-full">
                  <p className="text-a11y/90  text-xs">
                    {user?.userOrganisation}
                  </p>
                  <div className="flex justify-center  items-center">
                    <ChevronDownIcon className="w-5 h-5 text-a11y/90 " />
                  </div>
                </div>
              </div>
            </div>
            {isBusinessListOpen && (
              <div
                className={`mt-1 bg-primary600 pb-2 rounded-md shadow absolute w-full z-[1] overflow-hidden duration-150 ${
                  isBusinessListOpen ? "h-[155px] overflow-y-scroll" : "h-0"
                }`}
              >
                <div className="flex flex-col items-start py-3 px-5 space-y-4">
                  {user?.members
                    ?.filter((item) => item?.name !== user?.userOrganisation)
                    ?.map((org, index) => (
                      <button
                        key={index}
                        className="text-a11y/90  text-left text-sm w-full"
                        onClick={() => {
                          if (
                            user?.adminsCanSwitchOrganisations === "True" ||
                            user?.isPrimaryAdmin === "True" ||
                            user?.isSecondaryAdmin === "True"
                          ) {
                            handleChangeOrg(`${org?.key}`)
                            toggleSidebar(false)
                          } else {
                            toast.unauthorized(
                              "You are not authorized to switch between accounts"
                            )
                          }
                        }}
                      >
                        {org.name}
                      </button>
                    ))}

                  <button
                    className="text-a11y/90 px-3 py-1 flex items-start justify-start rounded-full text-sm border border-a11y/90"
                    onClick={() => {
                      navigate("/dashboard/company-details/new")
                      handleToggleSidebar()
                    }}
                  >
                    <span className="mr-2">
                      <PlusIcon className="w-4 h-4 text-a11y" />
                    </span>
                    <span>Add new Business</span>
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Menu */}
          <div className="h-full w-full flex flex-col gap-9">
            {/* Dashboard */}
            <div className="gap-3 flex flex-col">
              {user?.setupStatus === "Approved" ? (
                ""
              ) : (
                <NavTab
                  action={{
                    title: "Onboarding",
                    route: "company-onboarding",
                    icon: <Squares2X2Icon className="w-5" />,
                    patterns: [{ path: "/dashboard/company-onboarding" }],
                    enabled: true,
                    badge: false,
                  }}
                  toggleSidebar={handleToggleSidebar}
                />
              )}

              <NavTab
                id="dashboard-nav"
                action={{
                  title: "Dashboard",
                  route: "",
                  icon: <Squares2X2Icon className="w-5" />,
                  patterns: [{ path: "/dashboard" }],
                  enabled: true,
                  badge: false,
                }}
                toggleSidebar={handleToggleSidebar}
              />
              <NavTab
                id="cd-nav"
                action={{
                  title: "Company Details",
                  icon: <BuildingOfficeIcon className="w-5" />,
                  route: "company-details",
                  patterns: [{ path: "/dashboard/company-details/*" }],
                  enabled: true,
                  badge: false,
                }}
                toggleSidebar={handleToggleSidebar}
              />
            </div>
            {/* Other Menu */}
            <div className=" w-full flex flex-col gap-8">
              {/* People */}
              <div className=" w-full flex flex-col gap-2">
                <h6 className="text-[10px] text-a11y/80 pl-6">PEOPLE</h6>
                <div className=" w-full flex flex-col gap-0.5 3">
                  {peopleActions.map((action, index) =>
                    !action.enabled ? null : (
                      <NavTab
                        id={action.id}
                        action={action}
                        key={index}
                        index={index}
                        toggleSidebar={handleToggleSidebar}
                      />
                    )
                  )}
                </div>
              </div>
              {/* Payroll */}
              {user?.isPayrollEnabled === "False" ? null : (
                <div className=" w-full flex flex-col gap-2">
                  <h6 className="text-[10px] text-a11y/80 pl-6">
                    COMPENSATION
                  </h6>
                  <div className="w-full flex flex-col gap-0.5 3">
                    {compensationActions.map((action, index) =>
                      !action.enabled ? (
                        ""
                      ) : (
                        <NavTab
                          action={action}
                          key={index}
                          index={index}
                          toggleSidebar={handleToggleSidebar}
                          id={action.id}
                        />
                      )
                    )}
                  </div>
                </div>
              )}
              {/* Tasks */}

              <div className="w-full flex flex-col gap-2">
                <h6 className="text-[10px] text-a11y/80 pl-6">PRODUCTIVITY</h6>
                <div className=" w-full flex flex-col gap-0.5 3">
                  {taskActions.map((action, index) =>
                    !action.enabled ? (
                      ""
                    ) : (
                      <NavTab
                        action={action}
                        key={index}
                        index={index}
                        toggleSidebar={handleToggleSidebar}
                        id={action.id}
                      />
                    )
                  )}
                </div>
              </div>

              {/* Documents */}
              <div className="w-full flex flex-col gap-2">
                <h6 className="text-[10px] text-a11y/80 pl-6">OTHER</h6>
                <div className=" w-full flex flex-col gap-0.5 3">
                  {otherActions.map((action, index) =>
                    !action.enabled ? null : (
                      <NavTab
                        action={action}
                        key={index}
                        index={index}
                        toggleSidebar={handleToggleSidebar}
                      />
                    )
                  )}
                </div>
              </div>
              {/* Extras */}
              <div className="w-full flex flex-col gap-2 pb-40">
                <h6 className="text-[10px] text-a11y/80 pl-6">EXTRAS</h6>
                <div className=" w-full flex flex-col gap-0.5 3">
                  {extraActions.map((action, index) =>
                    !action.enabled ? null : (
                      <NavTab
                        action={action}
                        key={index}
                        index={index}
                        toggleSidebar={handleToggleSidebar}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="fixed bg-white bg-opacity-75  z-50 top-0 bottom-0 right-0 left-0 w-screen h-screen flex justify-center items-center">
          <LoadingIndicatorWhite />
        </div>
      )}
    </>
  )
}
