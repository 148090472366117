import React, { useState } from "react"
import { Button, LinkButton } from "components"
import AmountWithdrawn from "../../../assets/svg/amount_withdrawn.svg"
import AmountApproved from "../../../assets/svg/amount_approved.svg"
import { Alert, Dropdown, Spin, Table, Tag } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { images } from "assets"
import { newBaseUrl } from "utils/newbaseurl"
import { useGet } from "utils/useFetch"
import { IEmployeesData } from "./interface/employees"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { StatsCard } from "../documents/company-details/Cards"
import { IAnalytics } from "./interface/analytics"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import usePermissions from "hooks/usePermissions"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { CSVLink } from "react-csv"
import EWANotEnabled from "./EWANotEnabled"

const EnrolledEmployees: React.FC<{}> = () => {
  const { toast } = useAppNotificationStore()
  const [search, setSearch] = useState("")
  const [enrollmentStatus, setEnrollmentStatus] = useState(true)
  const [activeEmployee, setActiveEmployee] = useState<any>(null)
  const [openModal, setOpenModal] = useState(false)
  const [modalInfo, setModalInfo] = useState<any>({
    title: "",
    subtitle: ``,
    onSubmit: () => {},
  })
  const [canStopEarnings, canDisableEmployees] = usePermissions({
    allowedPermissions: ["can_stop_employee_earnings", "can_disable_employees"],
  })
  const { isLoading: isAnalyticsLoading, data: analyticsData } =
    useGet<IAnalytics>({
      url: `${newBaseUrl}/v1/employer/withdrawals/analytics?period=week`,
      external: true,
    })
  const {
    isLoading: isemployeesLoading,
    data: employeesData,
    isError,
    refetch,
  } = useGet<IEmployeesData>({
    url: `${newBaseUrl}/v1/employees?enrollmentStatus=${enrollmentStatus}&limit=0`,
    external: true,
    onSuccess: (data: any) => {
      mixPanelEvent("ewa-employees-listing")
    },
  })

  const { isLoading: isUpdatingDetails, mutate: handleUpdateEmployee } =
    useMutation({
      mutationFn: async (body: { reqBody: any; id: string }) => {
        const res = await axiosInstance.put(
          `${newBaseUrl}/v1/employees/${body.id}`,
          body.reqBody
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.success) {
          toast.success(`User details has been updated successfully`)
          setOpenModal(false)
          setModalInfo({
            title: "",
            subtitle: ``,
            onSubmit: () => {},
          })
          mixPanelEvent("ewa-employees-update-success")
          refetch()
        } else {
          toast.error(data?.message)
        }
      },
    })

  const handleStartEarnings = (id: string) => {
    mixPanelEvent("ewa-employees-start-earnings-init")

    handleUpdateEmployee({
      reqBody: {
        ...activeEmployee,
        earningsStopped: false,
      },
      id: id,
    })
  }
  const handleStopEarnings = (id: string) => {
    mixPanelEvent("ewa-employees-stop-earnings-init")
    handleUpdateEmployee({
      reqBody: {
        ...activeEmployee,
        earningsStopped: true,
      },
      id: id,
    })
  }
  const handleEnable = (id: string) => {
    mixPanelEvent("ewa-employees-enable-init")
    handleUpdateEmployee({
      reqBody: {
        ...activeEmployee,
        status: "ACTIVE",
      },
      id: id,
    })
  }
  const handleDisable = (id: string) => {
    mixPanelEvent("ewa-employees-disable-init")

    handleUpdateEmployee({
      reqBody: {
        ...activeEmployee,
        status: "INACTIVE",
      },
      id: id,
    })
  }
  const TableData =
    employeesData?.data.docs
      ?.filter((emp: any) => {
        const name = emp.firstName || emp.lastName
        return name.toLowerCase().includes(search.toLowerCase())
      })
      ?.map((employee, index: any) => {
        return {
          key: index,

          employee: {
            img: images.DefaultProfilePicture,
            name: `${employee.firstName} ${employee.lastName}`,
            earningsStopped: employee?.earningsStopped,
          },
          enrollment: {
            id: employee._id,
            title: employee.enrollmentStatus ? "Enrolled" : "Unenrolled",
          },
          status: {
            id: employee._id,
            title: employee.status == "ACTIVE" ? "Active" : "Inactive",
          },
          email: employee.email,
          employeeId: employee.employeeId,
          renumeration: `₦${formatCurrencyNoSymbol(employee.netSalary)}`,
          account: {
            accountNumber: employee?.bankAccountNumber,
            bank: employee?.bankName,
          },
          // phoneNumber: employee?.phoneNumber,

          action: {
            key: employee._id,
            id: employee._id,
            emp: {
              bankName: employee?.bankName,
              bankAccountName: employee?.bankAccountName,
              bankAccountNumber: employee?.bankAccountNumber,
              // bankCode: employee?.bankCode,
              // netSalary: employee?.netSalary,
              earningsStopped: employee?.earningsStopped,
              status: employee?.status,
              phoneNumber: employee?.phoneNumber,
            },
          },
        }
      }) || []
  const TableColumns =
    [
      {
        title: "Employee",
        dataIndex: "employee",
        render: (employee: {
          img: string
          name: string
          earningsStopped: boolean
        }) => (
          <div className="flex flex-col items-start gap-1">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]`}
            >
              {employee.name}
            </span>
            {employee.earningsStopped && (
              <Tag color="gold">Earnings stopped</Tag>
            )}
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (email: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {email}{" "}
          </span>
        ),
      },
      {
        title: "ID Number",
        dataIndex: "employeeId",
        render: (employeeId: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {employeeId}{" "}
          </span>
        ),
      },

      {
        title: "Account number",
        dataIndex: "account",
        render: (account: { accountNumber: string; bank: any }) => (
          <div className="flex flex-col ">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
            >
              {account?.accountNumber}{" "}
            </span>
            <span
              className={`whitespace-nowrap  font-avenir text-[10px] text-neutral400`}
            >
              {account?.bank}{" "}
            </span>
          </div>
        ),
      },
      {
        title: "Registered",
        dataIndex: "enrollment",
        render: (enrollment: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] capitalize rounded-[4px]
                    ${
                      enrollment?.title == "Enrolled"
                        ? "bg-success100 text-success800"
                        : "bg-neutral40"
                    } `}
          >
            {enrollment?.title == "Enrolled" ? "YES" : "NO"}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] capitalize rounded-[4px]
                    ${
                      status?.title == "Active"
                        ? "bg-success100 text-success800"
                        : "bg-neutral40 text-neutral500"
                    } `}
          >
            {status?.title}
          </span>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (action: {
          key: string
          id: string
          status: string
          emp: any
        }) => (
          <Dropdown
            menu={{
              items: actionItems(action),
            }}
          >
            <button onClick={() => setActiveEmployee(action.emp)}>
              <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        ),
      },
    ] || []

  const actionItems = (action: {
    key: string
    id: string
    status: string
    emp: any
  }) => {
    const { key, id, status, emp } = action
    let arrayOptions: any = []
    {
      id &&
        !emp?.earningsStopped &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "4",
            label: (
              <button
                className="w-full"
                onClick={() => {
                  if (canStopEarnings) {
                    setOpenModal(true)
                    setModalInfo({
                      title: "Stop Earnings",
                      subtitle: `If you stop this employee's earnings, they’ll not be able to partake in withdrawal from their daily earnings. Click Confirm to continue.`,
                      onSubmit: () => {
                        handleStopEarnings(action.id)
                      },
                    })
                  } else {
                    toast.unauthorized()
                  }
                }}
              >
                Stop earnings
              </button>
            ),
          },
        ])
    }
    {
      id &&
        emp?.earningsStopped &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "5",
            label: (
              <button
                className="w-full"
                onClick={() => {
                  if (canStopEarnings) {
                    setOpenModal(true)
                    setModalInfo({
                      title: "Start Earnings",
                      subtitle: `If you start this employee's earnings, they’ll be able to partake in withdrawal from their daily earnings. Click Confirm to continue.`,
                      onSubmit: () => {
                        handleStartEarnings(action.id)
                      },
                    })
                  } else {
                    toast.unauthorized()
                  }
                }}
              >
                Resume earnings
              </button>
            ),
          },
        ])
    }
    {
      id &&
        emp?.status == "INACTIVE" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "6",
            label: (
              <div
                className="w-full"
                onClick={() => {
                  if (canDisableEmployees) {
                    setOpenModal(true)
                    setModalInfo({
                      title: "Enable Employee",
                      subtitle: `If you enable this employee, they’ll not be able to partake in withdrawal from their daily earnings. Click Confirm to continue.`,
                      onSubmit: () => {
                        handleEnable(action.id)
                      },
                    })
                  } else {
                    toast.unauthorized()
                  }
                }}
              >
                Enable
              </div>
            ),
          },
        ])
    }
    {
      id &&
        emp?.status == "ACTIVE" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "7",
            label: (
              <button
                className="w-full"
                onClick={() => {
                  if (canDisableEmployees) {
                    setOpenModal(true)
                    setModalInfo({
                      title: "Disable Employee",
                      subtitle: `If you disable this employee, they’ll not be able to partake in withdrawal from their daily earnings. Click Confirm to continue.`,
                      onSubmit: () => {
                        handleDisable(action.id)
                      },
                    })
                  } else {
                    toast.unauthorized()
                  }
                }}
              >
                Disable
              </button>
            ),
          },
        ])
    }

    return arrayOptions
  }
  const cardData = [
    {
      icon: AmountWithdrawn,
      title: "Registered employees",
      value: analyticsData?.data?.totalEnrolledEmployees || 0,
      type: "increase",
      handleClick: () => {
        setEnrollmentStatus(true)
      },
      id: "ewa-employees-registered",
    },
    {
      icon: AmountApproved,
      title: "Unregistered employees",
      value:
        (analyticsData?.data &&
          analyticsData?.data.totalEmployees -
            analyticsData?.data?.totalEnrolledEmployees) ||
        0,
      type: "decrease",
      handleClick: () => {
        setEnrollmentStatus(false)
      },
      id: "ewa-employees-unregistered",
    },
  ]
  const headers = [
    // { label: "Date", key: "createdAt" },
    { label: "First name", key: "firstName" },
    { label: "Last name", key: "lastName" },
    { label: "ID Number", key: "employeeId" },
    { label: "Email", key: "email" },
    { label: "Phone number", key: "phoneNumber" },
    { label: "Bank", key: "bankName" },
    { label: "Account Number", key: "bankAccountNumber" },
    { label: "Status", key: "status" },
  ]

  const csvProps = {
    filename: "employees.csv",
    headers: headers,
    data: employeesData?.data?.docs || [],
  }

  return (
    <div className="relative">
      <DashboardWrapper>
        {isemployeesLoading ? (
          <div className="flex justify-center w-full h-screen items-center">
            <Spin />
          </div>
        ) : employeesData ? (
          <div className="flex flex-col gap-5">
            <div className="flex justify-between w-full items-center">
              <h5 className="text-lg font-bold text-neutral700 ">Employees</h5>
              {employeesData?.data?.docs?.length ? (
                <CSVLink {...csvProps}>
                  <Button
                    color="primary"
                    title="Download"
                  >
                    Download
                  </Button>
                </CSVLink>
              ) : (
                <></>
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 ">
              {cardData.map((card, idx) => (
                <StatsCard
                  id={card.id}
                  key={idx}
                  image={card.icon}
                  title={card.title}
                  text={card.value}
                  handleClick={card.handleClick}
                />
              ))}
            </div>
            <div
              className="bg-white  rounded-5px p-2"
              id="ewa-employees-list"
            >
              <div className=" h-10 flex   items-center my-2 justify-between ">
                <div className="border-2  h-8  rounded-5px w-[268px] flex  items-center px-4 bg-white ">
                  <MagnifyingGlassIcon className=" text-neutral80 w-4" />
                  <input
                    placeholder="Search"
                    className="bg-none w-full px-2 outline-none  text-sm h-7"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />
                </div>
                {/* <Select
                    defaultValue={true}
                    style={{ width: 120 }}
                    placeholder="Filter by"
                    onChange={(value: boolean) => {
                      setEnrollmentStatus(value)
                    }}
                    options={[
                      { value: true, label: "Enrolled" },
                      { value: false, label: "Unenrolled" },
                    ]}
                    className="font-avenir "
                  /> */}
              </div>
              <Table
                columns={TableColumns}
                dataSource={TableData}
                loading={isemployeesLoading}
                className="text-[#42526D]"
                pagination={{
                  defaultPageSize: 20,
                }}
                scroll={{
                  x: "100%",
                }}
              />
            </div>
          </div>
        ) : (
          <EWANotEnabled />
        )}
      </DashboardWrapper>

      <NewModalWrapper
        title={modalInfo?.title}
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setModalInfo({
            title: "",
            subtitle: ``,
            onSubmit: () => {},
          })
        }}
      >
        <>
          <p className="my-4 px-4">{modalInfo.subtitle}</p>
          <div className="border py-5 px-6 flex gap-4 justify-end items-center ">
            <Button
              title="cancel"
              color="danger"
              onClick={() => {
                setOpenModal(false)
                setModalInfo({
                  title: "",
                  subtitle: ``,
                  onSubmit: () => {},
                })
              }}
            >
              Cancel
            </Button>

            <Button
              title="update"
              color="primary"
              onClick={() => {
                modalInfo?.onSubmit()
              }}
              submitting={isUpdatingDetails}
            >
              Confirm
            </Button>
          </div>
        </>
      </NewModalWrapper>
    </div>
  )
}
export default EnrolledEmployees
