import { Button, Label } from "components"
import React, { useState } from "react"
import { Select, Form, Input, Alert, InputNumber } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
// import EnterPin from "../pin/EnterPin"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { KycLevel } from "pages/employee-dashboard/wallet/wallet/kyc/interface"
import { IBeneficiaries } from "pages/employee-dashboard/wallet/wallet/beneficiaries/interface"
import AntToggle from "components/inputs/AntToggle"
import { getAcronym } from "../ViewLocalCurrency"
import { sampleAmounts } from "./SendMoneyEmpCB"
import { useGetCountries } from "components/new-stuff/library/common-utils"

interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<any[]>>
  currentLevel: null | KycLevel
  x?: string | null
  handleProceed: () => void
  found: any | undefined
  currencyCode: string
  availableBalance: number | undefined
}

const SingleSend = ({
  setPreviewData,
  currentLevel,
  handleProceed,
  x,
  found,
  currencyCode,
  availableBalance,
}: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const { countries, isLoading: isLoadingCountries } = useGetCountries()
  const EmployeeWalletID = user?.userWalletId
  const { toast } = useAppNotificationStore()
  const [useBeneficiary, setUseBeneficiary] = useState(false)
  const [saveBeneficiary, setSaveBeneficiary] = useState(false)
  const selectedPaymentType = Form.useWatch("paymentMethodType", form)
  const beneficiaryType = Form.useWatch("beneficiaryType", form)
  const [savedBeneficiary, setSavedBeneficiary] = useState({
    accountNumber: "",
    amount: "",
    currency: "",
    paymentMethodType: "",
    bankCode: "",
    beneficiaryCountryISO: "",
    beneficiaryType: "",
    beneficiaryFirstName: "",
    beneficiaryLastName: "",
    beneficiaryCompanyName: "",
    addToBeneficiary: "",
    purposeId: "",
  })

  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries?currency=${currencyCode}`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries?currency=${currencyCode}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: IBeneficiaries) => {
        if (x) {
          const benFound = data?.data?.find((ben) => ben._id === x)
          if (benFound) {
            // form.setFieldValue("bank", benFound?.bankCode)
            // form.setFieldValue("account_number", benFound?.accountNumber)
            // setBeneficiaryInfo({
            //   bankCode: benFound?.bankCode,
            //   accountNumber: benFound?.accountNumber,
            //   accountName: benFound?.accountName,
            //   bankName: benFound?.bankName,
            // })
          }
        }
      },
    })
  // Purpose TYPES
  const { isLoading: isLoadingPurposes, data: purposeRes } = useQuery<any>({
    queryKey: [`transfer-purposes`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/transfer-purposes`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    onSuccess: (data: IBeneficiaries) => {
      if (x) {
        const benFound = data?.data?.find((ben) => ben._id === x)
        if (benFound) {
          // form.setFieldValue("bank", benFound?.bankCode)
          // form.setFieldValue("account_number", benFound?.accountNumber)
          // setBeneficiaryInfo({
          //   bankCode: benFound?.bankCode,
          //   accountNumber: benFound?.accountNumber,
          //   accountName: benFound?.accountName,
          //   bankName: benFound?.bankName,
          // })
        }
      }
    },
  })

  const onFinish = (values: any) => {
    const {
      accountNumber,
      amount,
      description,
      paymentMethodType,
      bankCode,
      beneficiaryCountryISO,
      beneficiaryType,
      beneficiaryFirstName,
      beneficiaryLastName,
      beneficiaryCompanyName,
      addbeneficiary,
      purpose,
    } = values

    setPreviewData([
      {
        accountNumber,
        amount,
        currency: currencyCode,
        description,
        paymentMethodType,
        bankCode,
        beneficiaryCountryISO,
        beneficiaryType,
        beneficiaryFirstName,
        beneficiaryLastName,
        beneficiaryCompanyName,
        addToBeneficiary: saveBeneficiary,
        purposeId: String(purpose),
      },
    ])
    handleProceed()
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <>
          <div className="px-4 md:px-6 lg:px-8 xl:px-[40px]">
            <div className="  mt-5 pb-8 flex flex-col  items-start w-full">
              {/* DESCRIPTION */}
              <div className="w-full">
                <CustomInput
                  label="Payment description"
                  name="description"
                  className="text-padeBlack"
                />{" "}
              </div>
              {/* AMOUNT */}
              <div className="mb-6">
                <Label className="mb-3 text-padeBlack font-circular text-sm">
                  Select amount{" "}
                </Label>
                <div className="flex  flex-wrap items-center gap-2">
                  {sampleAmounts.map((amount, index) => (
                    <button
                      key={index}
                      type="button"
                      className="h-[36px] w-[97px] border border-[#6c7a9326] rounded-5px bg-neutral30  focus:bg-padeLightBlue  focus:border-primary500"
                      onClick={(e) => {
                        e.preventDefault()
                        form.setFieldValue("amount", String(amount))
                      }}
                    >
                      {found?.symbol} {formatCurrencyNoSymbol(amount)}
                    </button>
                  ))}
                </div>
              </div>
              <div className="w-full">
                <Form.Item
                  name="amount"
                  label={
                    <div className="flex w-screen justify-between ">
                      <span className="text-padeBlack text-sm font-circular  ">
                        {" "}
                        Enter other amount
                      </span>
                      {availableBalance && (
                        <span>
                          Balance:{" "}
                          <span className="font-medium">
                            {found?.symbol}
                            {formatCurrencyNoSymbol(availableBalance)}
                          </span>
                        </span>
                      )}
                    </div>
                  }
                  required
                  rules={[
                    // {
                    //   validator: (_, value) => {
                    //     const limit =
                    //       currentLevel && currentLevel.level > 2
                    //         ? Number.MAX_SAFE_INTEGER
                    //         : 200000
                    //     if (!value || value <= limit) {
                    //       return Promise.resolve()
                    //     }
                    //     return Promise.reject(
                    //       new Error(
                    //         `You can only send a maximum of ${formatCurrencyNoSymbol(
                    //           limit
                    //         )} to other banks, kindly upgrade your account `
                    //       )
                    //     )
                    //   },
                    // },
                    {
                      validator: (_, value) => {
                        const minimumAmount = 5
                        if (!value || value >= minimumAmount) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            `Amount must be at least  ${found?.symbol} ${minimumAmount}`
                          )
                        )
                      },
                    },
                  ]}
                >
                  {/* <Input
                    type="number"
                    className="font-circular text-neutral400"
                    addonBefore={found?.symbol}
                    placeholder=""
                  /> */}
                  <InputNumber<number>
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      value?.replace(/,/g, "") as unknown as number
                    }
                    className="w-full font-circular text-neutral400"
                    addonBefore={found?.symbol}
                    placeholder="e.g 200"
                  />
                </Form.Item>
              </div>
              {/* SAVED BENEFICIARIES */}
              {/* {beneficiariesData && beneficiariesData?.data?.length > 0 && (
                <div className=" flex  items-center gap-9 mb-6">
                  <Label className="font-circular text-sm  text-padeBlack">
                    Select from saved beneficiaries to send
                    <div className="relative mt-1 h-6">
                      {beneficiariesData?.data
                        ?.slice(0, 5)
                        ?.map((item, index) => (
                          <div
                            key={index}
                            className="h-8 w-8 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold absolute border-2 border-white"
                            style={{ left: `${index * 20}px` }}
                          >
                            {getAcronym(item?.accountName)}
                          </div>
                        ))}
                    </div>
                  </Label>

                  <div className="">
                    <AntToggle
                      name="usebeneficiary"
                      label=""
                      checked={useBeneficiary}
                      onChange={(e: any) => {
                        setUseBeneficiary(!useBeneficiary)
                      }}
                    />
                  </div>
                </div>
              )} */}
              {/* USE BENEFICIARY OPTION */}
              {useBeneficiary ? (
                <div className="w-full">
                  <div className="mt-1">
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm  ">
                          Select beneficiary to send
                        </span>
                      }
                      name="beneficiary"
                      rules={[
                        { required: true, message: "Please enter this field!" },
                      ]}
                    >
                      <Select
                        showSearch
                        loading={isLoadingBeneficiaries}
                        onChange={(value) => {
                          const selected = beneficiariesData?.data?.find(
                            (item) => item.accountName === value
                          )
                          if (selected) {
                            console.log(selected)

                            setSavedBeneficiary({
                              ...savedBeneficiary,
                              accountNumber: selected?.accountNumber,
                              currency: currencyCode,
                              bankCode: selected?.bankCode,
                              // paymentMethodType: "",
                              // beneficiaryCountryISO: "",
                              // beneficiaryType: "",
                              // beneficiaryFirstName: "",
                              // beneficiaryLastName: "",
                              // beneficiaryCompanyName: "",
                              // addToBeneficiary: "",
                              purposeId: "",
                            })
                          }
                        }}
                        options={beneficiariesData?.data
                          ?.filter((item) => item.type === "bank")
                          ?.map((item) => ({
                            label: item?.accountName,
                            value: item?.accountName,
                          }))}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder="Select beneficiary"
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : (
                <div className="w-full grid grid-cols-2 gap-x-6">
                  {/* // COUNTRYISO */}
                  <Form.Item
                    label={
                      <span className="text-padeBlack text-sm font-circular  ">
                        {" "}
                        Beneficiary country
                      </span>
                    }
                    name="beneficiaryCountryISO"
                    rules={[
                      {
                        required: true,
                        message: "Please enter this field!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={isLoadingBeneficiaries}
                      options={countries?.map((item) => ({
                        label: item?.description,
                        value: item?.alpha2Code,
                      }))}
                      filterOption={(input, option) => {
                        if (option) {
                          const value = (option?.label as string) || ""
                          return (
                            value
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          )
                        } else {
                          return false
                        }
                      }}
                      placeholder="Select country"
                    />
                  </Form.Item>
                  {/* BENEFICIARY TYPES */}
                  <div>
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm  ">
                          Beneficiary type
                        </span>
                      }
                      name="beneficiaryType"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        options={AllBeneficiaryType?.map((item) => ({
                          label: item?.label,
                          value: item?.value,
                        }))}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder="Select"
                      />
                    </Form.Item>
                  </div>
                  {/*ACCOUNT NUMBER  */}
                  <CustomInput
                    label="Beneficiary account number"
                    name="accountNumber"
                    required
                  />

                  {/* PAYMENT METHOD */}
                  <div>
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm  ">
                          Payment method type
                        </span>
                      }
                      name="paymentMethodType"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        options={PaymentMethods?.map((item) => ({
                          label: item.label,
                          value: item.value,
                        }))}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder="Select"
                      />
                    </Form.Item>
                  </div>
                  {/* BENEFICIARY INDIVIDUAL INFO */}
                  {beneficiaryType === "individual" && (
                    <>
                      <CustomInput
                        label="Beneficiary first name"
                        name="beneficiaryFirstName"
                        required
                      />
                      <CustomInput
                        label="Beneficiary last name"
                        name="beneficiaryLastName"
                        required
                      />
                    </>
                  )}
                  {/* BENEFICIARY COMPANY NAME */}
                  {beneficiaryType === "company" && (
                    <CustomInput
                      label="Beneficiary company name"
                      name="beneficiaryCompanyName"
                      required
                    />
                  )}
                  {/*Purpose*/}
                  <div>
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm  ">
                          Purpose
                        </span>
                      }
                      name="purpose"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        options={purposeRes?.data?.map((item: DataItem) => ({
                          label: item.title,
                          value: item.id,
                        }))}
                        loading={isLoadingPurposes}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder="Select"
                      />
                    </Form.Item>
                  </div>
                  {/* BANK CODE */}
                  <div>
                    <CustomInput
                      label="Routing number"
                      name="bankCode"
                      required
                    />
                  </div>
                  <div className=" col-span-2">
                    <Alert
                      message={""}
                      type="warning"
                      showIcon
                      description={
                        "Please double-check your payment details before proceeding. We won't be liable for any losses due to incorrect information."
                      }
                      className="invoice-alert font-avenir text-neutral500 text-sm w-full items-center mb-6 rounded-none"
                    />
                  </div>
                  <div className=" flex  items-center gap-2 mb-6">
                    <Label>Save beneficiary</Label>
                    <AntToggle
                      name="addbeneficiary"
                      label=""
                      checked={saveBeneficiary}
                      onChange={(e: any) => {
                        setSaveBeneficiary(!saveBeneficiary)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default SingleSend
export const PaymentMethods = [
  {
    value: "RoutingNumber",
    label: "Routing Number",
  },
  {
    value: "SortCode",
    label: "Sort Code",
  },
  {
    value: "BankCode",
    label: "Bank Code",
  },
  {
    value: "AbaCode",
    label: "Aba Code",
  },
  {
    value: "BicCode",
    label: "Bic Code",
  },
  {
    value: "SwiftCode",
    label: "Swift Code",
  },
  {
    value: "IfcCode",
    label: "Ifc Code",
  },
]
export const AllBeneficiaryType = [
  {
    value: "individual",
    label: "Individual",
  },
  {
    value: "company",
    label: "Company",
  },
]
export interface IPurposeResponse {
  success: boolean
  code: number
  message: string
  data: DataItem[]
}

export interface DataItem {
  id: number
  title: string
}
