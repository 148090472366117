import { Button } from "components"
import React, { useEffect, useState } from "react"
import { Select, Checkbox, Form, Switch, Spin, InputNumber } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import { IPreviewData } from "../SendMoneyBank"
import { useMutation, useQuery } from "@tanstack/react-query"
import { IEmployeeForWallet } from "../beneficiaries/interface"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { IFormBody, ITransferWalletBody } from "./interface"
import { groupFormDataByIndex } from "./component"
import DeleteIcon from "../../../../../assets/images/delete_icon.svg"
import { getAcronym } from "../Wallet"
import EnterPin from "../pin/EnterPin"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<IPreviewData[]>>
  amountOverBalance: boolean
  allBeneficiariesFilled: boolean
}

const MultipleWallet = ({
  setPreviewData,
  amountOverBalance,
  allBeneficiariesFilled,
}: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [scheduleConfirmation, setScheduleConfirmation] = useState(false)
  const [useBeneficiary, setUseBeneficiary] = useState(false)
  const [showPinModal, setShowPinModal] = useState(false)

  const [formBodyMultiple, setFormBodyMultiple] = useState<IFormBody[]>([
    {
      accountNumber: "",
      bankCode: "",
      bankName: "",
      amount: "",
      currency: "",
      description: "",
      sendToBeneficiary: false,
      bankDetails: null,
      addToBeneficiary: false,
    },
  ])

  const { isLoading: isLoadingEmployees, data: employeesData } =
    useQuery<IEmployeeForWallet>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/user-group-beneficiaries`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/user-group-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: getBankDetails, mutate: submitGetBankDet } = useMutation({
    mutationFn: async (value: { body: any; index: number }) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/resolve-wallet`,
        value.body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      if (res) {
        form.setFieldValue(
          `accountName~${value.index}`,
          String(res?.data?.data?.accountName)
        )
        form.setFieldValue(
          `accountName~${value.index}`,
          String(res?.data?.data?.accountName)
        )
        setFormBodyMultiple((prev) => {
          return prev.map((item, i) => {
            if (i === value?.index) {
              return {
                ...item,
                bankDetails: res?.data?.data?.accountName,
              }
            }
            return item
          })
        })
      }

      return res
    },
    onError: (data: any) => {
      toast.error(data?.response?.data?.message)
    },
  })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITransferWalletBody) => {
      const res = await axiosInstance.post(
        `employee/wallet/initiate_wallet_transfer`,
        body
        // {
        //   headers: {
        //     api_key: walletPublicKey,
        //   },
        // }
      )

      return res?.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        mixPanelEvent(`empl-wallet-multiple-transfer-success`)
        navigate(
          user?.userStatusId === "2" || user?.userStatusId === "3"
            ? "/employee/susp-exit/wallet/overview"
            : "/employee/dashboard/wallet/overview"
        )
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })

  const sampleAmounts = [5000, 10000, 20000, 50000]

  // Handler to update form body values
  const handleInputChange = async (
    index: number,
    field: keyof IFormBody | string,
    value: string | boolean
  ) => {
    const updatedFormBodies = [...formBodyMultiple]
    updatedFormBodies[index] = {
      ...updatedFormBodies[index],
      [field]: value,
    }

    // Set the updated state
    setFormBodyMultiple(updatedFormBodies)

    if (
      field === `accountNumber~${index}` &&
      typeof value === "string" &&
      value.length === 9
    ) {
      const ResolveBody = {
        body: {
          accountNumber: value,
        },
        index,
      }

      try {
        const response: any = await submitGetBankDet(ResolveBody)
        updatedFormBodies[index].bankDetails = response
        setFormBodyMultiple(updatedFormBodies)
      } catch (error) {}
    }

    form.setFieldsValue({ formBodyMultiple })
  }

  const handleAddRecipient = () => {
    setFormBodyMultiple([
      ...formBodyMultiple,
      {
        accountNumber: "",
        bankCode: "",
        bankName: "",
        amount: "",
        currency: "",
        description: "",
        addToBeneficiary: false,
        sendToBeneficiary: false,
        bankDetails: null,
      },
    ])
  }

  const handleDeleteRecipient = (index: number) => {
    const updatedFormBodies = formBodyMultiple.filter((_, i) => i !== index)
    setFormBodyMultiple(updatedFormBodies)
  }

  const onFinish = (values: any) => {
    cancelIdleCallback
    setShowPinModal(true)
  }
  const handleSubmit = (value: string) => {
    const formData = form.getFieldsValue()
    const groupedData = groupFormDataByIndex(formData)
    submit({
      pin: value,
      payments:
        groupedData.length > 0
          ? groupedData.map((data) => ({
              walletId: data.accountNumber ?? "",
              amount: Number(data?.amount) ?? 0,
              currency: "NGN",
              description: data.description ?? "",
              addToBeneficiary: data?.addBeneficiary ? true : false,
            }))
          : [],
    })
    mixPanelEvent(`empl-wallet-multiple-transfer-init`)
  }

  useEffect(() => {
    const formattedPreviewData = formBodyMultiple?.map((item, index) => ({
      beneficiary: item?.bankDetails,
      bank_name: item?.bankName || "PaidHR Wallet",
      account_number: item?.accountNumber,
      amount: item?.amount ?? "0",
    }))

    setPreviewData(formattedPreviewData)
  }, [formBodyMultiple])

  const initialValues = form.getFieldsValue()

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <>
          <div className=" mt-5 flex flex-col  items-start w-full">
            <div className="w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
              {" "}
              <CustomInput
                label="Payment description"
                name="description"
              />{" "}
            </div>
            {formBodyMultiple.map((item, index) => (
              <div
                key={index}
                className={`w-full ${
                  index !== formBodyMultiple?.length - 1
                    ? "mb-6 border-b border-[#C2C7D0]"
                    : ""
                } px-4 md:px-6 lg:px-8 xl:px-[40px]`}
              >
                <div className="flex items-center mb-4">
                  <p className="font-bold text-[#42526D] text-[15px] font-avenir ">
                    Recipient {index + 1}
                  </p>
                  {formBodyMultiple.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleDeleteRecipient(index)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="delete"
                        className="ml-6 "
                      />
                    </button>
                  )}
                </div>
                <div className="mb-6">
                  <span className="mb-3 text-padeBlack text-sm font-circular">
                    Select amount{" "}
                  </span>
                  <div className="flex  flex-wrap items-center gap-2">
                    {sampleAmounts.map((amount, index) => (
                      <button
                        type="button"
                        key={index}
                        className="h-[36px] w-[97px] border border-[#6c7a9326] rounded-5px bg-neutral30  focus:bg-padeLightBlue  focus:border-primary500"
                        onClick={() => {
                          handleInputChange(
                            index,
                            `amount~${index}`,
                            String(amount)
                          )
                          form.setFieldValue(`amount~${index}`, String(amount))
                          setFormBodyMultiple((prev) => {
                            return prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  amount: String(amount),
                                }
                              }
                              return item
                            })
                          })
                        }}
                      >
                        ₦ {formatCurrencyNoSymbol(amount)}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="w-full mb-6">
                  <Form.Item
                    name={`amount~${index}`}
                    label={
                      <span className="text-padeBlack text-sm font-circular ">
                        Enter other amount
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input amount",
                      },
                    ]}
                  >
                    <InputNumber<number>
                      addonBefore="₦"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) =>
                        value ? parseFloat(value.replace(/,/g, "")) : 0
                      }
                      name={`amount~${index}`}
                      className="w-full"
                      placeholder="e.g 200"
                      onChange={(value: any) => {
                        handleInputChange(index, `amount~${index}`, value)

                        setFormBodyMultiple((prev) => {
                          return prev.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                amount: value,
                              }
                            }
                            return item
                          })
                        })
                      }}
                    />
                  </Form.Item>
                </div>
                <div className=" flex  items-center gap-9 mb-6">
                  <span className="mb-3 text-padeBlack text-sm font-circular">
                    Select beneficiary from your organization{" "}
                    <div className="relative mt-1 h-6">
                      {employeesData?.data?.docs
                        ?.slice(0, 5)
                        ?.map((item, index) => (
                          <div
                            key={index}
                            className="h-8 w-8 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold absolute border-2 border-white"
                            style={{ left: `${index * 20}px` }}
                          >
                            {getAcronym(item?.commonName)}
                          </div>
                        ))}
                    </div>
                  </span>

                  <Form.Item
                    name={`usebeneficiary~${index}`}
                    valuePropName="checked"
                  >
                    <Switch
                      onChange={(checked: boolean) => {
                        setFormBodyMultiple((prev) => {
                          return prev.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                sendToBeneficiary: checked,
                              }
                            }
                            return item
                          })
                        })
                        form.setFieldValue(`usebeneficiary~${index}`, checked)
                      }}
                      className="bg-[#ededed]"
                    />
                  </Form.Item>
                </div>
                {/* USE BENEFICIARY OPTION */}
                {item?.sendToBeneficiary ? (
                  <div className="w-full">
                    <div className="mt-1">
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm font-circular  ">
                            Select beneficiary to send
                          </span>
                        }
                        name={`beneficiary~${index}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={isLoadingEmployees}
                          onChange={(value) => {
                            const selected = employeesData?.data?.docs?.find(
                              (item) => item.walletId === value
                            )

                            if (selected) {
                              // form.setFieldValue(
                              //   `bank~${index}`,
                              //   String(selected?.bankCode)
                              // )

                              form.setFieldValue(
                                `accountNumber~${index}`,
                                String(selected?.walletId)
                              )

                              setFormBodyMultiple((prev) => {
                                return prev.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      bankDetails: selected?.commonName,
                                      bankCode: "",
                                      bankName: "PaidHR Wallet",
                                      accountNumber: String(selected?.walletId),
                                    }
                                  }
                                  return item
                                })
                              })
                            }
                          }}
                          options={employeesData?.data?.docs?.map((item) => ({
                            label: item.commonName,
                            value: item.walletId,
                          }))}
                          filterOption={(input, option) => {
                            if (option) {
                              const value = (option?.label as string) || ""
                              return (
                                value
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              )
                            } else {
                              return false
                            }
                          }}
                          placeholder="Select beneficiary"
                        />
                      </Form.Item>
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    <CustomInput
                      label="PaidHR Wallet ID"
                      name={`accountNumber~${index}`}
                      required
                      type="number"
                      disabled={getBankDetails}
                      placeholder="Enter 9-digit Wallet Id"
                      onChange={(e) => {
                        handleInputChange(
                          index,
                          `accountNumber~${index}`,
                          e.target.value
                        )
                        setFormBodyMultiple((prev) => {
                          return prev.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                accountNumber: e.target.value,
                              }
                            }
                            return item
                          })
                        })
                      }}
                      extra={
                        !item?.bankDetails && getBankDetails ? (
                          <div>
                            <Spin className="h-4 w-4" />
                          </div>
                        ) : item?.bankDetails ? (
                          <span className="text-xs">{item?.bankDetails}</span>
                        ) : null
                      }
                    />
                    <div className=" flex  items-center gap-2">
                      <span className="text-padeBlack text-sm font-circular">
                        Save beneficiary
                      </span>
                      <Form.Item
                        name={`addBeneficiary~${index}`}
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Switch
                          onChange={(checked: boolean) => {
                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    addToBeneficiary: checked,
                                  }
                                }
                                return item
                              })
                            })
                            form.setFieldValue(
                              `addBeneficiary~${index}`,
                              checked
                            )
                          }}
                          className="bg-[#ededed]"
                        />
                      </Form.Item>
                      {/* s */}
                    </div>
                  </div>
                )}
                <div className="opacity-0 h-1 overflow-hidden">
                  <Form.Item name={`bank~${index}`}></Form.Item>
                  <Form.Item name={`accountNumber~${index}`}></Form.Item>
                </div>
              </div>
            ))}

            <div className="mt-4 w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
              {formBodyMultiple.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddRecipient}
                  className="text-primary500  font-circular text-sm underline"
                >
                  +Add Another Recipient
                </button>
              )}
              <div className="flex items-center gap-3 mt-2">
                <Form.Item
                  name="scheduleConfirmation"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: "You must confirm the payment details above.",
                    },
                  ]}
                >
                  <Checkbox className="text-sm text-[#5D6B82]">
                    I confirm the payment details above
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className=" flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                type="button"
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={() => {
                form.submit()
              }}
              disabled={amountOverBalance || !allBeneficiariesFilled}
            >
              Proceed{" "}
            </Button>
          </div>
        </>
      </Form>
      <EnterPin
        title="Enter Transaction Pin"
        onClose={() => setShowPinModal(false)}
        open={showPinModal}
        loading={isLoading}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default MultipleWallet
