import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Form, FormItemProps, Input, Select, Tooltip } from "antd"
import type { TextAreaProps, InputProps } from "antd/es/input"

const { Option } = Select

export const CustomInput = ({
  label,
  name,
  placeholder,
  required = false,
  disabled = false,
  onChange,
  minLength,
  maxLength,
  type = "text",
  addonBefore,
  prefix,
  suffix,
  tooltip = "",
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <Tooltip title={`${tooltip || ""}` || ""}>
          <div className="flex gap-2 w-fit">
            <span className=" text-padeBlack font-circular text-sm  ">
              {label}
            </span>
            {tooltip && (
              <ExclamationCircleIcon className="w-5 h-5 opacity-60 hover:opacity-100" />
            )}
          </div>
        </Tooltip>
      }
      name={name}
      rules={[{ required, message: "Please enter this field" }]}
      // tooltip="This is a required field"
      {...restProps}
    >
      <Input
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        className="  text-neutral400"
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        addonBefore={addonBefore}
        prefix={prefix}
        suffix={suffix}
      />
    </Form.Item>
  )
}

export const CustomTextArea = ({
  label,
  name,
  required = false,
  inputProps,
  ...restProps
}: TextAreaInputItemProps) => {
  return (
    <Form.Item
      label={<span className=" text-neutral300 text-sm  ">{label}</span>}
      name={name}
      rules={[{ required, message: "Please enter this field!" }]}
      // tooltip="This is a required field"
      {...restProps}
    >
      <Input.TextArea
        {...inputProps}
        className="  text-neutral400"
      />
    </Form.Item>
  )
}

export const CustomSelect = ({
  label,
  name,
  required = false,
  showSearch = true,
  placeholder,
  options,
  onChange,
  onSelectClick,
  isLoading = false,
  addBottomSpace = false,
  mode,
  disabled = false,
  ...restProps
}: SelectItemProps) => {
  return (
    <Form.Item
      name={name}
      label={<span className=" text-neutral300 text-sm">{label}</span>}
      rules={[{ required, message: "Please enter this field" }]}
      {...restProps}
    >
      <Select
        mode={mode}
        loading={isLoading}
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={onChange}
        allowClear
        onClick={onSelectClick}
        filterOption={(
          input: string,
          option?: { label: string; value: string; children: React.ReactNode }
        ) => {
          if (option) {
            const value = (option.value as string) || ""
            const children =
              typeof option.children === "string" ? option.children : ""
            return (
              value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
              children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            )
          } else {
            return false
          }
        }}
        disabled={disabled}
      >
        {options?.map((option, index) => (
          <Option
            key={index}
            value={option.value}
          >
            <span className=" text-neutral400 ">{option.label}</span>
          </Option>
        ))}

        {(addBottomSpace || (options && options?.length >= 30)) &&
          ["first", "second"].map((item) => (
            <Option
              key={"empty" + item}
              disabled
              className="pointer-events-none"
            >
              {" "}
            </Option>
          ))}
      </Select>
    </Form.Item>
  )
}

type InputItemProps = FormItemProps & {
  label: React.ReactNode
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  disabled?: boolean
  minLength?: number
  maxLength?: number
  addonBefore?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week"
}

type SelectItemProps = FormItemProps & {
  label: React.ReactNode
  required?: boolean
  showSearch?: boolean
  onChange?: (e: any) => void
  onSelectClick?: VoidFunction
  placeholder: string
  options: { label: string; value: string }[] | undefined
  isLoading?: boolean
  addBottomSpace?: boolean
  disabled?: boolean
  mode?: "multiple" | "tags"
}

type TextAreaInputItemProps = FormItemProps & {
  label: React.ReactNode
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  inputProps?: TextAreaProps
}
